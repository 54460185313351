// See https://formik.org/docs/api/fieldarray#fieldarray-validation-gotchas
import { Field } from 'formik';
import { get } from 'lodash';
import * as React from 'react';

const ErrorMessage = ({ name }) => (
  <Field
    name={name}
  >
    {({ form }) => {
      const error = get(form.errors, name);
      const touch = get(form.touched, name);
      return touch && error ? error : null;
    }}
  </Field>
);

export default ErrorMessage;
