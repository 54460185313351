import * as React from 'react';
import {
  Accordion,
  AccordionItem,
  Box,
  Button,
  Flex,
  Link,
  Spacer,
  Text,
} from '@chakra-ui/react';
import { AddIcon, InfoOutlineIcon } from '@chakra-ui/icons';
import { FieldArray } from 'formik';
import { Link as RouterLink } from 'react-router-dom';
import { withRequiredAuthInfo } from '@propelauth/react';
import _ from 'lodash';

import SectionBox from '../SectionBox';
import { UserContext } from '../UserContext';
import TransferFormElement from './TransferFormElement';
import { TransferTypes } from './types';

const NoFileSystemError = () => (
  <>
    <SectionBox>
      <Flex alignItems='center' justifyContent='center' mt='8rem' mb='8rem' bg='white'>
        <InfoOutlineIcon color='greys.500'/>
        <Text ml='1ch'>
          <Link as={RouterLink} to='/billing' ml='0.5ch'>Change your plan or start a trial</Link> to transfer files.
        </Text>
      </Flex>
    </SectionBox>
  </>
);

const TransferDetailsForm = ({
  values, errors, setFieldValue, touched, isValid, isFetching,
}: any) => {
  const context = React.useContext(UserContext);
  const hasRemoteMountName = !!context.orgData?.remote_mount_name;
  const defaultTransfer = {
    type: TransferTypes.File,
    source: '',
    destination: '',
    destination_name: '',
  };

  if (!hasRemoteMountName) {
    return <NoFileSystemError />;
  }
  return (
    <>
      <SectionBox header='Transfer details' isFormatted>
        <FieldArray
          name="transfers"
          render={(arrayHelpers) => (
            <Accordion allowMultiple defaultIndex={[0]} key={errors}>
              {values.transfers && values.transfers.length > 0 ? (
                values.transfers.map((transfer, index) => (
                  <AccordionItem
                    key={`${index}${errors}`}
                  >
                    {({ isExpanded }) => (
                      <>
                        <TransferFormElement
                          index={index}
                          key={`${index}${errors}`}
                          setFieldValue={setFieldValue}
                          isExpanded={isExpanded}
                          onRemove={() => arrayHelpers.remove(index)}
                          elementValues={values.transfers[index]}
                        />
                      </>
                    )}
                  </AccordionItem>
                ))
              ) : (
                <></>
              )}
              <Box bg='gray.200' height='1px'/>
              <Button
                mt='1.5rem'
                width='12rem'
                colorScheme='blue'
                variant='outline'
                leftIcon={<AddIcon/>}
                onClick={() => arrayHelpers.push(defaultTransfer)}
              >
                Add a transfer
              </Button>
            </Accordion>
          )}
        />
      </SectionBox>
      <Flex>
        <Spacer/>
        <Button
          type="submit"
          colorScheme='blue'
          isDisabled={_.isEmpty(touched) || !isValid}
          isLoading={isFetching}
          loadingText="Starting..."
          mb='2rem'
        >
          Start transfer
        </Button>
      </Flex>
    </>
  );
};

export default withRequiredAuthInfo(TransferDetailsForm);
