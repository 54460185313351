import {
  Box,
  Flex,
  Heading,
  Step,
  Stepper,
  useSteps,
  useToast,
} from '@chakra-ui/react';
import * as React from 'react';
import { useNavigate, createSearchParams, useLocation } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import { RedirectToLogin, withRequiredAuthInfo } from '@propelauth/react';
import { useEffect, useState } from 'react';
import { Formik } from 'formik';
import { Tour as FrigadeTour, useFlow } from '@frigade/react';
import { useFeatureFlagEnabled } from 'posthog-js/react';
import { runTypes } from '../../constants';
import PipelineSelection from './PipelineSelection';
import SettingsForm from './SettingsForm';
import { UserContext } from '../../UserContext';

async function launchPipeline({ orgId, accessToken, formValues }) {
  const response = await fetch(`${process.env.REACT_APP_API_URL}/app/${orgId}/pipelines/run`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${accessToken}`,
    },
    body: JSON.stringify(formValues),
  });
  if (response.ok) {
    return response.json();
  }
  const body = await response.json();
  throw new Error(body.error || 'Error connecting to server');
}

const LaunchPage = ({ accessToken, orgHelper }: any) => {
  const orgId = orgHelper.getOrgIds()[0];
  const [selectedPipeline, setSelectedPipeline] = useState('');
  const [finalFormValues, setFinalFormValues] = useState({});
  const toast = useToast();
  const navigate = useNavigate();
  const { state } = useLocation();
  const { flow } = useFlow('flow_BPsXVptZ');
  const [pipeline] = useState(state?.pipeline ?? {});
  const context = React.useContext(UserContext);
  const useSingleInstancePipelineLaunch = useFeatureFlagEnabled('use_single_instance_pipeline_launch');

  const initialPipelineValues = {
    pipeline: pipeline?.name ?? '',
    pipeline_version: '',
    instance_type: 'compute-2',
    run_location: `fd://${context?.orgData?.remote_mount_name}` ?? 'fd://',
    inputs: [],
    outputs: [],
    outdir: '',
    export_location: '',
    export_location_source: '',
    profiles: [],
    cli_args: '',
    use_git_release: true,
    wf_manager: pipeline?.wf_manager ?? null,
    snakemake_targets: [],
    snakefile_location: pipeline?.wf_manager === 'snakemake' ? 'workflow/Snakefile' : '',
    snakemake_folder: pipeline?.wf_manager === 'snakemake' ? pipeline.name.split('/')[1] : '',
    use_single_instance: useSingleInstancePipelineLaunch,
  };

  const steps = [
    {
      title: 'Start',
      description: selectedPipeline ? `Selected "${selectedPipeline}"` : 'Select a pipeline',
    },
    { title: 'Finish', description: 'Configure & launch' },
  ];
  const { activeStep, setActiveStep } = useSteps({
    index: 0,
    count: steps.length,
  });

  useEffect(() => {
    if (state?.pipeline) {
      setSelectedPipeline(state.pipeline.name);
      setActiveStep(1);
    }
    if (state?.resetStep) {
      setActiveStep(0);
      navigate('.'); // Resets state so the useEffect can be triggered again
    }
  }, [state, setActiveStep]);

  const {
    data, refetch: fetchLaunchPipeline, isSuccess, isFetching, isError, failureReason,
  } = useQuery(
    ['launchPipeline', finalFormValues],
    () => launchPipeline({
      orgId,
      accessToken,
      formValues: finalFormValues,
    }),
    {
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      enabled: false,
      retry: false,
    },
  );

  // Redirect on successful launch
  useEffect(() => {
    if (isFetching) {
      return;
    }
    if (isSuccess && data) {
      toast({
        title: 'Pipeline launched!',
        status: 'success',
        duration: 5_000,
        isClosable: true,
      });
      navigate(
        {
          pathname: `/dashboard/${data.task_id}`,
          search: createSearchParams({
            runType: runTypes.TES.toString(),
          }).toString(),
        },
        {
          state: { runSummary: { id: data.task_id }, showConfetti: true },
        },
      );
    } else if (isError) {
      toast({
        title: 'Failed to launch pipeline',
        description: `${failureReason}`,
        status: 'error',
        isClosable: true,
      });
    }
  }, [data, isSuccess, isError, failureReason, isFetching]);

  async function onSubmit(formikValues) {
    await setFinalFormValues(formikValues);
    await flow.steps.get('launch-template-pipeline')?.complete();
    await fetchLaunchPipeline();
  }

  return (
    <Box flex='4'>
      <FrigadeTour
        flowId="flow_BPsXVptZ"
        tooltipPosition='auto'
      />
      <Flex mt={4}>
        <Heading as='h2' size='xl' fontWeight='400'>
          Launch a pipeline
        </Heading>
      </Flex>
      <Box bg='gray.200' height='1px' width='100%' mt='1.875rem'/>
      <Stepper size='lg' index={activeStep} colorScheme='blue'>
        {steps.map((step, index) => (
          <Step
            key={index}
            onClick={() => {
              if (index < activeStep) {
                setActiveStep(index);
              }
            }}
          />
        ))}
      </Stepper>
      <Formik
        initialValues={initialPipelineValues}
        onSubmit={async (values) => {
          await onSubmit({
            ...values,
          });
        }}
        enableReinitialize
      >
        {({
          handleSubmit, errors, setTouched, resetForm, isValid, setFieldValue, setValues, values,
        }: any) => (
          <form onSubmit={handleSubmit} onKeyDown={(e) => e.key === 'Enter' && e.preventDefault()}>
            {activeStep === 0
          && <PipelineSelection
            selectedPipeline={selectedPipeline}
            setSelectedPipeline={setSelectedPipeline}
            setValues={setValues}
            setFieldValue={setFieldValue}
            setActiveStep={setActiveStep}
            setTouched={setTouched}
            resetForm={resetForm}
            initialPipelineValues={initialPipelineValues}
          />
            }
            {activeStep === 1
          && <SettingsForm
            values={values}
            errors={errors}
            setFieldValue={setFieldValue}
            setActiveStep={setActiveStep}
            isValid={isValid}
            isFetching={isFetching}
            selectedPipeline={selectedPipeline}
            key={`${selectedPipeline}`}
          />
            }
          </form>
        )}
      </Formik>
    </Box>
  );
};

export default withRequiredAuthInfo(LaunchPage, {
  displayIfLoggedOut: <RedirectToLogin />,
});
