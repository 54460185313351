export const instanceTypes = {
  'compute-2': {
    name: 'compute-2',
    vcpus: 2,
    memory: 4,
  },
  'compute-4': {
    name: 'compute-4',
    vcpus: 4,
    memory: 8,
  },
  'compute-8': {
    name: 'compute-8',
    vcpus: 8,
    memory: 16,
  },
  'compute-16': {
    name: 'compute-16',
    vcpus: 16,
    memory: 32,
  },
  'compute-32': {
    name: 'compute-32',
    vcpus: 32,
    memory: 64,
  },
  'compute-48': {
    name: 'compute-48',
    vcpus: 48,
    memory: 96,
  },
  'compute-64': {
    name: 'compute-64',
    vcpus: 64,
    memory: 128,
  },
  'compute-96': {
    name: 'compute-96',
    vcpus: 96,
    memory: 192,
  },
  'general-2': {
    name: 'general-2',
    vcpus: 2,
    memory: 8,
  },
  'general-4': {
    name: 'general-4',
    vcpus: 4,
    memory: 16,
  },
  'general-8': {
    name: 'general-8',
    vcpus: 8,
    memory: 32,
  },
  'general-16': {
    name: 'general-16',
    vcpus: 16,
    memory: 64,
  },
  'general-32': {
    name: 'general-32',
    vcpus: 32,
    memory: 128,
  },
  'general-48': {
    name: 'general-48',
    vcpus: 48,
    memory: 192,
  },
  'general-64': {
    name: 'general-64',
    vcpus: 64,
    memory: 256,
  },
  'general-96': {
    name: 'general-96',
    vcpus: 96,
    memory: 384,
  },
  'gpu-V100': {
    name: 'gpu-V100',
    vcpus: 8,
    memory: 61,
  },
  'memory-16': {
    name: 'memory-16',
    vcpus: 2,
    memory: 16,
  },
  'memory-32': {
    name: 'memory-32',
    vcpus: 4,
    memory: 32,
  },
  'memory-64': {
    name: 'memory-64',
    vcpus: 8,
    memory: 64,
  },
  'memory-128': {
    name: 'memory-128',
    vcpus: 16,
    memory: 128,
  },
  'memory-256': {
    name: 'memory-256',
    vcpus: 32,
    memory: 256,
  },
  'memory-384': {
    name: 'memory-384',
    vcpus: 48,
    memory: 384,
  },
  'memory-512': {
    name: 'memory-512',
    vcpus: 64,
    memory: 512,
  },
};

export enum runTypes {
  PipelineSegmentInstance,
  TES,
}

export function convertRunTypes(runType: string): runTypes {
  switch (runType) {
  case 'TES':
    return runTypes.TES;
  default:
    return runTypes.PipelineSegmentInstance;
  }
}
