import * as React from 'react';
import {
  Box, Flex, Spacer, Text, useToast,
} from '@chakra-ui/react';
import { CopyIcon } from '@chakra-ui/icons';

type StatusBadgeProps = {
  label: string,
  value: string,
  expanding?: boolean,
}

const CopyableBadge = ({ label, value, expanding = false }: StatusBadgeProps) => {
  const [hovered, setHover] = React.useState(false);
  const toast = useToast();
  const colors = hovered ? {
    bg: '#E3ECFF',
    icon: '#1945AF',
    label: '#1945AF',
  } : {
    bg: '#F2F4F7',
    icon: '#344054',
    label: '#344054',
  };
  return (
    <Flex align="left" justify="left">
      <Box
        bg={colors.bg}
        height="2.2rem"
        borderRadius="20"
        onClick={(e) => {
          e.stopPropagation();
          navigator.clipboard.writeText(value)
            .then(() => toast({
              title: 'Copied to clipboard',
              description: `"${label}" was copied to clipboard.`,
              status: 'success',
              duration: 5_000,
              isClosable: true,
            }));
        }}
        onMouseEnter={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
        position={expanding ? 'absolute' : 'relative'}
        minWidth='max-content'
        _hover={{
          cursor: 'pointer',
        }}>
        <Flex align="center" justify="center" height="100%">
          <Spacer />
          <CopyIcon color={colors.icon} ml="1rem" />
          <Spacer />
          <Text color={colors.label} variant='monospace' fontSize='sm' mr="1rem" ml="0.5rem" >
            {hovered && expanding ? value : label}
          </Text>
          <Spacer />
        </Flex>
      </Box>
    </Flex>
  );
};

export default CopyableBadge;
