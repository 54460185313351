import {
  Box, Flex, Text,
} from '@chakra-ui/react';

const SectionBox = ({
  children, header, headerId, isFormatted, hidden, bodyPaddingTop, marginBottom,
}: any) => {
  if (hidden) {
    return (<></>);
  }
  return (
    <Flex
      id={`section_${header}`}
      direction='column'
      borderWidth="1.5px"
      borderRadius="0.625rem"
      border='1px solid'
      borderColor='gray.200'
      bg='white'
      align='flex-start'
      mt='2rem'
      mb={marginBottom || '2rem'}
      width='100%'
    >
      <Flex
        id='tabs'
        padding='0.3125rem 0rem'
        align='center'
        alignSelf='stretch'
        bg='gray.50'
        borderBottom='1px solid'
        borderColor='gray.200'
        borderRadius='0.625rem 0.625rem 0rem 0rem'
        gap='0.5rem'
      >
        <Flex
          height='3rem'
          padding='1rem 1.25rem'
          align='center'
          borderRadius='0.5rem 0.5rem 0rem 0rem'
          gap='0.5rem'
          id={headerId}
        >
          <Flex justify='center' align='center' gap='0.25rem'>
            <Text variant='text-2xl/lineHeight-8/font-medium'>
              {header}
            </Text>
          </Flex>
        </Flex>
      </Flex>
      {isFormatted && <Box bg='translucent.200' height='1px'/>}
      <Flex
        direction='column'
        bg="white"
        mb={isFormatted ? '0.31rem' : '0.5rem'}
        mt={bodyPaddingTop || (isFormatted ? '0.1rem' : '0.5rem')}
        width='100%'
        p={isFormatted ? 5 : 0}
      >
        {children}
      </Flex>
    </Flex>
  );
};

export default SectionBox;
