import * as React from 'react';
import {
  Box, Flex, Spacer, Text,
} from '@chakra-ui/react';

enum StatusBadges {
    Disabled,
    Active,
    Initialized,
    Transferring,
    Executing,
    ReadyToTransfer,
    Complete,
    Failed,
}

type StatusBadgeProps = {
    label: string,
    badgeType: StatusBadges,
}

const statusBadgeColors = {
  [StatusBadges.Active]: {
    bg: '#E9FFF2',
    circle: '#12B76A',
    label: '#027A48',
  },
  [StatusBadges.Disabled]: {
    bg: '#F2F4F7',
    circle: '#667085',
    label: '#344054',
  },
  [StatusBadges.Initialized]: {
    bg: '#F2F4F7',
    circle: '#667085',
    label: '#344054',
  },
  [StatusBadges.Transferring]: {
    bg: '#F2F4F7',
    circle: '#667085',
    label: '#344054',
  },
  [StatusBadges.Executing]: {
    bg: '#EDF0FF',
    circle: '#5E8CF7',
    label: '#1945AF',
  },
  [StatusBadges.ReadyToTransfer]: {
    bg: '#F4F3FF',
    circle: '#7A5AF8',
    label: '#5925DC',
  },
  [StatusBadges.Complete]: {
    bg: '#E9FFF2',
    circle: '#12B76A',
    label: '#027A48',
  },
  [StatusBadges.Failed]: {
    bg: '#FEF3F2',
    circle: '#FF5555',
    label: '#F23737',
  },
};

const StatusBadge = ({ label, badgeType }: StatusBadgeProps) => {
  const colors = statusBadgeColors[badgeType];
  return (
    <Flex align="left" justify="left">
      <Box bg={colors.bg} height="9" borderRadius="5">
        <Flex align="center" justify="center" height="100%">
          <Spacer />
          <Box width="1.5" height="1.5" bg={colors.circle} borderRadius="5" ml={2.5}/>
          <Spacer />
          <Text color={colors.label} fontSize='sm' mr={2.5} ml={2.5}>
            {label}
          </Text>
          <Spacer />
        </Flex>
      </Box>
    </Flex>
  );
};

const convertStatusToBadge = (status?: string) => {
  if (!status) {
    return (<StatusBadge label='None' badgeType={StatusBadges.Disabled} />);
  } if (status === 'initialized') {
    return (<StatusBadge label='Initialized' badgeType={StatusBadges.Initialized}/>);
  } if (status.startsWith('transferring')) {
    return (<StatusBadge label='Transferring' badgeType={StatusBadges.Transferring} />);
  } if (status.startsWith('executing') || status.endsWith('execution')) {
    return (<StatusBadge label='Executing' badgeType={StatusBadges.Executing}/>);
  } if (status === 'ready_to_transfer_to_client') {
    return (<StatusBadge label='Ready to transfer' badgeType={StatusBadges.ReadyToTransfer} />);
  } if (status === 'complete') {
    return (<StatusBadge label='Complete' badgeType={StatusBadges.Complete} />);
  } if (['terminated', 'terminating'].includes(status)) {
    return (<StatusBadge label='Terminated' badgeType={StatusBadges.Disabled} />);
  } if (status === 'failed') {
    return (<StatusBadge label='Failed' badgeType={StatusBadges.Failed} />);
  }
  return (<StatusBadge label='Unknown' badgeType={StatusBadges.Disabled} />);
};

export { StatusBadge, StatusBadges, convertStatusToBadge };
