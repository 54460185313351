import {
  Button, Flex, Spacer, Text,
} from '@chakra-ui/react';
import { InfoIcon, RepeatClockIcon } from '@chakra-ui/icons';
import { useEffect, useState } from 'react';
import { withRequiredAuthInfo } from '@propelauth/react';

const AlertBar = ({ setHeightCalculation, orgHelper }: any) => {
  const [alertDismissedAt, setAlertDismissedAt] = useState(
    new Date(localStorage.getItem('alertDismissedAt') || 0),
  );

  const orgs = orgHelper.getOrgs();
  const orgName = orgs[0] && orgs[0].orgName;
  let yesterday = new Date();
  yesterday = new Date(yesterday.setDate(yesterday.getDate() - 1));
  const hideAlert = (!orgName?.startsWith('Enterprise demo')) || (alertDismissedAt && alertDismissedAt > yesterday);
  useEffect(() => {
    if (hideAlert) {
      setHeightCalculation('calc(100vh - max(64px, 4.5vh))');
    }
  }, [hideAlert]);
  if (hideAlert) {
    return <></>;
  }
  function dismissAlert() {
    const now = new Date();
    setAlertDismissedAt(now);
    localStorage.setItem('alertDismissedAt', now.toISOString());
  }

  return (
    <Flex bg='blues.300' p='1rem' alignItems='center' height='4.5vh' minHeight='64px'>
      <InfoIcon color='greys.900' mr='1ch'/>
      <Text fontWeight='500' color='greys.900'>
        You're using a shared demo account with a shared file system.
      </Text>
      <Spacer />
      <Button
        onClick={dismissAlert}
        variant='ghost' leftIcon={<RepeatClockIcon color='greys.900' />}
      >
        Dismiss for a day
      </Button>
    </Flex>
  );
};

export default withRequiredAuthInfo(AlertBar);
