import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Box,
  Button,
  Flex,
  Icon,
  Link,
  SimpleGrid,
  Text,
} from '@chakra-ui/react';
import * as React from 'react';
import {
  BsGithub,
  BsStarFill,
} from 'react-icons/bs';
import { GoLaw } from 'react-icons/go';
import { useQuery } from '@tanstack/react-query';
import { withRequiredAuthInfo } from '@propelauth/react';
import { useFeatureFlagEnabled } from 'posthog-js/react';
import SectionBox from '../../../SectionBox';
import PipelinesTable from '../../PipelinesTable';

type TemplateData = {
  description: string,
  id: string,
  latest_version: string,
  name: string,
  template: any,
  stars?: number,
  repo_url?: string,
  license?: string,
}

type TemplateBinProps = {
  templateData: TemplateData,
  onClick: any,
}

const TemplateBin = ({ templateData, onClick }: TemplateBinProps) => (
  <Flex
    height='10.375rem'
    minWidth='10.625rem'
    padding='1.25rem 1.25rem 0.9375rem 1.25rem'
    direction='column'
    justifyContent='space-between'
    alignItems='flex-start'
    borderRadius='0.625rem'
    border='1px solid'
    borderColor='gray.300'
    bg='white'
    _hover={{
      bg: 'gray.100',
      cursor: 'pointer',
    }}
    onClick={() => onClick(templateData)}
    id={
      templateData.name === 'Snakemake tutorial'
        ? 'snakemake-tutorial-template' // for the demo tutorial selector
        : templateData.name
    }
  >
    <Flex
      id='name-and-description'
      direction='column'
      align='flex-start'
    >
      <Text color='gray.900' variant='text-md/lineHeight-6/font-semibold'>
        {templateData.name}
      </Text>
      <Text color='gray.900' variant='text-sm/lineHeight-5/font-normal'>
        {templateData.description}
      </Text>
    </Flex>
    <Flex
      id='template-info'
      align='center'
      gap='0.625rem'
    >
      <Flex
        id='icon-info'
        justify='center'
        align='center'
        gap='1.25rem'
      >
        <Flex
          id='stars'
          align='center'
          justify='center'
          gap='0.375rem'
          height='1.25rem'
        >
          <Icon as={BsStarFill} boxSize='1.25rem' viewBox='0 0 20 20' color='gray.500' />
          <Text color='gray.500' lineHeight='normal' variant='text-sm/lineHeight-5/font-normal'>
            {templateData.stars}
          </Text>
        </Flex>
        <Flex
          id='license'
          align='flex-end'
          gap='0.375rem'
          justify='center'
        >
          <Icon as={GoLaw} width='1.25rem' height='1.25rem' color='gray.500' />
          <Text color='gray.500' lineHeight='normal' variant='text-sm/lineHeight-5/font-normal'>
            {templateData.license}
          </Text>
        </Flex>
      </Flex>
      <Flex
        id='buttons'
        justify='center'
        align='center'
      >
        <Button
          as={Link}
          size='sm'
          variant='ghost'
          leftIcon={<Icon as={BsGithub} color='blue.600' width='1.1875rem' height='1.1875rem' />}
          padding='0rem 0.75rem'
          alignItems='center'
          justifyContent='center'
          borderRadius='0.5rem'
          _hover={{
            bg: 'blue.100',
            textDecoration: 'none',
          }}
          onClick={(e) => e.stopPropagation()}
          href={templateData.repo_url}
          isExternal
          textDecoration='none'
        >
          <Text color='blue.600'>Repository</Text>
        </Button>
      </Flex>
    </Flex>
  </Flex>
);

async function fetchSingleInstanceTemplates({
  accessToken, orgId,
}) {
  const response = await fetch(`${process.env.REACT_APP_API_URL}/app/${orgId}/templates/public`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${accessToken}`,
    },
  });
  if (response.ok) {
    return response.json();
  }
  const body = await response.json();
  throw new Error(body.error || 'Error connecting to server');
}

const SingleInstancePipelineSelect = ({
  onClickTemplate, onClickPipeline, orgHelper, accessToken,
}: any) => {
  const orgId = orgHelper.getOrgIds()[0];

  const { data: templates } = useQuery(
    ['singleInstanceTemplates'],
    async () => fetchSingleInstanceTemplates({ orgId, accessToken }),
    {
      placeholderData: [],
      refetchOnMount: true,
      refetchOnWindowFocus: false,
      enabled: true,
    },
  );

  const enableSingleInstancePipelineSelection = useFeatureFlagEnabled('enable_single_instance_pipeline_selection');

  return (
    <>
      <Box id='page-content'>
        <Box id='header-and-subhead' mt='2rem'>
          <Text color='gray.900' variant='text-2xl/lineHeight-8/font-medium'>Select a pipeline</Text>
        </Box>
        <SectionBox header='Templates' bodyPaddingTop='0'>
          <Flex id='free-tier-alert-section' p='1.25rem 1.25rem 0rem 1.25rem' justify='center' align='center'>
            <Alert status='info' variant='subtle' bg='blue.50' borderRadius='0.625rem'>
              <AlertIcon />
              <Box>
                <AlertTitle>
                  Try these templates for free
                </AlertTitle>
                <AlertDescription>
                  Pipelines in this collection are eligible for the free tier.
                </AlertDescription>
              </Box>
            </Alert>
          </Flex>
          <Flex
            id='templates-section'
            p='1.25rem'
            justify='center'
            align='center'
            borderRadius='0.625rem'
            flexWrap='wrap'
          >
            <SimpleGrid
              spacingX='1.25rem'
              spacingY='1.25rem'
              columns={{
                base: 1, sm: 1, md: 2,
              }}
              width='100%'
            >
              {templates.map((template) => <TemplateBin
                key={template.id}
                templateData={template}
                onClick={onClickTemplate}
              />)}
            </SimpleGrid>
          </Flex>
        </SectionBox>
        <SectionBox header='My pipelines' bodyPaddingTop='0rem'>
          <PipelinesTable
            isDisabled={!enableSingleInstancePipelineSelection}
            noMargin
            emptyMessage='You have no connected pipelines yet.'
            customOnRowClick={onClickPipeline}
          />
        </SectionBox>
      </Box>
    </>
  );
};

export default withRequiredAuthInfo(SingleInstancePipelineSelect);
