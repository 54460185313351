import { useState } from 'react';
import { Field, Formik } from 'formik';
import {
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Textarea,
  useToast,
} from '@chakra-ui/react';
import { withRequiredAuthInfo } from '@propelauth/react';
import { useQuery } from '@tanstack/react-query';

async function createTemplateFromRun({ orgId, accessToken, formValues }) {
  const response = await fetch(`${process.env.REACT_APP_API_URL}/app/${orgId}/template`, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${accessToken}`,
    },
    body: JSON.stringify(formValues),
  });
  if (!response.ok) {
    const body = await response.json();
    throw new Error(body.error || 'Error connecting to server');
  }
}
const TemplateCreationModal = ({
  pipelineName,
  spawnArgs,
  wfManager,
  isOpen,
  onClose,
  accessToken,
  orgHelper,
}: any) => {
  const orgId = orgHelper.getOrgIds()[0];
  const [finalFormValues, setFinalFormValues] = useState({});
  const toast = useToast();

  const { refetch: createPipelineTemplate, isFetching } = useQuery(
    ['createPipelineTemplate'],
    async () => {
      await createTemplateFromRun({
        orgId,
        accessToken,
        formValues: finalFormValues,
      });
      toast({
        title: 'Template created',
        status: 'success',
        duration: 5_000,
        isClosable: true,
      });
      onClose();
      return {};
    },
    {
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      enabled: false,
      retry: false,
    },
  );

  return (
    <Modal isOpen={isOpen} onClose={onClose} size='xl'>
      <ModalOverlay bg='blackAlpha.300' backdropFilter='blur(5px)'/>
      <ModalContent>
        <ModalHeader>Create a template</ModalHeader>
        <ModalCloseButton/>
        <Formik
          initialValues={{
            name: '',
            description: '',
            template_args: spawnArgs,
            wf_manager: wfManager,
          }}
          onSubmit={async (values, actions) => {
            // Does need to be awaited so the values are set when pulled in the useQuery
            await setFinalFormValues(values);
            const response: any = await createPipelineTemplate();
            if (response.isError) {
              if (response.error.message.startsWith('Name')) {
                actions.setErrors({ name: response.error.message });
              } else if (response.error.message.startsWith('Description')) {
                actions.setErrors({ description: response.error.message });
              } else if (response.error.message.startsWith('JSON: ')) {
                actions.setErrors({ template_args: response.error.message.replace('JSON: ', '') });
              }
            }
          }}
        >
          {({
            handleSubmit, errors, touched, isValid,
          }: any) => (
            <form onSubmit={handleSubmit}>
              <ModalBody>
                <Field name='name'>
                  {({ field, form }) => (
                    <FormControl key={`name${errors.name}`} isInvalid={errors.name && touched.name} isRequired>
                      <FormLabel>Name</FormLabel>
                      <Input
                        placeholder={`${pipelineName} template`}
                        value={field.value}
                        onChange={(e) => form.setFieldValue('name', e.target.value)}
                      />
                      <FormErrorMessage>{errors.name}</FormErrorMessage>
                    </FormControl>
                  )}
                </Field>
                <Field name='description'>
                  {({ field, form }) => (
                    <FormControl
                      key={`description${errors.description}`}
                      isInvalid={errors.description && touched.description}
                      mt="1rem"
                      isRequired
                    >
                      <FormLabel>Description</FormLabel>
                      <Input
                        placeholder=''
                        value={field.value}
                        onChange={(e) => form.setFieldValue('description', e.target.value)}
                      />
                      <FormErrorMessage>{errors.description}</FormErrorMessage>
                    </FormControl>
                  )}
                </Field>
                <Field name='template_args'>
                  {({ field, form }) => (
                    <FormControl
                      key={`template_args${errors.template_args}`}
                      isInvalid={errors.template_args && touched.template_args}
                      isRequired
                      mt="1rem"
                    >
                      <FormLabel>Template JSON</FormLabel>
                      <Textarea
                        value={field.value}
                        onChange={(e) => form.setFieldValue('template_args', e.target.value)}
                        height='20rem'
                      />
                      <FormErrorMessage>{errors.template_args}</FormErrorMessage>
                    </FormControl>
                  )}
                </Field>
              </ModalBody>
              <ModalFooter>
                <Button
                  type="submit"
                  colorScheme='blue'
                  isDisabled={!touched || !isValid}
                  isLoading={isFetching}
                  loadingText="Importing..."
                >
                   Create template
                </Button>
              </ModalFooter>
            </form>
          )}
        </Formik>
      </ModalContent>
    </Modal>
  );
};

export default withRequiredAuthInfo(TemplateCreationModal);
