import * as React from 'react';
import { useContext, useEffect, useState } from 'react';
import {
  Box,
  Flex,
  Heading,
  Input,
  InputGroup,
  InputLeftElement,
  Link,
  Progress,
  SimpleGrid,
  Text,
} from '@chakra-ui/react';
import { InfoOutlineIcon, SearchIcon } from '@chakra-ui/icons';
import _ from 'lodash';
import Fuse from 'fuse.js';
import { useFlow } from '@frigade/react';
import { withRequiredAuthInfo } from '@propelauth/react';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { useSearchParams } from 'react-router-dom';

import publicPipelines from '../../publicPipelines';
import SectionBox from '../../../SectionBox';
import PipelineCard from '../PipelineCard';
import fetchPipelines from '../../fetchPipelines';
import PipelineRow from '../PipelineRow';
import randomName from '../../../words';
import SingleInstancePipelineSelect from './SingleInstancePipelineSelect';
import { UserContext } from '../../../UserContext';

async function fetchTemplates({ orgId, accessToken }: any) {
  return fetch(`${process.env.REACT_APP_API_URL}/app/${orgId}/templates`, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${accessToken}`,
    },
  }).then(async (response) => {
    if (response.ok) {
      return response.json();
    }
    throw new Error('Network response was not ok');
  });
}

const PipelineSelection = ({
  selectedPipeline, setSelectedPipeline, setValues, setFieldValue, initialPipelineValues,
  setActiveStep, setTouched, resetForm, orgHelper, accessToken,
}: any) => {
  const orgId = orgHelper.getOrgIds()[0];
  const { flow } = useFlow('flow_BPsXVptZ');
  const [searchParams, setSearchParams] = useSearchParams();
  const pipelineName = searchParams.get('pipeline');
  const queryClient = useQueryClient();

  const [searchQuery, setSearchQuery] = useState('');
  const [searchResults, setSearchResults] = useState([]);

  useEffect(() => {
    if (pipelineName) {
      queryClient.invalidateQueries({ queryKey: ['pipelinesKey'] });
      queryClient.invalidateQueries({ queryKey: ['templatesKey'] });
    }
  }, [pipelineName, queryClient]);

  const { data: templatePipelines } = useQuery(
    ['templatesKey'],

    async () => fetchTemplates({
      orgId,
      accessToken,
    }),
    {
      placeholderData: () => ([]),
      refetchOnMount: true,
      refetchOnWindowFocus: false,
      staleTime: 60 * 1000,
    },
  );

  async function onSubmit(result, isFeaturedTemplate = false) {
    // Only reset form values if this pipeline is newly selected
    const shouldReset = selectedPipeline && selectedPipeline !== result.name;
    if (shouldReset) {
      await resetForm();
    }

    if (isFeaturedTemplate) {
      // New style template handling – currently only supporting isFeaturedTemplate
      const startingValues = _.assign({}, initialPipelineValues, result.template);
      await setValues({ templateId: result.id, ...startingValues });
      setTouched({ pipeline: true });
      await flow.steps.get('select-template-pipeline')?.complete();
    } else {
      // Legacy style template handling
      const templatePipeline = templatePipelines ? _.find(
        templatePipelines,
        { name: result.name, latest_version: result.latest_version },
      ) : undefined;
      const shouldSetTemplateValues = templatePipeline && (shouldReset || !selectedPipeline);
      if (!templatePipeline) {
        setFieldValue('pipeline', result.name);
        setFieldValue('pipeline_version', result.latest_version);
      }
      const randomRunLocation = `${initialPipelineValues.run_location}/runs/${randomName()}`;
      if (result.wf_manager === 'snakemake') {
        if (shouldSetTemplateValues) {
          const snakemakeFolder = templatePipeline.template.pipeline.split('/')[1];
          setFieldValue('snakemake_folder', snakemakeFolder);
        } else if (!templatePipeline) {
          setFieldValue('snakemake_folder', result.name);
          setFieldValue('run_location', randomRunLocation);
        }
        setFieldValue('snakefile_location', 'workflow/Snakefile');
      }
      if (shouldSetTemplateValues) {
        const safeTemplate = _.mapValues(
          templatePipeline.template,
          (value) => value ?? '',
        );
        const randomRunLocationObj = result.wf_manager === 'snakemake'
          ? { run_location: randomRunLocation }
          : {};
        const startingValues = _.assign({}, initialPipelineValues, randomRunLocationObj, safeTemplate);
        await setValues(startingValues);
        setTouched({ pipeline: true });
      }

      setFieldValue('imported', result.imported);
      setFieldValue('wf_manager', result.wf_manager);
    }

    setSelectedPipeline(result.name);
    setActiveStep(1);
    window.scrollTo(0, 0);
  }

  const { data: importedPipelines } = useQuery(
    ['pipelinesKey'],
    async () => {
      const pipelines = await fetchPipelines({
        orgId,
        accessToken,
      });
      if (pipelineName) {
        const pipeline = _.find(pipelines, { name: pipelineName });
        searchParams.delete('pipeline');
        setSearchParams(searchParams);
        return onSubmit(pipeline);
      }
      return pipelines;
    },
    {
      placeholderData: () => ([]),
      refetchOnMount: true,
      refetchOnWindowFocus: false,
      staleTime: 60 * 1000,
    },
  );

  useEffect(() => {
    const options = {
      includeScore: true,
      keys: [
        {
          name: 'name',
          weight: 0.8,
        },
        {
          name: 'description',
          weight: 0.2,
        },
      ],
    };

    const pipelines = importedPipelines.concat(publicPipelines);
    const fuse = new Fuse(pipelines, options);
    const results = fuse.search(searchQuery, { limit: 5 });
    setSearchResults(results.map((result) => result.item));
  }, [searchQuery, importedPipelines]);

  const { orgData } = useContext(UserContext);
  const useSingleInstanceLaunch = orgData.is_single_instance;

  // Loading state for if pipeline is already selected
  if (pipelineName) {
    return (
      <>
        <Text>Getting the pipeline ready</Text>
        <Progress size='xs' isIndeterminate />
      </>
    );
  }

  if (useSingleInstanceLaunch) {
    return <SingleInstancePipelineSelect
      onClickTemplate={(template) => onSubmit(template, true)}
      onClickPipeline={(pipeline) => onSubmit(pipeline, false)}
    />;
  }

  return (
    <>
      <SectionBox header='Choose a pipeline' isFormatted>
        <Heading as='h3' size='md'>
          Search pipelines
        </Heading>
        <InputGroup mt={4} mb={4}>
          <InputLeftElement pointerEvents='none'>
            <SearchIcon color='gray.300' />
          </InputLeftElement>
          <Input
            type='text'
            placeholder='Start typing to see results (try "rna...")'
            onChange={(e) => {
              setSearchQuery(e.target.value);
            }}
            value={searchQuery}
          />
        </InputGroup>
        {
          searchResults.map((result, index) => (
            <PipelineRow
              result={result}
              selectedPipeline={selectedPipeline}
              key={`${result.name}${selectedPipeline === result.name}${result.imported ?? 'false'}`}
              onClick={onSubmit}
              style={{ borderTopWidth: index === 0 ? '1.5px' : '0px' }}
            />
          ))
        }
        {
          searchResults.length > 1 && (
            <Flex alignItems='center' justify='left' p={5}>
              <InfoOutlineIcon color='greys.500'/>
              <Text ml='0.5ch'>
                Not seeing what you're looking for?
                <Link
                  ml='0.5ch'
                  isExternal
                  href={`https://github.com/apps/flowdeploy-pipeline-manager/installations/new?state=${orgId}`}
                >
                  Connect or manage your GitHub connection
                </Link>.
              </Text>
            </Flex>
          )
        }
        <Box width='100%' borderTop='solid 1px' borderColor='gray.100' pt={4}>
          <Heading as='h3' size='md' mb={4}>
            Not sure where to start? Try launching a pipeline from a template.
          </Heading>
          <SimpleGrid spacingX='1em' spacingY='1em' columns={{
            base: 1, sm: 1, md: 2, lg: 2, xl: 2, '2xl': 3,
          }} width='100%'>
            {
              templatePipelines?.map((result) => (
                <PipelineCard
                  result={result}
                  key={`${result.name}${selectedPipeline === result.name}`}
                  onClick={onSubmit}
                />
              ))
            }
          </SimpleGrid>
        </Box>
      </SectionBox>
    </>
  );
};

export default withRequiredAuthInfo(PipelineSelection);
