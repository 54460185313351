import { Box } from '@chakra-ui/react';
import * as React from 'react';
import { FieldArray } from 'formik';
import InputDataSection from './InputDataSection';
import OutputDataSection from './OutputDataSection';
import { LaunchInputFile } from '../types';

const DataSection = ({ setFieldValue, formValues }: any) => {
  function onAddInput(arrayHelpers, inputFile: LaunchInputFile) {
    const {
      // eslint-disable-next-line camelcase
      label, url, arg, file, relative_path,
    } = inputFile;

    arrayHelpers?.push({
      id: file?.id || url,
      url,
      label: label || file?.label || '',
      name: file?.name || '',
      size: file?.size || 0,
      is_registered_file: file?.is_registered_file ?? !!file?.id,
      is_dir: file?.is_dir || false,
      arg: arg || '',
      // eslint-disable-next-line camelcase
      relative_path: relative_path || '',
      file,
    });
  }

  return (
    <Box>
      <FieldArray
        name="inputs"
        render={(arrayHelpers) => (
          <InputDataSection
            key='input-data-section'
            onRemove={(index) => arrayHelpers.remove(index)}
            onEditArg={({ index, newArg }) => setFieldValue(`inputs[${index}]`, {
              ...formValues.inputs[index],
              arg: newArg,
            })}
            onEditRelativePath={({ index, relativePath }) => setFieldValue(`inputs[${index}]`, {
              ...formValues.inputs[index],
              relative_path: relativePath,
            })}
            onAdd={({ selectedFile }) => onAddInput(arrayHelpers, selectedFile)}
            inputs={formValues?.inputs}
          />
        )}
      />
      <Box width='100%' height='1px' bg='gray.300'/>
      <FieldArray
        name="outputs"
        render={(arrayHelpers) => (
          <OutputDataSection
            key='output-data-section'
            onRemove={(index) => arrayHelpers.remove(index)}
            onEditLabel={({ index, newLabel }) => setFieldValue(`outputs[${index}]`, {
              ...formValues.outputs[index],
              label: newLabel,
            })}
            onAdd={({ label, path }) => arrayHelpers?.push({
              path,
              label: label || '',
            })}
            outputs={formValues?.outputs}
          />
        )}
      />
    </Box>
  );
};

export default DataSection;
