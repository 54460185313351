import { RedirectToLogin, withRequiredAuthInfo } from '@propelauth/react';
import {
  Box, Button, Flex, Heading, Link, Spacer, Text,
} from '@chakra-ui/react';
import * as React from 'react';
import { EditIcon } from '@chakra-ui/icons';

const AUTH_URL = process.env.REACT_APP_AUTH_URL;

const SettingsPage = (props: any) => {
  const orgs = props.orgHelper.getOrgs();
  const orgName = orgs[0] && orgs[0].orgName;
  if (props.isLoggedIn) {
    return (
      <Box flex='4'>
        <Flex width="90%">
          <Heading as='h2' size='xl' mb={8} mt={8}>
                    Account settings
          </Heading>
        </Flex>
        <Flex width="90%">
          <Box minWidth="13rem">
            <Text fontSize='xl' mb={4} mt={4} as='b' color="#667085">
                    User information
            </Text>
            <Box mt={2}>
              <Text fontSize='m' as='b'>
                {props.user.firstName} {props.user.lastName}
              </Text>
            </Box>
            <Link href={`${AUTH_URL}/account`}>
              <Button mt={4} leftIcon={<EditIcon />}>Edit</Button>
            </Link>
          </Box>
          <Spacer />
          <Box flexDir="column" minWidth="13rem">
            <Text fontSize='xl' mb={4} mt={4} as='b' color="#667085">
                    Team
            </Text>
            <Box mt={2}>
              <Text fontSize='m' as='b'>
                {orgName && !orgName.startsWith('unnamed') ? orgName : 'No team'}
              </Text>
            </Box>
            <Link href={`${AUTH_URL}/org`}>
              <Button mt={4} leftIcon={<EditIcon />}>Edit</Button>
            </Link>
          </Box>
          <Spacer />
          <Box flexDir="column" minWidth="13rem">
            <Text fontSize='xl' mb={4} mt={4} as='b' color="#667085">
                    Plan
            </Text>
            <Box mt={2}>
              <Text fontSize='m' as='b'>
                      Developer
              </Text>
            </Box>
          </Box>
        </Flex>
      </Box>
    );
  }
  return <div>You are not logged in</div>;
};

export default withRequiredAuthInfo(SettingsPage, {
  displayIfLoggedOut: <RedirectToLogin />,
});
