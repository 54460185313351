import {
  Button, Icon, Menu, MenuButton, MenuDivider, MenuItem, MenuList, useDisclosure, useToast,
} from '@chakra-ui/react';
import {
  BsBucket, BsFolderPlus, BsLaptop, BsLink45Deg, BsPlusLg,
} from 'react-icons/bs';
import { useContext, useRef } from 'react';
import { withRequiredAuthInfo } from '@propelauth/react';
import * as React from 'react';
import { map } from 'lodash';
import { UserContext } from '../../../UserContext';
import { DrivesContext } from '../../DriveProvider';
import NewFolderModal from './NewFolderModal';

async function uploadToPresignedUrl({ presignedUrl, file }) {
  return fetch(presignedUrl, {
    method: 'PUT',
    headers: {
      'Content-Type': file.type,
    },
    body: file,
  });
}

async function fetchPresignedUrl({
  prefix, file, accessToken, orgId,
}) {
  const response = await fetch(`${process.env.REACT_APP_API_URL}/app/${orgId}/files/registered`, {
    method: 'POST',
    body: JSON.stringify({
      is_directory: false,
      prefix,
      file_name: file.name,
      content_type: file.type,
    }),
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${accessToken}`,
    },
  });
  if (response.ok) {
    return response.json();
  }
  const body = await response.json();
  throw new Error(body.error || 'Network response was not ok');
}

async function markFileAvailable({ id, accessToken, orgId }) {
  const response = await fetch(`${process.env.REACT_APP_API_URL}/app/${orgId}/files/registered`, {
    method: 'PUT',
    body: JSON.stringify({
      id,
    }),
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${accessToken}`,
    },
  });
  if (response.ok) {
    return response.json();
  }
  const body = await response.json();
  throw new Error(body.error || 'Network response was not ok');
}

const AddDataMenu = ({
  onConnectBucket, onImportViaUrl, onCreateNewFolder, accessToken, orgHelper,
}) => {
  const { orgData } = useContext(UserContext);
  const {
    selectedFileSystem,
    originalPath,
    refetchFiles,
  } = React.useContext(DrivesContext);
  const { isOpen: isFolderOpen, onOpen: onFolderOpen, onClose: onFolderClose } = useDisclosure();
  const isS3 = selectedFileSystem.startsWith('s3');
  // Remove file system root URI, leading, and trailing slashes
  const prefix = originalPath.split(selectedFileSystem)[1].replace(/^\/+|\/+$/g, '');
  // todo: if originalPath is not equal to the full path (e.g. including subdir), then this is a registered directory
  // and we can't upload
  const useSingleInstanceLaunch = orgData.is_single_instance;
  const orgId = orgHelper.getOrgIds()[0];
  const toast = useToast({
    position: 'bottom-right',
    variant: 'subtle',
  });
  const fileInputRef = useRef<any>();

  async function onClickUpload() {
    // Opens file picker. This seems unnecessarily hacky, but I couldn't find another clean path forward.
    fileInputRef.current?.click();
  }

  async function uploadPromiseForToast(fileForUpload) {
    // 5 GB max size for upload
    if (fileForUpload.size > 5 * 1024 * 1024 * 1024) {
      throw Error(`File too large: ${fileForUpload.name}`);
    }

    const { presigned_s3_uri: presignedUrl, id } = await fetchPresignedUrl({
      prefix,
      file: fileForUpload,
      accessToken,
      orgId,
    });
    await uploadToPresignedUrl({
      presignedUrl,
      file: fileForUpload,
    });
    await markFileAvailable({
      id,
      accessToken,
      orgId,
    });
    refetchFiles().then();
  }

  async function onChangeFile(e) {
    const uploadFilesAndPromises = map(e.target.files, (fileForUpload) => (
      {
        fileForUpload,
        uploadPromise: uploadPromiseForToast(fileForUpload),
      }
    ));

    await Promise.all(uploadFilesAndPromises.map(({ fileForUpload, uploadPromise }) => (
      toast.promise(uploadPromise, {
        success: { title: 'Upload complete', description: `${fileForUpload.name}` },
        error: (error) => ({ title: 'Upload failed', description: error.message || 'Please try again' }),
        loading: { title: `Uploading ${fileForUpload.name}`, description: '' }, // todo: progress, cancel
      })
    )));
  }

  return (
    <Menu>
      <MenuButton
        as={Button}
        leftIcon={<Icon as={BsPlusLg} width='0.875rem' height='0.875rem' mr={0} color='white'/>}
        variant='solid'
        colorScheme='blue'
        size='sm'
      >
        Add data
      </MenuButton>
      <MenuList>
        <MenuItem
          onClick={onConnectBucket}
          icon={<Icon as={BsBucket} width='1.2rem' height='1.2rem'/>}
        >
          Connect S3 bucket
        </MenuItem>
        <MenuDivider/>
        <MenuItem
          onClick={onImportViaUrl}
          icon={<Icon as={BsLink45Deg} width='1.2rem' height='1.2rem'/>}
          isDisabled={useSingleInstanceLaunch}
        >
          Import via URI/URL
        </MenuItem>
        <MenuItem
          onClick={onClickUpload}
          icon={<Icon as={BsLaptop} width='1.2rem' height='1.2rem'/>}
          isDisabled={isS3}
        >
          Upload from device
          <input onChange={onChangeFile} ref={fileInputRef} type='file' style={{ display: 'none' }} multiple/>
        </MenuItem>
        <MenuDivider/>
        <NewFolderModal
          onClose={onFolderClose}
          isOpen={isFolderOpen}
          refetchFiles={refetchFiles}
          prefix={prefix}
        />
        <MenuItem
          onClick={useSingleInstanceLaunch ? onFolderOpen : onCreateNewFolder}
          icon={<Icon as={BsFolderPlus} height='1.4em' width='1.4em'/>}
        >
          Create new folder
        </MenuItem>
      </MenuList>
    </Menu>
  );
};

export default withRequiredAuthInfo(AddDataMenu);
