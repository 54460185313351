import {
  Flex,
  Progress,
  Text,
} from '@chakra-ui/react';

const TransferProgress = ({ hidden, transferSummary }) => {
  if (hidden) return null;
  return (
    <Flex
      direction='column'
      gap='1.25rem'
      alignSelf='stretch'
    >
      <Flex
        direction='column'
        gap='0.625rem'
        alignItems='flex-start'
      >
        <Text variant='text-sm/lineHeight-5/font-medium' color='gray.500'>
              File sync
        </Text>
        <Flex alignItems='flex-end' direction='row'>
          <Text variant='text-3xl/lineHeight-9/font-normal' color='gray.900'>
            {transferSummary.completedFiles}
          </Text>
          <Text variant='text-2xl/lineHeight-8/font-normal' color='gray.400'>
            {`\u00A0/ ${transferSummary.totalFiles} files ${transferSummary.isInput ? 'to server' : 'to FlowDeploy'}`}
          </Text>
        </Flex>
      </Flex>
      <Progress hasStripe isAnimated value={transferSummary.progress} max={100} borderRadius='0.625rem' sx={{
        '& > div:first-child': {
          transitionProperty: 'width',
        }, // Adds animation to progress bar movement
      }}/>
      <Text variant='text-sm/lineHeight-5/font-medium' color='gray.600'>
        {transferSummary.fileName}
      </Text>
    </Flex>
  );
};

export default TransferProgress;
