import { defineStyleConfig, theme, extendTheme } from '@chakra-ui/react';
import { mode } from '@chakra-ui/theme-tools';
import '@fontsource-variable/roboto-mono';
import '@fontsource-variable/figtree';

const Text = defineStyleConfig({
  variants: {
    secondary: {
      color: 'greys.500',
    },
    monospace: {
      color: 'white',
      fontFamily: '\'Roboto Mono Variable\', sans-serif',
      fontWeight: '500',
    },
    'text-xs/lineHeight-4/font-normal': {
      fontSize: '0.75rem',
      fontWeight: 400,
      lineHeight: '1rem',
    },
    'text-sm/lineHeight-5/font-normal': {
      fontSize: '0.875rem',
      fontWeight: 400,
      lineHeight: '1.25rem',
      color: 'gray.500',
    },
    'text-sm/lineHeight-5/font-medium': {
      fontSize: '0.875rem',
      fontWeight: 500,
      lineHeight: '1.25rem',
      color: 'gray.500',
    },
    'text-sm/lineHeight-5/font-semibold': {
      fontSize: '0.875rem',
      fontWeight: 600,
      lineHeight: '1.25rem',
    },
    'text-md/lineHeight-6/font-normal': {
      fontSize: '1rem',
      fontWeight: 400,
      lineHeight: '1.5rem',
      color: 'gray.700',
    },
    'text-md/lineHeight-6/font-medium': {
      fontSize: '1rem',
      fontWeight: 500,
      lineHeight: '1.5rem',
      color: 'gray.500',
    },
    'text-md/lineHeight-6/font-semibold': {
      fontSize: '1rem',
      fontWeight: 600,
      lineHeight: '1.5rem',
    },
    'text-lg/lineHeight-7/font-normal': {
      fontSize: '1.125rem',
      fontWeight: 400,
      lineHeight: '1.75rem',
    },
    'text-lg/lineHeight-7/font-medium': {
      fontSize: '1.125rem',
      fontWeight: 500,
      lineHeight: '1.75rem',
      color: 'gray.900',
    },
    'text-lg/lineHeight-7/font-semibold': {
      fontSize: '1.125rem',
      fontWeight: 600,
      lineHeight: '1.75rem',
    },
    'text-xl/lineHeight-7/font-normal': {
      fontSize: '1.25rem',
      fontWeight: 400,
      lineHeight: '1.75rem',
    },
    'text-xl/lineHeight-7/font-medium': {
      fontSize: '1.25rem',
      fontWeight: 500,
      lineHeight: '1.75rem',
    },
    'text-xl/lineHeight-7/font-semibold': {
      fontSize: '1.25rem',
      fontWeight: 600,
      lineHeight: '1.75rem',
    },
    'text-2xl/lineHeight-8/font-normal': {
      fontSize: '1.5rem',
      fontWeight: 400,
      lineHeight: '2rem',
    },
    'text-2xl/lineHeight-8/font-medium': {
      fontSize: '1.5rem',
      fontWeight: 500,
      lineHeight: '2rem',
    },
    'text-3xl/lineHeight-9/font-normal': {
      fontSize: '1.875rem',
      fontWeight: 400,
      lineHeight: '2.25rem',
    },
  },
});

export const themeConfig = {
  styles: {
    global: (props: any) => ({
      'html, body': {
        background: mode('#fdfdfd', '#2E2E30')(props),
      },
    }),
  },
  config: {
    initialColorMode: 'light',
    useSystemColorMode: false,
  },
  components: {
    Alert: {
      variants: {
        info: {
          container: {
            background: 'blues.200',
          },
          title: {
            color: 'teal.500',
            _dark: {
              color: 'cyan.400',
            },
          },
        },
      },
    },
    Accordion: {
      baseStyle: {
        container: {
          bg: '#ffffff', // change the backgroundColor of the container
        },
      },
    },
    Button: {
      baseStyle: {
        height: '3rem',
        borderRadius: '0.625rem',
        fontSize: '1.125rem',
        fontWeight: 400,
        lineHeight: '1.75rem',
        fontColor: 'gray.800',
      },
      variants: {
        outline: {
          display: 'inline-flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '3rem',
          padding: '0 rem 1.5 rem',
          gap: '0.5rem',
          flexShrink: 0,
        },
      },
    },
    Checkbox: {
      baseStyle: {
        control: {
          borderWidth: '1.5px',
          borderRadius: '0.16669rem',
          borderColor: 'gray.300',
          _checked: {
            bg: 'gray.600',
            borderColor: 'gray.600',
          },
          _indeterminate: {
            bg: 'gray.600',
            borderColor: 'gray.600',
          },
        },
      },
    },
    Divider: {
      baseStyle: {
        borderWidth: '4px',
      },
    },
    FormLabel: {
      baseStyle: {
        fontSize: '1rem',
        fontWeight: 600,
        lineHeight: '1.5rem',
        color: 'gray.700',
      },
    },
    Heading: {
      variants: {
        monospace: {
          fontFamily: '\'Roboto Mono Variable\', monospace, sans-serif',
          fontWeight: '500',
        },
        subheader: {
          fontColor: 'greys.800',
        },
      },
    },
    Input: {
      defaultProps: {
        addon: {
          color: 'white',
          background: 'white',
        },
        _placeholder: {
          color: 'gray.400',
        },
      },
    },
    InputRightElement: {
      baseStyle: {
        height: '3rem',
        padding: '0rem 0.625rem',
        justify: 'center',
        align: 'center',
        gap: '0.625rem',
      },
    },
    Link: {
      baseStyle: {
        textDecoration: 'underline',
      },
      variants: {
        embedded: {
          textDecoration: 'none',
          fontColor: 'blue.100',
          color: 'blue.100',
        },
      },
    },
    Radio: {
      baseStyle: {
        control: {
          border: '2px solid',
          borderColor: 'gray.500',
        },
      },
    },
    Tabs: {
      variants: {
        sectionbox: (props) => ({
          ...theme.components.Button.variants.outline(props),
          tab: {
            bg: 'gray.50',
            color: 'gray.500',
            fontWeight: 500,
            lineHeight: '1.75rem',
            fontSize: '1.125rem',
            textAlign: 'center',
            _selected: {
              bg: 'gray.200',
              color: 'gray.800',
              fontWeight: 600,
            },
            borderRadius: '6000rem',
          },
          tabpanel: {
            margin: 0,
            padding: 0,
          },
          tablist: {
            border: '1px solid',
            borderColor: 'gray.200',
            borderRadius: '6000rem',
            bg: 'gray.50',
          },
        }),
      },
    },
    Text,
    Tooltip: {
      variants: {
        white: {
          bg: '#fff',
          arrowColor: '#fff',
          borderColor: '#fff',
          color: '#fff',
          fontColor: '#000',
          borderRadius: '0.25rem',
        },
      },
    },
  },
  fonts: {
    heading: '\'Figtree Variable\', sans-serif',
    body: '\'Figtree Variable\', sans-serif',
  },
  colors: {
    blues: {
      50: '#F6F8FF',
      100: '#EDF0FF',
      200: '#E3ECFF',
      300: '#BED1FF',
      400: '#5f89ff',
      500: '#3e78ff',
      600: '#175CFF',
      700: '#0542D1',
      800: '#1945AF',
    },
    blue: {
      50: '#EBF8FF',
      100: '#BEE3F8',
      200: '#90CDF4',
      300: '#63B3ED',
      400: '#4299E1',
      500: '#3182CE',
      600: '#2B6CB0',
      700: '#2C5282',
      800: '#2A4365',
      900: '#1A365D',
    },
    ghost: {
      50: '#53489a',
      100: '#53489a',
      200: '#F6F8FF',
      300: '#F6F8FF',
      400: '#F6F8FF',
      500: '#F6F8FF',
      600: '#F6F8FF',
      700: '#5b5b5b',
      800: '#5b5b5b',
    },
    greens: {
      50: '#E9FFF2',
      500: '#12B76A',
      700: '#027A48',
    },
    greys: {
      50: '#e5e5e5',
      400: '#a1a1a1',
      500: '#667085',
      700: '#3d3d3d',
      800: '#1a1a1a',
    },
    translucent: {
      200: 'rgba(161,161,161,0.1)',
    },
    reds: {
      50: '#fff8f6',
      500: '#FF5555',
      600: '#FF5555',
      700: '#F23737',
    },
    deep: {
      100: 'rgba(39,20,66,0.9)',
      500: 'rgba(58,25,107,0.9)',
      900: 'rgba(35,40,122,0.9)',
    },
  },
};

const styledTheme = extendTheme(themeConfig);

export default styledTheme;
