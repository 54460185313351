import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import { withRequiredAuthInfo } from '@propelauth/react';
import { createColumnHelper } from '@tanstack/react-table';

import { DataTable } from '../DataTable';
import RunsTableHeader from './RunsTableHeader';
import { RawRunSummary } from './types';
import { convertRunTypes } from '../constants';
import { convertStatusToBadge } from '../StatusBadge';
import CopyableBadge from '../CopyableBadge';

const RunsSummaryTable = ({
  runs, refetch, emptyMessage, skipFiltering,
}: any) => {
  const navigate = useNavigate();

  const onRowClick = (row: RawRunSummary) => {
    navigate(
      `/dashboard/${row.id}?runType=${convertRunTypes(row.run_type).toString()}`,
      { state: { runSummary: row } },
    );
  };

  const isRowSelectable = (row: any) => !row.original.is_single_instance;
  const columnHelper = createColumnHelper<RawRunSummary>();

  const columns = [
    columnHelper.accessor('tool_name', {
      cell: (info) => info.getValue(),
      header: 'Tool name',
    }),
    columnHelper.accessor('created_at', {
      cell: (info) => {
        const date = info.getValue() && new Date(info.getValue());
        return date
          ? `${date.toLocaleDateString('en-US')} at ${date.toLocaleTimeString('en-US')}`
          : 'Never used';
      },
      header: 'Started at',
      meta: {
        isNumeric: false,
      },
    }),
    columnHelper.accessor('status', {
      cell: (info) => {
        const status = info.getValue();
        return convertStatusToBadge(status);
      },
      header: 'Status',
    }),
    columnHelper.accessor('id', {
      cell: (info) => {
        const id = info.getValue();
        return id && (
          <CopyableBadge
            label={`${id.substring(0, 8)}${id.length > 8 ? '...' : ''}`}
            value={id}
          />
        );
      },
      header: 'ID',
    }),
  ];

  return (
    <DataTable
      data={runs}
      columns={columns}
      refetch={refetch}
      TableHeader={!skipFiltering && RunsTableHeader}
      onRowClick={onRowClick}
      defaultSortId='created_at'
      noMargin={true}
      noTopMargin
      noBottomMargin
      idColumn='id'
      emptyMessage={emptyMessage}
      isRowSelectable={isRowSelectable}
      enableGlobalSearch
    />
  );
};

export default withRequiredAuthInfo(RunsSummaryTable);
