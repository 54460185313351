/* eslint-disable @typescript-eslint/ban-types */
import * as React from 'react';
import {
  Table, Thead, Tbody, Tr, Th, Td, chakra, Box, Button, Select, Flex, Spacer, Text,
} from '@chakra-ui/react';
import {
  ArrowBackIcon, ArrowForwardIcon,
} from '@chakra-ui/icons';
import {
  useReactTable,
  flexRender,
  getCoreRowModel,
  ColumnDef,
  FilterFn,
  SortingState,
  getSortedRowModel,
  getPaginationRowModel,
  getFilteredRowModel,
} from '@tanstack/react-table';
import { reduce, some, includes } from 'lodash';
import { Component } from 'react';
import { IoArrowDownOutline, IoArrowUpOutline } from 'react-icons/io5';

export type DataTableProps<Data extends object> = {
    data: Data[];
    columns: ColumnDef<Data, any>[];
    filter?: Function;
    refetch?: Function;
    TableHeader: any;
    onRowClick?: Function,
    defaultSortId: string,
    noMargin?: boolean,
    noTopMargin?: boolean,
    noBottomMargin?: boolean,
    onSelect?: Function,
    onDeselect?: Function,
    idColumn: string,
    initialRowSelection?: Array<string>,
    isRowSelectable?: Function,
    emptyMessage?: any;
    headerButtons?: Component,
    enableMultiRowSelection?: boolean,
    enableGlobalSearch?: boolean,
};

export function DataTable<Data extends object>({
  data,
  columns,
  filter,
  refetch,
  TableHeader,
  onRowClick,
  defaultSortId,
  noMargin,
  noTopMargin,
  noBottomMargin,
  onSelect,
  onDeselect,
  idColumn,
  initialRowSelection,
  isRowSelectable,
  emptyMessage,
  headerButtons,
  enableMultiRowSelection = false,
  enableGlobalSearch = false,
}: DataTableProps<Data>) {
  const [sorting, setSorting] = React.useState<SortingState>([{
    id: defaultSortId,
    desc: true,
  }]);
  const [globalSearchQuery, setGlobalSearchQuery] = React.useState<string>('');

  const searchEveryColumn: FilterFn<any> = (row, columnId, value) => some(
    row.getVisibleCells(),
    (columnValue) => includes(columnValue.getValue(), value),
  );

  const defaultRowSelection = reduce(
    initialRowSelection,
    (selections, id) => {
      // eslint-disable-next-line no-param-reassign
      selections[id] = true;
      return selections;
    },
    {},
  );
  const [rowSelection, setRowSelection] = React.useState(defaultRowSelection);
  const hasCustomSelector = !!isRowSelectable;
  const table = useReactTable({
    columns,
    data,
    getCoreRowModel: getCoreRowModel(),
    onSortingChange: setSorting,
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    filterFns: {
      searchEveryColumn,
    },
    enableGlobalFilter: enableGlobalSearch,
    state: {
      sorting,
      rowSelection,
      globalFilter: globalSearchQuery,
    },
    globalFilterFn: searchEveryColumn,
    onRowSelectionChange: setRowSelection,
    enableMultiRowSelection,
    getRowId: (row) => row[idColumn],
  });

  function handleRowClick(row, event, isCheckbox) {
    // Hacky way to fix launch page file select double-check
    // Not sure why this is happening; spent about an hour debugging and calling it quits
    const duplicateCheckboxClick = event.target.className === 'chakra-checkbox__input';
    if (
      row.original.not_selectable
      || duplicateCheckboxClick
      || (hasCustomSelector && !isRowSelectable(row))
    ) {
      event.stopPropagation();
      return;
    }

    // Includes navigation
    const shouldPropagateRowClick = !hasCustomSelector || (hasCustomSelector && !isCheckbox);
    if (shouldPropagateRowClick) {
      if (onRowClick) {
        event.stopPropagation();
        onRowClick(row.original);
        if (row.original.is_dir) { // Skips selecting the directory when navigating into it
          return;
        }
      }
    }

    row.toggleSelected();
    // Toggle selected doesn't seem to propagate synchronously
    const isSelected = !row.getIsSelected();
    if (isSelected && onSelect) {
      onSelect(row.original);
    } else if (!isSelected && onDeselect) {
      onDeselect(row.original);
    }

    if (isCheckbox) {
      event.stopPropagation();
    }
  }

  function getSelectionBackground(isSelectable, isSelected) {
    if (isSelectable) {
      return isSelected ? 'gray.100' : 'gray.50';
    }
    return 'transparent';
  }

  const rowStyle = (row) => {
    const isSelectable = hasCustomSelector ? isRowSelectable(row) : true;
    const isSelected = isSelectable && row.getIsSelected();
    return {
      bg: isSelected ? 'gray.50' : 'transparent',
      _hover: {
        background: getSelectionBackground(isSelectable, isSelected),
        cursor: isSelectable ? 'pointer' : undefined,
      },
    };
  };

  const columnStyle = (cellRow) => {
    const isSelectable = hasCustomSelector ? isRowSelectable(cellRow) : true;
    return {
      color: isSelectable ? 'black' : 'gray.800',
    };
  };

  function getSortArrow(header) {
    if (header.column.getIsSorted()) {
      return header.column.getIsSorted() === 'desc' ? (
        <IoArrowDownOutline color='gray.800' aria-label="sorted descending" />
      ) : (
        <IoArrowUpOutline color='gray.800' aria-label="sorted ascending" />
      );
    }
    return <></>;
  }

  return (
    <Box
      mt={noMargin || noTopMargin ? 0 : 12}
      mb={noMargin || noBottomMargin ? 0 : 4}
      mr={noMargin ? '0%' : '12%'}
      bg="white"
      borderWidth={noMargin ? '0px' : '1.5px'}
      borderRadius={noMargin ? '0px' : '15px'}
      borderColor={noMargin ? undefined : 'gray.200'}
      overflowX="auto"
      width='100%'
    >
      {TableHeader
        && <TableHeader
          table={table}
          refetch={refetch}
          filter={filter}
          setGlobalSearchQuery={setGlobalSearchQuery}
          headerButtons={headerButtons}
        />}
      <Table style={{ width: '100%', borderCollapse: 'collapse' }}>
        <Thead bg='gray.50' borderTop='1px' borderBottom='1px' borderColor='gray.200'>
          {table.getHeaderGroups().map((headerGroup) => (
            <Tr key={headerGroup.id}>
              {headerGroup.headers.map((header) => {
                const { meta } = header.column.columnDef;
                return (
                  <Th
                    key={header.id}
                    onClick={header.column.getToggleSortingHandler()}
                    isNumeric={meta?.isNumeric}
                  >
                    <Flex align='center' >
                      {flexRender(
                        header.column.columnDef.header,
                        header.getContext(),
                      )}
                      <chakra.span pl="0.5rem">
                        {getSortArrow(header)}
                      </chakra.span>
                    </Flex>
                  </Th>
                );
              })}
            </Tr>
          ))}
        </Thead>
        <Tbody>
          {table.getRowModel().rows.map((row) => (
            <Tr
              key={row.id}
              onClick={(e) => handleRowClick(row, e, false)}
              { ...rowStyle(row) }
            >
              {row.getVisibleCells().map((cell) => {
                const { meta } = cell.column.columnDef;
                return (
                  <Td
                    key={cell.id}
                    isNumeric={meta?.isNumeric}
                    style={{
                      whiteSpace: 'nowrap',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      maxWidth: '30ch', // adjust as needed
                      ...columnStyle(cell.row),
                    }}
                    onClick={
                      (e) => handleRowClick(
                        row,
                        e,
                        cell.column.id === 'select',
                      )
                    }
                  >
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </Td>
                );
              })}
            </Tr>
          ))}
        </Tbody>
      </Table>
      {table.getPageCount() > 1 && (
        <Flex p={4} direction="row" alignItems="center" bg='gray.50'>
          <Box>
            <Select
              value={table.getState().pagination.pageSize}
              onChange={(e) => {
                table.setPageSize(Number(e.target.value));
              }}
              bg='white'
            >
              {[10, 100, 1000].map((pageSize) => (
                <option key={pageSize} value={pageSize}>
                        Show {pageSize}
                </option>
              ))}
            </Select>
          </Box>
          <Spacer />
          <Flex>
            <Text variant="secondary">
              {table.getState().pagination.pageIndex + 1} of{' '}{table.getPageCount()}
            </Text>
          </Flex>
          <Spacer />
          <Flex alignItems="right" justifyContent="right" flexDirection="row">
            <Button
              onClick={() => table.previousPage()}
              isDisabled={!table.getCanPreviousPage()}
              leftIcon={<ArrowBackIcon />}
              mr={2}
            >
                    Previous
            </Button>
            <Button
              onClick={() => table.nextPage()}
              isDisabled={!table.getCanNextPage()}
              rightIcon={<ArrowForwardIcon />}
              ml={2}
            >
                    Next
            </Button>
          </Flex>
        </Flex>
      )
      }
      {((data?.length === 0) || table.getRowModel().rows?.length === 0) && (
        <Box m={6}>
          {emptyMessage || 'There\'s nothing here right now!'
          }
        </Box>
      )
      }
    </Box>
  );
}
