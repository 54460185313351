import * as React from 'react';
import {
  Flex, HStack, Heading, Icon, Link, Text,
} from '@chakra-ui/react';
import { GoOrganization } from 'react-icons/go';

import { VscGithub } from 'react-icons/vsc';
import { ReactComponent as NextflowLogo } from '../../images/nextflow_logo.svg';
import { ReactComponent as SnakemakeLogo } from '../../images/snakemake_logo.svg';

type PipelineCardProps = {
  result: any;
  selectedPipeline: string;
  // eslint-disable-next-line @typescript-eslint/ban-types
  onClick: Function; // TODO: define function shape and remove ignore
  style: any;
}

const PipelineRow = ({
  result, selectedPipeline, onClick, style,
}: PipelineCardProps) => {
  if (!result) {
    return <></>;
  }
  return (
    <Flex
      borderWidth="1.5px" borderColor="#EDF0FF"
      bg={selectedPipeline === result?.name ? 'blues.50' : '#ffffff'}
      height='3rem'
      position='relative'
      _hover={{
        cursor: 'pointer',
        textDecoration: 'underline',
      }}
      onClick={async () => {
        await onClick(result);
      }}
      {...style}
    >
      <Flex
        position='absolute'
        top={0}
        bottom={0}
        left={0}
        right={0}
        display="flex"
        alignItems="center"
        justifyContent="center"
        opacity={0}
        backgroundColor="rgba(255, 255, 255, 0.8)"
        backdropFilter="blur(5px)"
        transition="opacity 0.3s"
        zIndex={1}
        _hover={{
          opacity: 1,
        }}
      >
        <Text fontWeight='600' color='blues.700'>
          {`Choose "${result.name}" ->`}
        </Text>
      </Flex>
      <Flex
        top={0}
        bottom={0}
        left={0}
        right={0}
        position='absolute'
      >
        <HStack ml='1em'>
          {result.imported && <Icon as={GoOrganization} fill='blues.700' />}
          <Heading
            as='h3'
            fontWeight='600'
            fontSize='18px'
            color='blues.700'
            width='40ch'
          >
            {result.name}
          </Heading>
        </HStack>
        <Flex width='100%' justify='right' mr='1em'>
          <HStack>
            <Link href={`https://github.com/${result.name}`} isExternal>
              <VscGithub />
            </Link>
            <Flex align='center' ml={2}>
              {
                result.wf_manager === 'nextflow'
                  ? <NextflowLogo width={'65px'} height={'100%'} opacity={0.9}/>
                  : <SnakemakeLogo width={'100px'} height={'100%'} opacity={0.9}/>
              }
            </Flex>
          </HStack>
        </Flex>
      </Flex>
    </Flex>
  );
};

export default PipelineRow;
