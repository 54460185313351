import * as React from 'react';
import { useEffect, useState } from 'react';
import {
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  Icon,
  Input,
  InputGroup,
  InputRightElement,
  Tooltip,
} from '@chakra-ui/react';
import { BsQuestionCircle } from 'react-icons/bs';
import { withRequiredAuthInfo } from '@propelauth/react';
import { useQuery } from '@tanstack/react-query';
import { GenericFile } from '../types';

type DataInput = {
  pathValue: string,
  isExternallyValidated: boolean,
  needsExternalValidation: boolean,
  isPendingSubmit: boolean,
  isS3: boolean,
  file?: GenericFile,
}

export async function validateURL({ orgId, accessToken, url }) {
  const response = await fetch(`${process.env.REACT_APP_API_URL}/app/${orgId}/files/validate`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${accessToken}`,
    },
    body: JSON.stringify({ url }),
  });
  if (response.ok) {
    return response.json();
  }
  const body = await response.json();
  throw new Error(body.error || 'Error connecting to server');
}

const DataLocationInput = ({
  onSubmit, typeLabel, placeholder, tooltipText, validate, minLength, accessToken, orgHelper,
}: any) => {
  const [dataInput, setDataInput] = useState<DataInput>({
    pathValue: '',
    isExternallyValidated: false,
    needsExternalValidation: typeLabel === 'input', // hacky enum-like usage for now
    isS3: false,
    isPendingSubmit: false,
  });
  const [fieldError, setFieldError] = useState('');
  const isSubmitDisabled = !!fieldError || dataInput.pathValue.length < minLength;

  function handleExternalValidationError(errorMessage) {
    setFieldError(errorMessage);
    setDataInput({
      ...dataInput,
      isExternallyValidated: false,
      isPendingSubmit: false,
    });
  }

  // Validate new input data. Only using for registered input files right now.
  const { isFetching } = useQuery(
    ['validateUri', dataInput.pathValue],
    () => validateURL({
      orgId: orgHelper.getOrgIds()[0],
      accessToken,
      url: dataInput.pathValue,
    }),
    {
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      enabled: !dataInput.isS3 && dataInput.needsExternalValidation && dataInput.isPendingSubmit,
      retry: false,
      onError: (err: Error) => handleExternalValidationError(err.message),
      onSuccess: (file: GenericFile) => setDataInput({
        ...dataInput,
        isExternallyValidated: true,
        file,
      }),
    },
  );

  function submitValue() {
    onSubmit({ pathValue: dataInput.pathValue, file: dataInput.file });
    setDataInput({
      ...dataInput,
      isExternallyValidated: false,
      isPendingSubmit: false,
      pathValue: '',
    });
  }

  // Automatically propagate submit once ready
  useEffect(() => {
    const canSubmit = !dataInput.needsExternalValidation || dataInput.isExternallyValidated || dataInput.isS3;
    if (canSubmit && dataInput.isPendingSubmit) {
      submitValue();
    }
  }, [dataInput.needsExternalValidation, dataInput.isPendingSubmit, dataInput.isExternallyValidated, setFieldError]);

  function validateField(value) {
    const errorMessage = validate(value);
    setFieldError(errorMessage);
  }

  function handleInputChange(newValue) {
    setDataInput({
      ...dataInput,
      pathValue: newValue,
      isExternallyValidated: false,
      isS3: dataInput.pathValue.startsWith('s3://'),
    });
    validateField(newValue);
  }

  function handleSubmit(event) {
    if (fieldError) {
      // Submit shouldn't happen with a fieldError; this is an extra guard
      return;
    }
    setDataInput({
      ...dataInput,
      isPendingSubmit: true,
    });
    event.preventDefault();
    event.stopPropagation();
  }

  return (
    <Flex
      id='input-section'
      gap='0.75rem'
      align='center'
      alignSelf='stretch'
    >
      <FormControl isInvalid={!!fieldError}>
        <InputGroup display='flex' alignItems='flex-start' flex='1 0 0' justifyContent='center' size='md'>
          <Input
            display='flex'
            flexDirection='column'
            padding='0rem 3rem 0rem 1rem'
            placeholder={placeholder || 'Type here'}
            value={dataInput.pathValue}
            onChange={(e) => handleInputChange(e.target.value)}
            autoFocus={false}
            onKeyUp={(event) => {
              if (event.key === 'Enter' && !isSubmitDisabled) {
                handleSubmit(event);
              }
            }}
          />
          <InputRightElement
            display='flex'
            height='100%'
            position='absolute'
            right='0rem'
            width='max-content'
            gap='0.625rem'
            padding='0rem 0.5rem'
            alignItems='center'
            justifyContent='center'
          >
            <Button
              onClick={handleSubmit}
              colorScheme='blue'
              isDisabled={isSubmitDisabled}
              borderRadius='5px'
              size='xs'
              padding='0rem 0.5rem'
              isLoading={isFetching}
              loadingText={`Verifying ${typeLabel}`}
            >
              Add as {typeLabel}
            </Button>
          </InputRightElement>
        </InputGroup>
        <FormErrorMessage position='absolute' bottom='-1.5rem'>{fieldError}</FormErrorMessage>
      </FormControl>
      <Tooltip label={tooltipText} hasArrow placement='top'>
        <Flex>
          <Icon as={BsQuestionCircle} width='1rem' height='1rem' color='gray.500'/>
        </Flex>
      </Tooltip>
    </Flex>
  );
};

export default withRequiredAuthInfo(DataLocationInput);
