import {
  Alert,
  AlertDescription, AlertIcon,
  AlertTitle,
  Box,
  Flex,
  Icon,
  Text,
} from '@chakra-ui/react';

import { get, countBy } from 'lodash';
import { useQuery } from '@tanstack/react-query';
import { withRequiredAuthInfo } from '@propelauth/react';

import TransferProgress from './TransferProgress';

const StatusMessages = {
  initialized: 'Setting up...',
  awaiting_execution: 'Queued...',
  beginning_execution: 'Starting the run...',
  executing: 'Running...',
  complete: 'Pipeline run complete 🎉',
  failed: '',
  terminated: '',
  terminating: '',
  resumed: '',
};

type transferSummary = {
  fileName: string,
  completedFiles: number,
  totalFiles: number,
  isCurrentFileInput: boolean,
  isCurrentFileOutput: boolean,
  isTransferring: boolean,
  progress: number,
};

function getTransferSummary(transferDetails) {
  const currentFile = transferDetails.find((file) => file.startedAt && !file.finishedAt);
  const fileCounts = countBy(transferDetails, (file) => (file.isInput ? 'inputFiles' : 'outputFiles'));

  const completedInputFileCount = transferDetails.filter((file) => file.isInput
        && file.startedAt && file.finishedAt).length;
  const completedOutputFileCount = transferDetails.filter((file) => !file.isInput
        && file.startedAt && file.finishedAt).length;

  const isCurrentFileInput = (currentFile && currentFile.isInput)
        || (completedInputFileCount > 0 && completedInputFileCount < fileCounts.inputFiles);
  const isCurrentFileOutput = (currentFile && !currentFile.isInput)
        || (completedOutputFileCount > 0 && completedOutputFileCount < fileCounts.outputFiles);

  const isTransferring = isCurrentFileInput || isCurrentFileOutput;
  const completedFiles = isCurrentFileInput ? completedInputFileCount : completedOutputFileCount;
  const totalFiles = isCurrentFileInput ? fileCounts.inputFiles : fileCounts.outputFiles;

  const summary: transferSummary = {
    fileName: currentFile?.fileName,
    completedFiles,
    totalFiles,
    isCurrentFileInput,
    isCurrentFileOutput,
    isTransferring,
    progress: currentFile?.progress || 0,
  };
  return summary;
}

const TopInfo = ({
  accessToken, orgHelper, status, errorMessage, id,
}: any) => {
  const orgId = orgHelper.getOrgIds()[0];
  const { data: transferDetails } = useQuery(
    [`transferDetails${id}`, orgId],
    async () => {
      const response = await
      fetch(`${process.env.REACT_APP_API_URL}/app/${orgId}/runs/tes-tasks/${id}/transfers`, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${accessToken}`,
        },
      });
      if (!response.ok) {
        const body = await response.json();
        throw new Error(body.error || 'Error connecting to server');
      }
      return response.json();
    },
    {
      refetchOnMount: true,
      refetchOnWindowFocus: false,
      refetchInterval: 2000,
      enabled: status === 'executing',
    },
  );

  const transferSummary = transferDetails ? getTransferSummary(transferDetails) : null;
  const statusMessage = get(StatusMessages, status, 'Unknown status');

  const errorAlert = (
    <>
      <Alert status='error' flexDirection='row' alignItems='center' bg='red.100' gap='0.75rem' borderRadius='0.625rem' >
        <Icon as={AlertIcon} color='red.500' boxSize='1.5rem'/>
        <Box>
          <AlertTitle>Execution failed</AlertTitle>
          <AlertDescription>
            <Text>
              {errorMessage || 'Check the streamed logs or workflow manager log for details.'}
            </Text>
          </AlertDescription>
        </Box>
      </Alert>
    </>
  );

  const terminatedAlert = (
    <>
      <Alert status='info' flexDirection='row' alignItems='center' bg='gray.200' gap='0.75rem' borderRadius='0.625rem' >
        <Icon as={AlertIcon} color='gray.500' boxSize='1.5rem'/>
        <Box>
          <AlertTitle>Run terminated</AlertTitle>
          <AlertDescription>
            <Text>
              {'Someone in your team terminated this run.'}
            </Text>
          </AlertDescription>
        </Box>
      </Alert>
    </>
  );

  return (
    <Flex
      id='detail-content'
      direction='column'
      justify='center'
      alignItems='flex-start'
      gap='0.3125rem'
      alignSelf='stretch'
    >
      {status === 'failed' && errorAlert}
      {status === 'terminated' && terminatedAlert}
      <TransferProgress hidden={!(status === 'executing' && transferSummary?.isTransferring)}
        transferSummary={transferSummary}/>
      {!transferSummary?.isTransferring
      && <Text variant='text-lg/lineHeight-7/font-normal' color='gray.700'>
        {statusMessage}
      </Text>}
    </Flex>
  );
};

export default withRequiredAuthInfo(TopInfo);
