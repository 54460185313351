export async function fetchDataRepos({
  orgId, accessToken,
}) {
  return fetch(`${process.env.REACT_APP_API_URL}/app/${orgId}/data-repos`, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${accessToken}`,
    },
  }).then((response) => {
    if (response.ok) {
      return response.json();
    }
    throw new Error('Failed to fetch data repos');
  });
}

async function fetchRegisteredFilesAtPath({
  path, orgId, accessToken, subdir,
}) {
  return fetch(`${process.env.REACT_APP_API_URL}/app/${orgId}/files/registered?url=${path}&sub_dir=${subdir || ''}`, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${accessToken}`,
    },
  }).then(async (response) => {
    if (response.ok) {
      return response.json();
    }
    throw new Error('Network response was not ok');
  });
}

async function fetchUnregisteredFilesAtPath({ path, orgId, accessToken }) {
  return fetch(`${process.env.REACT_APP_API_URL}/app/${orgId}/files?path=${encodeURIComponent(path)}`, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${accessToken}`,
    },
  }).then((response) => {
    if (response.ok) {
      return response.json();
    }
    throw new Error('Network response was not ok');
  });
}

export async function fetchFilesAtPath({
  path, useRegisteredFiles, ...args
}) {
  if (!path.endsWith('/')) {
    return [];
  }

  const isFetchingRegisteredFiles = useRegisteredFiles && path.startsWith('fd');

  if (isFetchingRegisteredFiles) {
    return fetchRegisteredFilesAtPath({ path, ...args });
  }
  return fetchUnregisteredFilesAtPath({ path, ...args });
}
