import { useState } from 'react';
import { Field, Formik } from 'formik';
import {
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Select,
  useToast,
} from '@chakra-ui/react';
import { withRequiredAuthInfo } from '@propelauth/react';
import { useQuery } from '@tanstack/react-query';

async function importPipelineFromUrl({ orgId, accessToken, formValues }) {
  const response = await fetch(`${process.env.REACT_APP_API_URL}/app/${orgId}/gh/import`, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${accessToken}`,
    },
    body: JSON.stringify(formValues),
  });
  if (!response.ok) {
    const body = await response.json();
    throw new Error(body.error || 'Error connecting to server');
  }
}
const PipelineConnectFromUrlModal = ({
  isOpen,
  onClose,
  refetchPipelines,
  accessToken,
  orgHelper,
}: any) => {
  const orgId = orgHelper.getOrgIds()[0];
  const [finalFormValues, setFinalFormValues] = useState({});
  const toast = useToast();

  const wfManagers = ['snakemake', 'nextflow'];

  const { refetch: fetchUrlPipeline, isFetching } = useQuery(
    ['importPipelineFromUrl'],
    async () => {
      await importPipelineFromUrl({
        orgId,
        accessToken,
        formValues: finalFormValues,
      });
      refetchPipelines();
      toast({
        title: 'Pipeline imported',
        status: 'success',
        duration: 5_000,
        isClosable: true,
      });
      onClose();
      return {};
    },
    {
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      enabled: false,
      retry: false,
    },
  );

  return (
    <Modal isOpen={isOpen} onClose={onClose} size='xl'>
      <ModalOverlay bg='blackAlpha.300' backdropFilter='blur(5px)'/>
      <ModalContent>
        <ModalHeader>Import pipeline</ModalHeader>
        <ModalCloseButton/>
        <Formik
          initialValues={{
            github_url: '',
            wf_manager: wfManagers[0],
          }}
          onSubmit={async (values, actions) => {
            // Does need to be awaited so the values are set when pulled in the useQuery
            await setFinalFormValues(values);
            const response: any = await fetchUrlPipeline();
            if (response.isError) {
              actions.setErrors({ github_url: response.error.message });
            }
          }}
        >
          {({
            handleSubmit, errors, touched, isValid,
          }: any) => (
            <form onSubmit={handleSubmit}>
              <ModalBody>
                <Field name='github_url' validate={undefined}>
                  {({ field, form }) => (
                    <FormControl key={errors.github_url} isInvalid={errors.github_url && touched.github_url}>
                      <FormLabel>GitHub URL</FormLabel>
                      <Input
                        placeholder='https://github.com/{org}/{repo}'
                        value={field.value}
                        onChange={(e) => form.setFieldValue('github_url', e.target.value)}
                      />
                      <FormErrorMessage>{errors.github_url}</FormErrorMessage>
                    </FormControl>
                  )}
                </Field>
                <FormControl mt="1rem">
                  <FormLabel htmlFor="wf_manager">Pipeline type</FormLabel>
                  <Field
                    as={Select}
                    id="wf_manager"
                    name="wf_manager"
                    w='9em'
                  >
                    {wfManagers.map((wfManager) => (
                      <option key={wfManager} value={wfManager}>{wfManager}</option>
                    ))}
                  </Field>
                </FormControl>
              </ModalBody>
              <ModalFooter>
                <Button
                  type="submit"
                  colorScheme='blue'
                  isDisabled={!touched || !isValid}
                  isLoading={isFetching}
                  loadingText="Importing..."
                >
                   Import pipeline
                </Button>
              </ModalFooter>
            </form>
          )}
        </Formik>
      </ModalContent>
    </Modal>
  );
};

export default withRequiredAuthInfo(PipelineConnectFromUrlModal);
