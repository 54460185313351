async function fetchPipelines({ orgId, accessToken }: any) {
  return fetch(`${process.env.REACT_APP_API_URL}/app/${orgId}/pipelines`, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${accessToken}`,
    },
  }).then(async (response) => {
    if (response.ok) {
      return response.json();
    }
    throw new Error('Network response was not ok');
  });
}

export default fetchPipelines;
