import {
  Button, Flex, Link, Spacer, Text,
} from '@chakra-ui/react';
import { InfoOutlineIcon } from '@chakra-ui/icons';
import { Link as RouterLink } from 'react-router-dom';
import * as React from 'react';
import SectionBox from '../../../SectionBox';

export const NoFileSystemError = ({ setActiveStep, selectedPipeline }) => (
  <>
    <SectionBox>
      <Flex alignItems='center' justifyContent='center' mt='8rem' mb='8rem' bg='white'>
        <InfoOutlineIcon color='greys.500'/>
        <Text ml='1ch'>
          <Link as={RouterLink} to='/billing' ml='0.5ch'>Change your plan or start a trial</Link> to launch
          pipelines.
        </Text>
      </Flex>
    </SectionBox>
    <Flex mb={8}>
      <Button colorScheme='blue' onClick={() => setActiveStep(0)}>
        Back
      </Button>
      <Spacer/>
      <Button
        type="submit"
        colorScheme='blue'
        isDisabled={true}
      >
        Launch "{selectedPipeline}"
      </Button>
    </Flex>
  </>
);

export const NoVersionsError = ({
  values, orgId, setActiveStep, selectedPipeline,
}) => (
  <>
    <SectionBox>
      <Flex alignItems='center' justifyContent='center' mt='8rem' mb='8rem' bg='white'>
        <InfoOutlineIcon color='greys.500'/>
        <Text ml='1ch'>
          Unable to retrieve pipeline details.
          {values.imported
            ? <Link ml='1ch' mr='1ch' isExternal
              href={`https://github.com/apps/flowdeploy-pipeline-manager/installations/new?state=${orgId}`}>
              Does FlowDeploy still have access?
            </Link>
            : ' '
          }
          You can try refreshing the page.
        </Text>
      </Flex>
    </SectionBox>
    <Flex mb={8}>
      <Button colorScheme='blue' onClick={() => setActiveStep(0)}>
        Back
      </Button>
      <Spacer/>
      <Button
        type="submit"
        colorScheme='blue'
        isDisabled={true}
      >
        Launch "{selectedPipeline}"
      </Button>
    </Flex>
  </>
);
