import {
  Accordion,
  AccordionButton,
  AccordionItem, AccordionPanel,
  Box,
  Button,
  Flex,
  FormControl,
  FormErrorMessage, FormHelperText,
  FormLabel, Icon, Input, InputGroup, InputLeftAddon, InputRightElement, Spacer, Text,
  Tooltip,
} from '@chakra-ui/react';
import { Field, FieldArray } from 'formik';
import * as React from 'react';
import {
  AddIcon, ChevronDownIcon, ChevronRightIcon, DeleteIcon,
} from '@chakra-ui/icons';
import { isEmpty, reduce } from 'lodash';
import { useContext } from 'react';
import { PrismLight as SyntaxHighlighter } from 'react-syntax-highlighter';
import darkPrism from 'react-syntax-highlighter/dist/esm/styles/prism/vs-dark';
import SectionBox from '../../../SectionBox';
import { UserContext } from '../../../UserContext';
import FileSelection from '../../../data/FileSelection';
import ErrorMessage from '../ErrorMessage';

function validateRunLocation(value: string) {
  if (value.length > 0 && !value.startsWith('fd://')) {
    return 'Run location must be in the shared file system (fd://)';
  }
  if (/^\s|\s$/.test(value)) {
    return 'Run location must not start or end with whitespace';
  }
  return '';
}

function validateCliArgs(value: string) {
  if (value.includes("'")) {
    return 'Command-line arguments cannot contain single quotes';
  }
  return '';
}

function truncateFromMiddle(path, maxLength) {
  if (maxLength > path.length) {
    return path;
  }
  const pathParts = path.split('/');
  return `fd://${pathParts[2]}/[...]/${pathParts[pathParts.length - 2]}/`;
}

// Copied from the previous launch page
function generateCommand({ values, isSnakemake }) {
  const inputArgs = reduce(
    values.inputs,
    (result, input) => {
      let cleanedPath = input.path;
      if (!input.arg) {
        return result;
      }
      if (input.destination) {
        cleanedPath = input.destination.replace(/^(fd:\/)/, '');
      } else if (input.source?.startsWith('fd://')) {
        cleanedPath = input.source.replace(/^(fd:\/)/, '');
      }
      return `${input.arg} ${cleanedPath} ${result}`;
    },
    '',
  );
  if (isSnakemake) {
    return `snakemake ${values?.snakemake_targets?.join(' ') || ''}\n\t`
      + `-s ${values?.snakefile_location}\n\t`
      + `${inputArgs ? `${inputArgs}\n\t` : ''}`
      + `${values?.cli_args || ''} -j 100`;
  }
  let profile: string;
  profile = values?.profiles?.length > 0
    ? `-profile ${values.profiles.join(',')}`
    : '-profile flowdeploy';
  if (!profile.includes('flowdeploy')) {
    profile += ',flowdeploy';
  }
  const pipelineVersionCommand = values?.pipeline_version ? ` -r ${values.pipeline_version}\n\t` : '';
  const inputArgsCommand = inputArgs.trim().length > 0 ? `${inputArgs.trim()}\n\t` : '';
  const cliArgsCommand = values?.cli_args?.trim().length > 0 ? `${values?.cli_args}\n\t` : '';
  return `nextflow -quiet -log /.nextflow/nextflow.log.\${i} run ${values?.pipeline}`
    + `${pipelineVersionCommand}`
    + `${inputArgsCommand}`
    + `${cliArgsCommand}`
    + `--outdir ${values?.outdir?.replace(/^(fd:\/)/, '')}\n\t`
    + `${profile}\n\t`
    + '-resume';
}

const SnakefileAndDirectoryInput = ({
  setFieldValue, folderLabel, fileLabel,
}) => {
  const { orgData } = useContext(UserContext);
  const useSingleInstanceLaunch = orgData.is_single_instance;

  if (useSingleInstanceLaunch) {
    return (
      <Field name='snakefile_location' validate={undefined}>
        {({ field, form }) => (
          <FormControl isInvalid={form.errors.snakefile_location && form.touched.snakefile_location}>
            <FormLabel>Snakefile location</FormLabel>
            <Flex>
              <InputGroup>
                <Input
                  {...field}
                  placeholder='workflow/Snakefile'
                  value={field.value}
                  onChange={(e) => setFieldValue('snakefile_location', e.target.value)}
                />
              </InputGroup>
            </Flex>
            <FormHelperText>
              Snakefile location, relative to the project's root. Defaults to "workflow/Snakefile".
            </FormHelperText>
            <FormErrorMessage>{form.errors.snakefile_location}</FormErrorMessage>
          </FormControl>
        )}
      </Field>
    );
  }

  return (
    <>
      <Box width='100%' height='1px' bg='gray.300' mt='0.75rem' mb='0.75rem' />
      <Field name='snakemake_folder' validate={undefined}>
        {({ field, form }) => (
          <FormControl isInvalid={form.errors.snakemake_folder && form.touched.snakemake_folder}>
            <FormLabel>Snakemake folder name for run</FormLabel>
            <InputGroup>
              <Tooltip
                label={folderLabel}
                isDisabled={folderLabel.length <= 60}
                closeDelay={200}
              >
                <InputLeftAddon
                  children={truncateFromMiddle(folderLabel, 60)}
                  fontSize={{
                    base: 'sm', xl: 'md',
                  }}
                  maxWidth='50%'
                  noOfLines={1}
                  pt={2}
                />
              </Tooltip>
              <Input
                {...field}
                placeholder='some-folder'
                value={field.value}
                onChange={(e) => setFieldValue('snakemake_folder', e.target.value)}
              />
            </InputGroup>
            <FormHelperText>
              Snakemake's working directory, relative to the run location. Defaults to pipeline name.
            </FormHelperText>
            <FormErrorMessage>{form.errors.snakemake_folder}</FormErrorMessage>
          </FormControl>
        )}
      </Field>
      <Box height='1px' mt={2} mb={2}/>
      <Field name='snakefile_location' validate={undefined}>
        {({ field, form }) => (
          <FormControl isInvalid={form.errors.snakefile_location && form.touched.snakefile_location}>
            <FormLabel>Snakefile location</FormLabel>
            <Flex>
              <InputGroup>
                <Tooltip
                  label={fileLabel}
                  isDisabled={fileLabel.length <= 60}
                  closeDelay={200}
                >
                  <InputLeftAddon
                    children={truncateFromMiddle(fileLabel, 60)}
                    fontSize={{
                      base: 'sm', xl: 'md',
                    }}
                  />
                </Tooltip>
                <Input
                  {...field}
                  placeholder='workflow/Snakefile'
                  value={field.value}
                  onChange={(e) => setFieldValue('snakefile_location', e.target.value)}
                />
              </InputGroup>
            </Flex>
            <FormHelperText>
              Snakefile location, relative to the Snakemake folder name. Defaults to "workflow/Snakefile".
            </FormHelperText>
            <FormErrorMessage>{form.errors.snakefile_location}</FormErrorMessage>
          </FormControl>
        )}
      </Field>
    </>
  );
};

const SnakemakeTargetInput = ({ values, setFieldValue }) => (
  <>
    <FieldArray
      name="snakemake_targets"
      render={(arrayHelpers) => (
        <>
          <FormControl>
            <FormLabel>Snakemake targets</FormLabel>
            <ErrorMessage name="snakemake_targets"/>
            {values.snakemake_targets && values.snakemake_targets.length > 0 ? (
              values.snakemake_targets.map((target, index) => (
                <Flex key={`snakemake_targets_${index}`} mb='0.5rem' maxWidth='50%'>
                  <InputGroup>
                    <Input
                      pr='9rem'
                      placeholder='mapping/A.bam'
                      value={target}
                      onChange={
                        (e) => setFieldValue(`snakemake_targets[${index}]`, e.target.value)
                      }
                    />
                    <InputRightElement width='9rem'>
                      <Button
                        variant='ghost'
                        colorScheme='reds'
                        leftIcon={<DeleteIcon/>}
                        onClick={() => arrayHelpers.remove(index)}
                        ml='2ch'
                        width='9rem'
                      >
                        Remove
                      </Button>
                    </InputRightElement>
                  </InputGroup>
                </Flex>
              ))
            ) : (
              <></>
            )}
            <Button
              variant='outline'
              colorScheme='gray'
              width='10rem'
              mt='0.2rem' mb='0.2rem'
              leftIcon={<Icon as={AddIcon} width='1rem' height='1rem' color='gray.800'/>}
              onClick={() => arrayHelpers.push('')}
            >
              Add a target
            </Button>
            <FormHelperText>Snakemake targets (e.g. "mapping/A.bam" in "snakemake mapping/A.bam")</FormHelperText>
          </FormControl>
        </>
      )}
    />
  </>
);

const PipelineSettingsSection = ({
  setFieldValue, values, isSnakemake,
}) => {
  const { orgData } = useContext(UserContext);
  const useSingleInstanceLaunch = orgData.is_single_instance;

  return (
    <SectionBox header='Configuration' headerId='pipeline-settings-configuration'>
      <Box padding='0.66rem 1.5rem 1.25rem'>
        {!useSingleInstanceLaunch && <FileSelection
          label='Run location (i.e. working directory)'
          fieldName='run_location'
          description='A shared file system path to use as the working directory for the pipeline. Used as default
                location for some files and the workflow cache.'
          setFieldValue={setFieldValue}
          isDirOnly
          isFdOnly
          validate={validateRunLocation}
          isRequired
        />}
        {isSnakemake && <SnakemakeTargetInput values={values} setFieldValue={setFieldValue} />}
      </Box>
      <Box width='100%' height='1px' bg='gray.300' mt='0.75rem' mb='0.75rem' />
      <Box padding='0.66rem 1.5rem 1.25rem'>
        {
          isSnakemake && <SnakefileAndDirectoryInput
            setFieldValue={setFieldValue}
            folderLabel={
              values.run_location.trim().endsWith('/')
                ? values.run_location.trim()
                : `${values.run_location}/`
            }
            fileLabel={
              `${
                values.run_location.trim().endsWith('/')
                  ? values.run_location.trim()
                  : `${values.run_location}/`
              }${
                !isEmpty(values?.snakemake_folder?.trim())
                  ? values.snakemake_folder
                  : values.pipeline
              }/`
            }
          />
        }
      </Box>
      <Box width='100%' height='1px' bg='gray.300' mt='0.75rem' mb='0.75rem' />
      <Box padding='0.66rem 1.5rem 1.25rem'>
        <FieldArray
          name="profiles"
          render={(arrayHelpers) => (
            <>
              <FormControl>
                <FormLabel>Profiles</FormLabel>
                <ErrorMessage name="profiles"/>
                {values.profiles && values.profiles.length > 0 ? (
                  values.profiles.map((profile, index) => (
                    <Flex key={`profile${index}`} mb='0.5rem' maxWidth='20rem'>
                      <InputGroup>
                        <Input
                          pr='9rem'
                          placeholder='docker'
                          value={profile}
                          onChange={(e) => setFieldValue(`profiles[${index}]`, e.target.value)}
                        />
                        <InputRightElement width='9rem'>
                          <Button
                            variant='ghost'
                            colorScheme='reds'
                            leftIcon={<DeleteIcon/>}
                            onClick={() => arrayHelpers.remove(index)}
                            ml='2ch'
                            width='9rem'
                          >
                            Remove
                          </Button>
                        </InputRightElement>
                      </InputGroup>
                    </Flex>
                  ))
                ) : (
                  <></>
                )}
                <Button
                  variant='outline'
                  colorScheme='gray'
                  width='10rem'
                  mt='0.2rem' mb='0.2rem'
                  leftIcon={<Icon as={AddIcon} width='1rem' height='1rem' color='gray.800'/>}
                  onClick={() => arrayHelpers.push('')}
                >
                  Add a profile
                </Button>
                <FormHelperText>{'Workflow manager configuration profiles'}</FormHelperText>
              </FormControl>
            </>
          )}
        />
      </Box>
      <Box width='100%' height='1px' bg='gray.300' mt='0.75rem' mb='0.75rem' />
      <Box padding='0.66rem 1.5rem 1.25rem'>
        <Field name='cli_args' validate={validateCliArgs}>
          {({ field, form }) => (
            <FormControl isInvalid={form.errors.cli_args && form.touched.cli_args}>
              <FormLabel>Command-line arguments</FormLabel>
              <Input
                {...field}
                placeholder='--any args'
                value={field.value}
                onChange={(e) => setFieldValue('cli_args', e.target.value)}
              />
              <Flex>
                <FormHelperText>Raw command line arguments for the workflow manager</FormHelperText>
                <Box id='pipeline-settings-cli-args' width='0.1rem' height='0.01rem' />
              </Flex>
              <FormErrorMessage>{form.errors.cli_args}</FormErrorMessage>
            </FormControl>
          )}
        </Field>
      </Box>
      <Box width='100%' height='1px' bg='gray.300' mt='0.75rem' mb='0.75rem' />
      <Box padding='0.66rem 1.5rem 1.25rem'>
        <Accordion allowToggle mt='1rem'>
          <AccordionItem>
            {({ isExpanded }) => (
              <>
                <AccordionButton bg={'white'}>
                  {isExpanded ? (
                    <ChevronDownIcon fontSize='18px'/>
                  ) : (
                    <ChevronRightIcon fontSize='18px'/>
                  )}
                  <Text fontSize='lg' fontWeight={600} ml={1} alignContent='center' pt={2} pb={2}>
                    {isSnakemake ? 'Snakemake' : 'Nextflow'} command preview
                  </Text>
                  <Spacer />
                </AccordionButton>
                <AccordionPanel pb={0} paddingInlineStart={0} paddingInlineEnd={0} >
                  <SyntaxHighlighter language="bash" style={darkPrism}>
                    {generateCommand({ values, isSnakemake })}
                  </SyntaxHighlighter>
                </AccordionPanel>
              </>
            )}
          </AccordionItem>
        </Accordion>
      </Box>
    </SectionBox>
  );
};

export default PipelineSettingsSection;
