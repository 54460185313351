import {
  Box,
  Button,
  ButtonGroup, Editable, EditableInput, EditablePreview,
  Flex, Icon, IconButton, Input,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  useDisclosure,
  useEditableControls,
} from '@chakra-ui/react';
import {
  BsFileEarmarkText, BsFolder, BsThreeDotsVertical, BsXCircle,
} from 'react-icons/bs';
import * as React from 'react';
import { CheckIcon, CloseIcon } from '@chakra-ui/icons';
import { FiEdit } from 'react-icons/fi';
import { IoEyeOutline } from 'react-icons/io5';
import FilePreviewModal from '../FilePreviewModal';
import DownloadMenuItem from '../DownloadMenuItem';

export const DirectoryEntry = ({ info }: any) => {
  const isDirectory = info.row.original?.is_dir || false;
  return (
    <Flex alignItems='center'>
      {isDirectory ? <BsFolder height='1.4em' width='1.4em'/> : <BsFileEarmarkText height='1.4em' width='1.4em'/>}
      <Box width='90%' ml='0.8ch'>{info.getValue() || 'N/A'}</Box>
    </Flex>
  );
};

export const Actions = ({
  onRemove, file, enableDownload, hidePreview,
}: any) => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const showPreviewButton = !file?.is_dir && !hidePreview;
  const showDownloadButton = enableDownload && !file?.is_dir;
  const showRemoveButton = !!onRemove;
  const isActionsMenuDisabled = !showPreviewButton && !showDownloadButton && !showRemoveButton;

  const registeredDirectoryRoot = file?.registered_directory_root;
  const url = registeredDirectoryRoot?.url || file?.url;
  const subdir = registeredDirectoryRoot
    ? registeredDirectoryRoot.subdir + file.name
    : undefined;

  return (
    <Flex width='100%' align='center' justify='right' p='-1rem'>
      {isOpen && <FilePreviewModal
        key={`file${file?.name}`}
        registeredUrl={file?.is_registered_file && url}
        file={file}
        path={file?.url}
        subdir={subdir}
        isOpen={isOpen}
        onClose={onClose}
      />}
      <Menu>
        <MenuButton
          as={Button}
          variant='ghost'
          onClick={(e) => e.stopPropagation()}
          size='sm'
          isDisabled={isActionsMenuDisabled}
        >
          <BsThreeDotsVertical />
        </MenuButton>
        <MenuList>
          {showPreviewButton && <MenuItem
            key='previewItem'
            icon={<Icon as={IoEyeOutline} width='1.25rem' height='1.25rem'/>}
            onClick={onOpen}
            isDisabled={file?.is_dir ?? false}
          >
            Preview
          </MenuItem>
          }
          {showDownloadButton && <DownloadMenuItem
            url={url}
            subdir={subdir}
            isRegisteredFile={file?.is_registered_file}
          />}
          {showRemoveButton && <MenuItem
            key='removeItem'
            icon={<Icon as={BsXCircle} width='1.25rem' height='1.25rem'/>}
            onClick={onRemove}
          >
            Remove from table
          </MenuItem>}
        </MenuList>
      </Menu>
    </Flex>
  );
};

export const EditableField = ({ defaultValue, onSubmit }: any) => {
  function EditableControls() {
    const {
      isEditing,
      getSubmitButtonProps,
      getCancelButtonProps,
      getEditButtonProps,
    } = useEditableControls();

    return isEditing ? (
      <ButtonGroup justifyContent='center' size='sm' onClick={(e) => e.stopPropagation()}>
        <IconButton
          aria-label='submit'
          variant='ghost'
          icon={<CheckIcon/>}
          {...getSubmitButtonProps()}
        />
        <IconButton
          aria-label='cancel'
          variant='ghost'
          icon={<CloseIcon/>}
          {...getCancelButtonProps()}
        />
      </ButtonGroup>
    ) : (
      <Flex justifyContent='center' onClick={(e) => e.stopPropagation()}>
        <IconButton
          aria-label='edit'
          variant='ghost'
          size='sm'
          icon={<FiEdit width='1.25rem' height='1.25rem' color='gray.500'/>}
          {...getEditButtonProps()}
        />
      </Flex>
    );
  }

  return (
    <Editable
      textAlign='left'
      defaultValue={defaultValue || ''}
      isPreviewFocusable={false}
      onSubmit={onSubmit}
    >
      <Flex align='center'>
        <EditablePreview onClick={(e) => e.stopPropagation()} />
        <Input as={EditableInput} onClick={(e) => e.stopPropagation()}/>
        <EditableControls />
      </Flex>
    </Editable>
  );
};
