import { withRequiredAuthInfo } from '@propelauth/react';
import {
  Box, Button, Flex, Heading, HStack, List, ListIcon, ListItem, Text, useToast,
} from '@chakra-ui/react';
import * as React from 'react';
import { ExternalLinkIcon, InfoOutlineIcon } from '@chakra-ui/icons';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { useSearchParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import Confetti from 'react-confetti';
import {
  GoCpu, GoFileDirectory, GoPerson, GoStack,
} from 'react-icons/go';
import { useFeatureFlagEnabled } from 'posthog-js/react';

import SectionBox from './SectionBox';
import { UserContext } from './UserContext';

async function requestTrial({ orgId, accessToken }) {
  const response = await fetch(`${process.env.REACT_APP_API_URL}/app/${orgId}/trial/create-checkout-session`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${accessToken}`,
    },
  });
  if (response.ok) {
    const jsonResponse = await response.json();
    window.open(jsonResponse.url, '_blank');
    return jsonResponse;
  }
  throw new Error('Failed to create trial');
}

async function finalizeTrial({ orgId, accessToken }) {
  const response = await fetch(`${process.env.REACT_APP_API_URL}/app/${orgId}/trial/finalize`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${accessToken}`,
    },
  });
  if (response.ok) {
    return response.json();
  }
  const body = await response.json();
  throw new Error(body.error || 'Failed to finalize trial');
}

const BillingPage = ({ orgHelper, accessToken }: any) => {
  const queryClient = useQueryClient();
  const toast = useToast();
  const [animateSuccess, setAnimateSuccess] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const shouldFinalize = searchParams.get('finalizeTrial') === 'true';
  const isTrialingByFeatureFlag = useFeatureFlagEnabled('is_trialing');

  const context = React.useContext(UserContext);
  const isTrialing = context.orgData?.is_trialing;
  const hasRemoteMountName = !!context.orgData?.remote_mount_name;
  const billingPortalUrl = context.orgData?.billing_portal_url;

  const orgId = orgHelper.getOrgIds()[0];
  const { refetch: fetchRequestTrial, isFetching: isTrialRequestFetching } = useQuery(
    ['requestTrial'],
    () => requestTrial({
      orgId,
      accessToken,
    }),
    {
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      enabled: false,
    },
  );

  const { isFetching: isFinalizingTrial, isSuccess: isTrialFinalized, error: finalizeTrialError } = useQuery(
    ['finalizeTrial'],
    () => finalizeTrial({
      orgId,
      accessToken,
    }),
    {
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      enabled: shouldFinalize && !animateSuccess,
    },
  );

  useEffect(() => {
    if (animateSuccess) {
      toast({
        title: 'Trial started!',
        status: 'success',
        duration: 5000,
        isClosable: true,
      });
      // setSearchParams({ finalizeTrial: 'false' })
      setTimeout(() => {
        queryClient.invalidateQueries({ queryKey: ['orgInfo'] });
      }, 500);
    }
  }, [animateSuccess, queryClient, setAnimateSuccess, setSearchParams, toast]);

  useEffect(() => {
    if (finalizeTrialError) {
      toast({
        title: 'Failed to start trial',
        description: `${finalizeTrialError}`,
        status: 'error',
        duration: 7000,
        isClosable: true,
      });
    } else if (shouldFinalize && isTrialFinalized) {
      setAnimateSuccess(true);
    }
  }, [isTrialFinalized, finalizeTrialError, shouldFinalize, setAnimateSuccess, toast]);

  const newUserContent = (
    <>
      <Text mt={4} mb={4}>Type: none</Text>
      <Box bg='translucent.200' height='1px'/>
      <Heading as='h4' size='small' mb='1rem' mt='1rem'>Try FlowDeploy for two weeks for $1! Includes:</Heading>
      <List spacing={3}>
        <ListItem alignItems='center' justifyContent='center'>
          <HStack>
            <ListIcon as={GoCpu} color='blues.500' />
            <Text>100 vCPU hours and up to 100 pipeline launches.</Text>
          </HStack>
        </ListItem>
        <ListItem>
          <HStack>
            <ListIcon as={GoFileDirectory} color='blues.500' />
            <Text>1 TB of data storage.</Text>
          </HStack>
        </ListItem>
        <ListItem>
          <HStack>
            <ListIcon as={GoStack} color='blues.500' />
            <Text>Unlimited connected pipelines, keys, tasks, flow configurations, and more.</Text>
          </HStack>
        </ListItem>
        <ListItem>
          <HStack>
            <ListIcon as={GoPerson} color='blues.500' />
            <Text>(Optional) A 30 minute consultation with a FlowDeploy engineer.</Text>
          </HStack>
        </ListItem>
      </List>
      <Button
        width='12rem'
        leftIcon={<ExternalLinkIcon />}
        colorScheme='blue'
        onClick={() => fetchRequestTrial()}
        isLoading={isFinalizingTrial || isTrialRequestFetching}
        loadingText='Starting trial...'
        mt='1.5rem'
      >
        Start trial
      </Button>
    </>
  );

  const trialingUserContent = (
    <>
      <Text mb={4}>Type: trial</Text>
      <Flex align='center'>
        <InfoOutlineIcon mr='0.5ch'/>
        <Text>
          You're in the trial period – nothing is due. Have fun!
        </Text>
      </Flex>
    </>
  );

  const establishedUserContent = (
    <>
      <Text mb={4}>Type: custom</Text>
      <Button width='12rem' leftIcon={<ExternalLinkIcon />} colorScheme='blue'>Billing portal</Button>
    </>
  );

  function showPlan() {
    if (isTrialing || isTrialFinalized || isTrialingByFeatureFlag) {
      return trialingUserContent;
    } if (hasRemoteMountName || billingPortalUrl) {
      return establishedUserContent;
    }
    return newUserContent;
  }

  return (
    <Box flex='4'>
      <Heading as='h2' size='xl' mb={2}>
        Billing
      </Heading>
      <Confetti
        width={window.innerWidth}
        height={window.innerHeight}
        recycle={false}
        run={animateSuccess}
      />
      <SectionBox header='Plan' isFormatted>
        {showPlan()}
      </SectionBox>
    </Box>
  );
};

export default withRequiredAuthInfo(BillingPage);
