import * as React from 'react';
import { Field, Formik } from 'formik';
import {
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  FormControl,
  FormLabel,
  Select, FormErrorMessage, AlertIcon, Alert,
} from '@chakra-ui/react';
import { withRequiredAuthInfo } from '@propelauth/react';
import { map } from 'lodash';

import { instanceTypes } from '../constants';

const InstanceTypes = () => (
  <>
    {map(instanceTypes, (instanceType) => (
      <option key={instanceType.name} value={instanceType.name}>{instanceType.name}</option>
    ))}
  </>
);

const SSHModal = ({
  isOpen,
  onClose,
  onSubmit,
  isLoading,
  name,
}: any) => (
  <Modal isOpen={isOpen} onClose={onClose} >
    <ModalOverlay bg='blackAlpha.300' backdropFilter='blur(5px)'/>
    <ModalContent>
      <ModalHeader>Debug "{name}" with SSH</ModalHeader>
      <ModalCloseButton/>
      <Formik
        initialValues={{
          ssh_key_label: '',
          instance_type: '',
        }}
        onSubmit={async (values) => {
          await onSubmit({
            ...values,
          });
        }}
      >
        {({
          handleSubmit, errors, touched, isValid,
        }: any) => (
          <form onSubmit={handleSubmit}>
            <ModalBody>
              <Alert status='info'>
                <AlertIcon/>
                  Interactive SSH runs continue indefinitely (until they are shut down or terminated).
              </Alert>
              <FormControl mt="2rem" isInvalid={!!errors.ssh_key_label && touched.ssh_key_label}>
                <FormLabel htmlFor="ssh_key_label">SSH key</FormLabel>
                <Field
                  as={Select}
                  id="ssh_key_label"
                  name="ssh_key_label"
                  validate={(value: string) => {
                    if (!value) {
                      return 'Please choose an SSH key';
                    }
                    return '';
                  }}
                >
                  {/* todo: add SSH keys from API – populate options similarly to instance type below */}
                </Field>
                <FormErrorMessage>{errors.ssh_key_label}</FormErrorMessage>
              </FormControl>
              <FormControl mt="1rem">
                <FormLabel htmlFor="instance_type">Instance type</FormLabel>
                <Field
                  as={Select}
                  id="instance_type"
                  name="instance_type"
                >
                  <InstanceTypes />
                </Field>
              </FormControl>
            </ModalBody>
            <ModalFooter>
              <Button
                type="submit"
                colorScheme='blue'
                isDisabled={!touched || !isValid}
                isLoading={isLoading}
                loadingText="Spawning..."
              >
                   Spawn run
              </Button>
            </ModalFooter>
          </form>
        )}
      </Formik>
    </ModalContent>
  </Modal>
);

export default withRequiredAuthInfo(SSHModal);
