import {
  Alert,
  AlertDescription,
  AlertTitle,
  Box,
  Button,
  Flex,
  Icon,
  Text,
} from '@chakra-ui/react';

import * as React from 'react';
import {
  BsArrowRepeat, BsFolder, BsWater, BsXOctagon,
} from 'react-icons/bs';
import { Link as RouterLink } from 'react-router-dom';
import SectionBox from '../../../SectionBox';
import TopInfo from './TopInfo';

const BottomButtons = ({
  status, resumedAs, onTerminateClick, onRetryClick, scrollToData, isRetryLoading,
}) => {
  const terminatedOrErrorAlert = (
    <>
      <Alert status='info' flexDirection='row' alignItems='center' bg='blue.50' gap='0.75rem' borderRadius='0.625rem' >
        <Icon as={BsArrowRepeat} color='blue.600' boxSize='1.5rem'/>
        <Box>
          <AlertTitle>Retry from savepoint</AlertTitle>
          <AlertDescription>
            <Text>
                This resumes the run from its cache, but the cache doesn't always include incomplete tasks.
                A new run is launched on retry.
            </Text>
          </AlertDescription>
        </Box>
      </Alert>
    </>
  );

  const resumedAlert = (
    <>
      <Alert status='info' alignItems='center' bg='orange.100' gap='0.75rem' borderRadius='0.625rem' >
        <Icon as={BsArrowRepeat} color='orange.500' boxSize='1.5rem'/>
        <Box>
          <AlertTitle>This run was resumed</AlertTitle>
          <AlertDescription>
            <Text>
                A new run was launched when this run was retried.
            </Text>
          </AlertDescription>
        </Box>
      </Alert>
    </>
  );

  const terminatedOrErrorButtons = () => (
    <Button
      onClick={onRetryClick}
      colorScheme='blue'
      leftIcon={<Icon as={BsArrowRepeat} />}
      isLoading={isRetryLoading}
      isDisabled={isRetryLoading}
    >
        Retry from savepoint
    </Button>
  );

  const completeButtons = (
    <Button
      variant='outline'
      leftIcon={<Icon as={BsFolder} />}
      colorScheme='blue'
      onClick={scrollToData}
    >
        Run data
    </Button>
  );

  const terminateButton = (
    <Button
      colorScheme='red'
      leftIcon={<Icon as={BsXOctagon} />}
      gap='none'
      onClick={onTerminateClick}
    >
        Terminate run
    </Button>
  );

  const viewResumedButton = (
    <Button
      as={RouterLink}
      colorScheme='blue'
      leftIcon={<Icon as={BsWater} />}
      gap='none'
      to={`/dashboard/${resumedAs}?runType=1`}
    >
        View resumed run
    </Button>
  );

  let alerts = <></>;
  let buttons = <></>;

  if (['terminated', 'failed'].includes(status)) {
    alerts = terminatedOrErrorAlert;
    buttons = terminatedOrErrorButtons(); // Implemented as a function to use isRetryLoading state
  } else if (status === 'complete') {
    buttons = completeButtons;
  } else if (['awaiting_execution', 'beginning_execution', 'executing', 'transferring'].includes(status)) {
    buttons = terminateButton;
  }

  // Override alerts if resumed
  if (resumedAs) {
    alerts = resumedAlert;
    buttons = viewResumedButton;
  }

  return (
    <>
      {alerts}
      <Flex
        id='button-container'
        justify='flex-end'
        align='center'
        gap='0.625rem'
        alignSelf='stretch'
      >
        {buttons}
      </Flex>
    </>
  );
};

const RunExecutionSection = ({
  runDetail, onTerminateClick, onRetryClick, isSubtask, scrollToData, isRetryLoading,
}: any) => (isSubtask ? <></> : (
  <SectionBox
    header='Run execution'
    bodyPaddingTop='0rem'
    marginBottom='0rem'
  >
    <Flex
      id='execution-details'
      padding='1.5625rem'
      direction='column'
      justify='center'
      align='center'
      gap='1.875rem'
    >
      <TopInfo
        id={runDetail.id}
        status={runDetail.status}
        errorMessage={runDetail.error_message}
      />
      <Box bg='gray.300' width='100%' height='1px'/>
      <BottomButtons
        status={runDetail.status}
        resumedAs={runDetail.resumed_as}
        onTerminateClick={onTerminateClick}
        onRetryClick={onRetryClick}
        scrollToData={scrollToData}
        isRetryLoading={isRetryLoading}
      />
    </Flex>
  </SectionBox>
));

export default RunExecutionSection;
