import * as React from 'react';
import { InfoOutlineIcon } from '@chakra-ui/icons';
import {
  Box,
  Text,
  Flex,
  Heading,
  SimpleGrid,
} from '@chakra-ui/react';
import { useQuery } from '@tanstack/react-query';
import { withRequiredAuthInfo } from '@propelauth/react';
import {
  VictoryArea, VictoryAxis, VictoryChart, VictoryScatter,
} from 'victory';
import _ from 'lodash';

import { themeConfig } from '../../theme';

function formatUpdatedDate(date: Date) {
  return new Date(date).toLocaleTimeString(
    'en-US',
    { hour: '2-digit', minute: '2-digit', timeZoneName: 'short' },
  );
}

const ActivitySegment = ({ metric, header, unit }: any) => {
  const historyLength = metric.historicalUsage.length;
  const history = historyLength > 0
    ? _.map(
      metric.historicalUsage,
      (usage, index) => ({ x: -parseInt(index, 10), y: usage }),
    )
    : [{ x: 0, y: 0 }, { x: 1, y: 0 }];
  const updatedLabel = metric.lastUpdated
    ? `Updated ${formatUpdatedDate(metric.lastUpdated)}`
    : 'Waiting for data...';

  const offWhite = themeConfig.colors.greys['800'];
  return (
    <Flex direction='column' justifyContent='left'>
      <Heading as='h3' size='md' color='gray.700' mb={1}>
        {header}
      </Heading>
      <Heading as='h4' size='sm' color='gray.700'>
        In use: {Math.round(metric.used * 10) / 10}  {unit} (of {Math.round(metric.reserved * 10) / 10})
      </Heading>
      <Text color='gray.400' variant='monospace'>{updatedLabel}</Text>
      <Flex direction='row' justifyContent='left' alignItems='left' height='100%'>
        <VictoryChart
          title='Historical usage'
          minDomain={{ x: -15, y: 0 }}
          maxDomain={{ x: 0, y: metric.reserved }}
          padding={{
            top: 20, bottom: 50, right: 80, left: 0,
          }}
        >
          <VictoryAxis
            style={{
              tickLabels: { fontFamily: "'Roboto Mono Variable', sans-serif", fontSize: 14, fill: offWhite },
            }}
            tickFormat={(tick) => `${tick} ${unit}`}
            fixLabelOverlap={true}
            dependentAxis
          />
          <VictoryAxis
            style={{
              tickLabels: {
                angle: -30, fontFamily: "'Roboto Mono Variable', sans-serif", fontSize: 14, fill: offWhite,
              },
            }}
            tickFormat={(tick) => `${Math.abs(tick)} min`}
            fixLabelOverlap={true}
          />
          <VictoryArea
            style={{
              data: { fill: themeConfig.colors.blues['50'], stroke: themeConfig.colors.blues['400'], strokeWidth: 8 },
            }}
            data={history}
            interpolation='natural'
          />
          <VictoryScatter
            style={{
              data: {
                fill: '#ffffff',
                stroke: themeConfig.colors.blues['400'],
                fillOpacity: 0.8,
                strokeWidth: 2,
              },
            }}
            data={history}
            padding={50}
          />
        </VictoryChart>
      </Flex>
    </Flex>
  );
};

const ActivityMonitor = ({
  isDisabled, taskId, isParent, accessToken, orgHelper,
}: any) => {
  const orgId = orgHelper.getOrgIds()[0];
  const { data: liveMetrics, isSuccess } = useQuery(
    [`activity${taskId}`, orgId],
    () => fetch(`${process.env.REACT_APP_API_URL}/app/${orgId}/runs/tes-tasks/${taskId}/activity`, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`,
      },
    }).then((response) => {
      if (response.ok) {
        return response.json();
      }
      throw new Error('Failed to fetch activity metrics');
    }),
    {
      enabled: !isDisabled,
      refetchOnMount: true,
      refetchOnWindowFocus: false,
      refetchInterval: () => 5 * 1000,
    },
  );

  if (isDisabled) {
    return <Box />;
  }
  const defaultMetrics = {
    cpus: {
      historicalUsage: [],
      lastUpdated: null,
      reserved: 1,
      used: 0,
    },
    memory: {
      historicalUsage: [],
      lastUpdated: null,
      reserved: 1,
      used: 0,
    },
  };
  const metrics = isSuccess
    ? liveMetrics
    : defaultMetrics;

  const isIdling = liveMetrics && liveMetrics.cpus.lastUpdated && liveMetrics.cpus.used < 1;
  return (
    <Flex direction='column'>
      {isParent && isIdling && (
        <Flex direction='row' alignItems='center' mb={4} ml={4}>
          <InfoOutlineIcon color='greys.500'/>
          <Text variant='secondary' ml='1ch'>
            Idling on this instance is normal for parallelized FlowDeploy runs.
            View the details of an individual task to see its resource requirements and active usage.
          </Text>
        </Flex>
      )}
      <SimpleGrid minChildWidth='10em' spacingX='4em' spacingY='2em' width='100%' pl={4} pr={4} bg='white'>
        <Box flexDirection='column' p={6} bg="white">
          <ActivitySegment
            metric={metrics.cpus}
            header='CPU'
            unit='vCPU'
          />
        </Box>
        <Box flexDirection='column' p={6} bg="white">
          <ActivitySegment
            metric={metrics.memory}
            header='Memory'
            unit='GB'
          />
        </Box>
      </SimpleGrid>
    </Flex>
  );
};

export default withRequiredAuthInfo(ActivityMonitor);
