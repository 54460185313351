import { useSearchParams } from 'react-router-dom';
import { assign } from 'lodash';

export default function useSearchParamsState(
  searchParamName: string,
  defaultValue: string,
): readonly [
  searchParamsState: string,
  setSearchParamsState: (newState: string) => void
] {
  const [searchParams, setSearchParams] = useSearchParams();

  const acquiredSearchParam = searchParams.get(searchParamName);
  const searchParamsState = acquiredSearchParam ?? defaultValue;

  const setSearchParamsState = (newState: string) => {
    const next = assign(
      {},
      ...Array.from(searchParams.entries()).map(([key, value]) => ({ [key]: value })),
      { [searchParamName]: newState },
    );
    setSearchParams(next);
  };
  return [searchParamsState, setSearchParamsState];
}
