import * as React from 'react';
import { useQuery } from '@tanstack/react-query';
import { RedirectToLogin, withRequiredAuthInfo } from '@propelauth/react';
import {
  Box,
  Button,
  Flex,
  Grid,
  Heading,
  Select,
  Spacer,
  Spinner,
  Text,
  useDisclosure,
  useToast,
} from '@chakra-ui/react';
import { ViewOffIcon } from '@chakra-ui/icons';
import { useNavigate, useParams } from 'react-router-dom';

import { useContext } from 'react';
import CopyableBadge from '../CopyableBadge';
import RunsSummaryTable from '../runs/RunsSummaryTable';
import SectionBox from '../SectionBox';
import { ReactComponent as LaunchIcon } from '../images/launch_glyph.svg';
import { themeConfig } from '../theme';
import PipelineHideModal from './PipelineHideModal';
import { UserContext } from '../UserContext';

const PipelineDetailsPage = ({ accessToken, orgHelper }: any) => {
  const { id } = useParams();
  const orgId = orgHelper.getOrgIds()[0];
  const navigate = useNavigate();
  const toast = useToast();
  const { isOpen: isPipelineHideOpen, onOpen: onPipelineHideOpen, onClose: onPipelineHideClose } = useDisclosure();
  const { orgData } = useContext(UserContext);
  const useSingleInstanceLaunch = orgData.is_single_instance;

  const {
    data, isLoading, isSuccess, refetch,
  } = useQuery(
    [`pipeline${id}`, orgId],
    async () => {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/app/${orgId}/pipelines/${id}`, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${accessToken}`,
        },
      });
      if (response.ok) {
        return response.json();
      }
      throw new Error('Network response was not ok');
    },
    {
      refetchOnMount: true,
      refetchOnWindowFocus: false,
      refetchInterval: () => 60 * 1000,
    },
  );

  const { refetch: switchWfManager } = useQuery(
    [`pipeline${id}`, orgId, data?.pipeline.wf_manager ?? ''],
    async () => {
      const newWfManager = data.pipeline.wf_manager === 'nextflow' ? 'snakemake' : 'nextflow';
      const response = await fetch(`${process.env.REACT_APP_API_URL}/app/${orgId}/pipelines/${id}/switch-wf`, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${accessToken}`,
        },
        body: JSON.stringify({
          wf_manager: newWfManager,
        }),
        method: 'PUT',
      });
      if (response.ok) {
        toast({
          title: 'Switched workflow manager',
          status: 'success',
          duration: 7_000,
          isClosable: true,
        });
      } else {
        toast({
          title: 'Failed to switch workflow manager',
          status: 'error',
          duration: 7_000,
          isClosable: true,
        });
      }
      return refetch();
    },
    {
      enabled: false,
      refetchOnMount: false,
      refetchOnWindowFocus: false,
    },
  );

  const lastUsedMessage = data?.pipeline?.last_used_at
    ? new Date(data.pipeline.last_used_at).toLocaleString('en-US')
    : 'Never used';
  return (
    <Box>
      <Flex direction="column">
        {isLoading || !isSuccess || !id
          ? <Flex alignItems="center" justifyContent="center"><Spinner mt={150}/></Flex>
          : (
            <Box width="100%">
              <Flex direction="row" align="center">
                <Heading size='xl' mr={6}>
                  Pipeline detail
                </Heading>
                <Spacer/>
                <Button
                  onClick={onPipelineHideOpen}
                  colorScheme="blue"
                  m={3}
                  mr={0}
                  leftIcon={<ViewOffIcon/>}
                  variant='outline'
                >
                  Hide pipeline
                </Button>
                <PipelineHideModal
                  isOpen={isPipelineHideOpen}
                  onClose={onPipelineHideClose}
                  refetchPipelines={refetch}
                  pipelineId={id}
                  name={data.pipeline.name}
                  lastUsedAt={lastUsedMessage}
                />
                {!useSingleInstanceLaunch && <Button
                  onClick={() => navigate(
                    {
                      pathname: '/pipelines/launch',
                    },
                    {
                      state: { pipeline: data.pipeline },
                    },
                  )}
                  colorScheme="blue"
                  m={3}
                  leftIcon={<LaunchIcon width='1ch' fill={themeConfig.colors.blues['50']}/>}
                >
                  Launch this pipeline
                </Button>}
              </Flex>
              <SectionBox header={data.pipeline.name} isFormatted>
                <Flex
                  direction="column"
                  bg={'white'}
                >
                  <Flex direction="row" width="100%">
                    <Flex direction="column" flex="2" pl={5} pr={5} mt={6} mb={6}>
                      <Grid templateRows='auto' gap={5}>
                        <Flex align="center" height="9">
                          <Text variant="secondary">Pipeline name</Text>
                        </Flex>
                        <Flex align="center" height="9">
                          <Text variant="secondary">Created at</Text>
                        </Flex>
                      </Grid>
                    </Flex>
                    <Flex direction="column" flex="4" pl={5} pr={5} mt={6} mb={6}>
                      <Grid templateRows='auto' gap={5}>
                        <Flex pb="9" position='relative'>
                          <CopyableBadge
                            expanding={true}
                            label={data.pipeline.name.length > 19
                              ? `...${data.pipeline.name.slice(-16, data.pipeline.name.length)}` : data.pipeline.name}
                            value={data.pipeline.name}
                          />
                        </Flex>
                        <Flex align="center" height="9">
                          <Text>
                            {new Date(data.pipeline.created_at).toLocaleString('en-US') || 'N/A'}
                          </Text>
                        </Flex>
                      </Grid>
                    </Flex>
                    <Flex flexDirection="column" flex="2" pl={5} pr={5} mt={6}>
                      <Grid templateRows='auto' gap={5}>
                        <Flex align="center" height="9">
                          <Text variant="secondary">Workflow manager</Text>
                        </Flex>
                        <Flex align="center" height="9">
                          <Text variant="secondary">Last used</Text>
                        </Flex>
                      </Grid>
                    </Flex>
                    <Flex direction="column" flex="4" pl={5} pr={5} mt={6}>
                      <Grid templateRows='auto' gap={5}>
                        <Flex align="center" height="9">
                          <Select value={data.pipeline?.wf_manager ?? ''}
                            onChange={() => switchWfManager()}>
                            <option value='nextflow'>Nextflow</option>
                            <option value='snakemake'>Snakemake</option>
                          </Select>
                        </Flex>
                        <Flex align="center" height="9">
                          <Text>
                            {lastUsedMessage}
                          </Text>
                        </Flex>
                      </Grid>
                    </Flex>
                  </Flex>
                </Flex>
              </SectionBox>
              {data.pipeline.description?.length > 0
                && <SectionBox header='Description' isFormatted>
                  <Text pt={5}>{data.pipeline.description}</Text>
                </SectionBox>
              }
              <SectionBox header='Pipeline runs' bodyPaddingTop='0rem'>
                <RunsSummaryTable
                  runs={data.runs}
                  refetch={refetch}
                  noMargin={false}
                  skipFiltering={false}
                />
              </SectionBox>
            </Box>
          )
        }
      </Flex>
    </Box>

  );
};

export default withRequiredAuthInfo(PipelineDetailsPage, {
  displayIfLoggedOut: <RedirectToLogin/>,
});
