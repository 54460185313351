import * as React from 'react';
import {
  Alert,
  AlertIcon,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
} from '@chakra-ui/react';
import { withRequiredAuthInfo } from '@propelauth/react';

const DisableKeyConfirmationModal = ({
  isOpen,
  onClose,
  onSubmit,
  isLoading,
}: any) => (
  <Modal isOpen={isOpen} onClose={onClose} >
    <ModalOverlay bg='blackAlpha.300' backdropFilter='blur(5px)'/>
    <ModalContent>
      <ModalHeader>Are you sure you want to disable this key?</ModalHeader>
      <ModalCloseButton/>
      <ModalBody>
        <Alert status='warning'>
          <AlertIcon/>
                        The only way to re-enable disabled keys is by contacting FlowDeploy. Make sure you
                        want to disable this key!
        </Alert>
      </ModalBody>
      <ModalFooter>
        <Button
          colorScheme='blue'
          onClick={onSubmit}
          isLoading={isLoading}
          loadingText="Disabling key..."
        >
                        Disable
        </Button>
      </ModalFooter>
    </ModalContent>
  </Modal>
);

export default withRequiredAuthInfo(DisableKeyConfirmationModal);
