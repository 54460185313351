import * as React from 'react';
import { Field, Formik } from 'formik';
import {
  Button,
  Input,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton, FormControl, FormLabel, FormErrorMessage,
} from '@chakra-ui/react';
import { withRequiredAuthInfo } from '@propelauth/react';

const FunctionCreateModal = ({
  isOpen,
  onClose,
  onSubmit,
  isLoading,
  serverErrors,
}: any) => (
  <Modal isOpen={isOpen} onClose={onClose} >
    <ModalOverlay bg='blackAlpha.300' backdropFilter='blur(5px)'/>
    <ModalContent>
      <ModalHeader>Create new universal function</ModalHeader>
      <ModalCloseButton/>
      <Formik
        initialValues={{
          function_name: null,
        }}
        onSubmit={async (values) => {
          await onSubmit(values.function_name);
        }}
        initialErrors={serverErrors}
        enableReinitialize
      >
        {({
          handleSubmit, errors, touched, isValid,
        }: any) => (
          <form onSubmit={handleSubmit}>
            <ModalBody>
              <FormControl
                isInvalid={
                  (!!serverErrors && !!errors.function_name)
                  || (touched.function_name && !!errors.function_name)
                }
              >
                <FormLabel htmlFor="function_name">Universal function name</FormLabel>
                <Field
                  as={Input}
                  id="function_name"
                  name="function_name"
                  type="text"
                  validate={(value: string) => {
                    if (!value) {
                      return 'Name must not be empty';
                    }
                    if (value.length < 1) {
                      return 'Name must contain at least one character';
                    } if (value.length > 255) {
                      return 'Maximum name length is 255 characters';
                    } if (/^\s*$/.test(value)) {
                      return 'Name must contain at least one non-whitespace character';
                    } if (/^\s|\s$/.test(value)) {
                      return 'Name must not start or end with whitespace';
                    }
                    return '';
                  }}
                />
                <FormErrorMessage>{errors.function_name}</FormErrorMessage>
              </FormControl>
            </ModalBody>
            <ModalFooter>
              <Button
                type="submit"
                colorScheme='blue'
                isDisabled={!touched || !isValid}
                isLoading={isLoading}
                loadingText="Creating..."
              >
                Create
              </Button>
            </ModalFooter>
          </form>
        )}
      </Formik>
    </ModalContent>
  </Modal>
);

export default withRequiredAuthInfo(FunctionCreateModal);
