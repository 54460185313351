import {
  Box, Button, Checkbox, Flex, Heading, Text, useDisclosure, useToast,
} from '@chakra-ui/react';
import * as React from 'react';
import { useEffect } from 'react';
import { useQuery } from '@tanstack/react-query';
import { createColumnHelper } from '@tanstack/react-table';
import { RedirectToLogin, withRequiredAuthInfo } from '@propelauth/react';
import { InfoOutlineIcon, LinkIcon } from '@chakra-ui/icons';
import { get } from 'lodash';
import Confetti from 'react-confetti';
import { useSearchParams } from 'react-router-dom';
import { PostHogFeature } from 'posthog-js/react';

import { DataTable } from '../DataTable';
import KeysTableHeader from './KeysTableHeader';
import { StatusBadge, StatusBadges } from '../StatusBadge';
import SectionBox from '../SectionBox';
import SetupScriptModal from '../pipelines/SetupScriptModal';
import GitHubConnectionSection from '../pipelines/GitHubConnectionSection';
import NotificationSettingsSection from '../pipelines/NotificationSettingsSection';
import ComputeEnvironmentsSection from './ComputeEnvironmentsSection';
import { UserContext } from '../UserContext';

const DeveloperSettingsPage = ({ accessToken, orgHelper, user }: any) => {
  const orgId = orgHelper.getOrgIds()[0];
  const [tabIndex, setTabIndex] = React.useState(0);
  const { isOpen: isSetupOpen, onOpen: onSetupOpen, onClose: onSetupClose } = useDisclosure();
  const [searchParams] = useSearchParams();
  const isInitialGitHubConnection = Array.from(searchParams.keys()).includes('connected');
  const connected = searchParams.get('connected') === 'true';
  const toast = useToast();
  const isDevelopModeEnabled = get(user, 'properties.metadata.isDevelopModeEnabled', false);
  const context = React.useContext(UserContext);
  const mountName = context.orgData?.remote_mount_name;
  const useSingleInstance = context?.orgData.is_single_instance;

  useEffect(() => {
    if (isInitialGitHubConnection) {
      if (connected) {
        toast({
          title: 'Connected to GitHub',
          status: 'success',
          duration: 5000,
          isClosable: true,
        });
      } else {
        toast({
          title: 'Failed to connect to GitHub',
          status: 'error',
          duration: 7000,
          isClosable: true,
        });
      }
    }
  }, [isInitialGitHubConnection, connected, toast]);

  const { data: setupScript, isFetching: isSetupScriptLoading, refetch: fetchSetupScript } = useQuery(
    ['setupScript', orgId],
    () => fetch(`${process.env.REACT_APP_API_URL}/app/${orgId}/setup-script`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`,
      },
    }).then(async (response) => {
      if (!response.ok) {
        const body = await response.json();
        throw new Error(body.error || 'Error connecting to server');
      }
      return response.json();
    }),
    {
      enabled: false,
    },
  );

  const {
    data: apiKeys, isLoading, isSuccess, refetch: refetchKeys,
  } = useQuery(
    ['apiKeys', orgId],
    () => fetch(`${process.env.REACT_APP_API_URL}/app/${orgId}/api-keys`, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`,
      },
    }).then((response) => {
      if (response.ok) {
        return response.json();
      }
      throw new Error('Network response was not ok');
    }),
    {
      refetchOnMount: false,
      refetchOnWindowFocus: false,
    },
  );

    type ApiKey = {
        key_prefix: string;
        customer_label: string;
        disabled: boolean;
        last_used_at: Date;
    };

    const data: ApiKey[] = isSuccess
      && apiKeys.filter((key: ApiKey) => (tabIndex === 0 ? !key.disabled : key.disabled));

    const columnHelper = createColumnHelper<ApiKey>();

    const columns = [
      {
        id: 'select',
        header: '',
        cell: ({ row }: any) => (
          <div className="px-1">
            <Checkbox
              as="div"
              isChecked={row.getIsSelected()}
            />
          </div>
        ),
      },
      columnHelper.accessor('key_prefix', {
        cell: (info) => info.getValue(),
        header: 'Access Key',
      }),
      columnHelper.accessor('customer_label', {
        cell: (info) => info.getValue(),
        header: 'Description',
      }),
      columnHelper.accessor('disabled', {
        cell: (info) => {
          const active = !info.getValue();
          return (
            <StatusBadge
              label={active ? 'Active' : 'Disabled'}
              badgeType={active ? StatusBadges.Active : StatusBadges.Disabled}
            />
          );
        },
        header: 'Status',
      }),
      columnHelper.accessor('last_used_at', {
        cell: (info) => (info.getValue()
          ? new Date(info.getValue()).toLocaleDateString('en-US')
          : 'Never used'),
        header: 'Last used',
        meta: {
          isNumeric: false,
        },
      }),
    ];

    return isLoading ? <div>Loading...</div> : (
      <Box flex='4'>
        <Heading as='h2' size='xl' mb={2}>
          Developer settings
        </Heading>
        <Confetti
          width={window.innerWidth}
          height={window.innerHeight}
          recycle={false}
          run={searchParams.get('connected') === 'true'}
        />
        <GitHubConnectionSection />
        { !!mountName && !useSingleInstance && <ComputeEnvironmentsSection /> }
        <NotificationSettingsSection />
        <SectionBox header='Keys' bodyPaddingTop='0rem'>
          <DataTable
            data={data}
            columns={columns}
            filter={setTabIndex}
            refetch={refetchKeys}
            TableHeader={KeysTableHeader}
            defaultSortId='last_used_at'
            idColumn='key_prefix'
            noMargin
          />
        </SectionBox>
        <SectionBox header='Develop mode' isFormatted>
          <Flex alignItems='center'>
            <InfoOutlineIcon />
            <Text ml='1ch'>
              {isDevelopModeEnabled
                ? 'Only connect trusted development devices.'
                : 'Before connecting, preconfigure your device with the FlowDeploy team.'
              }
            </Text>
          </Flex>
          <Button
            onClick={async () => {
              const response = await fetchSetupScript();
              if (response.isSuccess) {
                onSetupOpen();
              } else {
                toast({
                  title: 'Failed to connect device to FlowDeploy',
                  description: `${response.failureReason}`,
                  status: 'error',
                  duration: 15_000,
                  isClosable: true,
                });
              }
            }}
            isLoading={isSetupScriptLoading}
            colorScheme="blue"
            loadingText="Generating connect script"
            mt='1ch'
            leftIcon={<LinkIcon />}
            width='20rem'
            isDisabled={isSetupScriptLoading || !isDevelopModeEnabled}
          >
            Connect development device
          </Button>
          <SetupScriptModal
            isOpen={isSetupOpen}
            onClose={onSetupClose}
            setupScript={setupScript}
          />
        </SectionBox>
        <PostHogFeature flag='feat_feature-flags' match={true}>
          <SectionBox header='Feature flags' isFormatted>
            <Text mt={6}>Enabled!</Text>
          </SectionBox>
        </PostHogFeature>

        <br />
        <br />
      </Box>
    );
};

export default withRequiredAuthInfo(DeveloperSettingsPage, {
  displayIfLoggedOut: <RedirectToLogin />,
});
