import { withRequiredAuthInfo } from '@propelauth/react';
import * as React from 'react';
import { useQuery } from '@tanstack/react-query';
import {
  Checkbox, Flex, Progress,
} from '@chakra-ui/react';
import { createColumnHelper } from '@tanstack/react-table';
import { useState } from 'react';
import NewFilesTable from '../../../data/NewFilesTable/NewFilesTable';
import withInitialProps from '../../../data/inputOutputSelection/withInitalProps';
import NewFilesTableHeader, { Breadcrumb, BreadcrumbConfig } from '../../../data/NewFilesTable/NewFilesTableHeader';
import TableEmpty from '../../../data/NewFilesTable/TableEmpty';
import { ReactComponent as EmptyDesert } from '../../../images/empty_desert.svg';
import { Actions, DirectoryEntry } from '../../../data/NewFilesTable/ColumnComponents';
import { GenericFile } from '../../../data/types';
import formatBytes from '../../../utils';

async function fetchRegisteredDirectory({
  url, subdirectory, orgId, accessToken,
}) {
  return fetch(`${process.env.REACT_APP_API_URL}/app/${orgId}/files/registered?url=${url}&sub_dir=${subdirectory}`, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${accessToken}`,
    },
  }).then(async (response) => {
    if (response.ok) {
      return response.json();
    }
    throw new Error('Network response was not ok');
  });
}

export type EmptyStateConfig = {
  heading: string,
  body: string,
}

type RegisteredFileSectionProps = {
  files: Array<GenericFile> & any,
  emptyStateConfig: EmptyStateConfig,
  openRunDrive: any,
  rootFileLabel: string,
  accessToken: any,
  orgHelper: any,
}

const RegisteredFileSection = ({
  files, emptyStateConfig, rootFileLabel, accessToken, orgHelper,
}: RegisteredFileSectionProps) => {
  const orgId = orgHelper.getOrgIds()[0];

  // The current registered directory
  const [registeredDirectoryUrl, setRegisteredDirectoryUrl] = useState('');
  const [subdirectory, setSubdirectory] = useState('');
  const [breadcrumbs, setBreadcrumbs] = useState([{
    displayName: rootFileLabel || 'All',
    to: '',
    subdirectory: '',
  }]);

  const {
    data: outputFiles,
    refetch: refetchOutputFiles,
  } = useQuery(
    ['outputFiles', registeredDirectoryUrl, subdirectory],
    () => fetchRegisteredDirectory({
      url: registeredDirectoryUrl,
      subdirectory,
      orgId,
      accessToken,
    }),
    {
      placeholderData: () => ([
        {
          name: <Progress size='xs' isIndeterminate />,
          last_modified: 0,
          is_dir: true,
          size: null,
          not_selectable: true,
        },
      ]),
      staleTime: 60 * 1000,
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      enabled: !!registeredDirectoryUrl,
    },
  );

  const onRowClick = (row: GenericFile) => {
    if (row.is_dir) {
      let subdirectoryFromRegisteredUrl = '';
      // This assumes the first clickable row is a registered directory ...
      if (row.is_registered_file) {
        setRegisteredDirectoryUrl(row.url);
      } else {
        // ... and the remainder is the subdirectory
        subdirectoryFromRegisteredUrl = `${row.url.split(registeredDirectoryUrl)[1]}`;
        setSubdirectory(subdirectoryFromRegisteredUrl);
      }
      setBreadcrumbs([
        ...breadcrumbs,
        {
          displayName: row.name || row.url,
          to: row.url,
          subdirectory: subdirectoryFromRegisteredUrl,
        },
      ]);
      refetchOutputFiles().then();
    }
  };

  const columnHelper = createColumnHelper<GenericFile>();
  const columns = [
    {
      id: 'select',
      header: '',
      cell: ({ row }: any) => (
        <Flex width='100%' align='center' justify='left'>
          <Checkbox
            size='lg'
            isChecked={row.getIsSelected()}
          />
        </Flex>
      ),
    },
    columnHelper.accessor('name', {
      cell: (info) => <DirectoryEntry info={info} />,
      header: () => 'name',
    }),
    columnHelper.accessor('label', {
      cell: (info) => info.getValue(),
      header: 'Label',
    }),
    columnHelper.accessor('size', {
      cell: (info) => formatBytes(info.getValue()),
      header: 'Size',
    }),
    {
      id: 'actions',
      header: '',
      size: 10,
      cell: ({ row }: any) => (
        <Actions
          enableDownload
          file={row.original}
        />
      ),
    },
  ];

  const breadcrumbConfig: BreadcrumbConfig = {
    path: registeredDirectoryUrl,
    setPath: (breadcrumb: Breadcrumb) => {
      setRegisteredDirectoryUrl(breadcrumb.to);
      setSubdirectory(breadcrumb.subdirectory);
    },
    breadcrumbs,
    setBreadcrumbs,
  };

  return (
    <>
      <NewFilesTable
        hide={files?.length === 0}
        idColumn='url'
        columns={columns}
        files={registeredDirectoryUrl === '' ? (files || []) : outputFiles}
        TableHeader={withInitialProps({
          enableDownload: true,
          enableEditHeader: true,
          breadcrumbConfig,
        })(NewFilesTableHeader)}
        refetch={refetchOutputFiles}
        onRowClick={onRowClick}
        enableMultiRowSelection
        isSelecting
      />
      <TableEmpty
        hide={files?.length !== 0}
        image={EmptyDesert}
        headingText={emptyStateConfig.heading}
        bodyText={emptyStateConfig.body}
      />
    </>
  );
};

export default withRequiredAuthInfo(RegisteredFileSection);
