import * as React from 'react';
import {
  Box, Flex, Heading, Link, Text,
} from '@chakra-ui/react';
import { Link as RouterLink } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import { RedirectToLogin, withRequiredAuthInfo } from '@propelauth/react';

import RunsSummaryTable from './runs/RunsSummaryTable';
import SectionBox from './SectionBox';
import useSearchParamsState from './searchParamsSatate/useSearchParamsState';
import PipelinesTable from './pipelines/PipelinesTable';
import EmptyPipelinesTableMessage from './pipelines/EmptyPipelinesTableMessage';

const DashboardPage = ({ accessToken, orgHelper }: any) => {
  const orgId = orgHelper.getOrgIds()[0];
  const [activeIndex, setActiveIndex] = useSearchParamsState('activeTab', '0');

  const {
    data: runs, isLoading, isSuccess, refetch: refetchRuns,
  } = useQuery(
    ['runs', orgId],
    async () => {
      if (!orgId) {
        await new Promise((r) => {
          setTimeout(r, 500);
        });
        window.location.reload();
      }
      return fetch(`${process.env.REACT_APP_API_URL}/app/${orgId}/runs`, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${accessToken}`,
        },
      }).then((response) => {
        if (response.ok) {
          return response.json();
        }
        throw new Error('Network response was not ok');
      });
    },
    {
      refetchOnMount: true,
      refetchOnWindowFocus: true,
      refetchInterval: () => 60 * 1000,
    },
  );

  const emptyMessage = activeIndex === '0' ? (
    <Flex>
      There are no active runs right now.
      <Link as={RouterLink} to='/pipelines/launch' ml='0.5ch'>Launch a pipeline</Link>
      , or
      <Link onClick={() => setActiveIndex('1')} ml='0.5ch'> view all runs</Link>.
    </Flex>
  ) : (
    <Flex>
      There are no finished runs yet. Create one by
      <Link as={RouterLink} to='/pipelines/launch' ml='0.5ch'>launching a pipeline.</Link>
    </Flex>
  );

  if (!orgId) {
    return (
      <Box flex='4'>
        <Heading as='h2' size='xl' mb={2}>
          Dashboard
        </Heading>
        <Text>Setting up your account.</Text>
      </Box>
    );
  }

  return isLoading || !isSuccess ? <div>Loading...</div> : (
    <Box flex='4'>
      <Heading as='h2' size='xl' mb={2}>
        Dashboard
      </Heading>
      <SectionBox header='Runs' bodyPaddingTop='0rem'>
        <RunsSummaryTable
          runs={runs}
          refetch={refetchRuns}
          noMargin={false}
          emptyMessage={emptyMessage}
          key={activeIndex}
        />
      </SectionBox>
      <SectionBox header='Your pipelines' bodyPaddingTop='0rem'>
        <PipelinesTable
          key={'pipelinesTable'}
          noMargin
          emptyMessage={<EmptyPipelinesTableMessage />}
        />
      </SectionBox>
      <br />
      <br />
    </Box>
  );
};

export default withRequiredAuthInfo(DashboardPage, {
  displayIfLoggedOut: <RedirectToLogin />,
});
