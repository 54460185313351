import {
  Box, Flex, Heading, Text,
} from '@chakra-ui/react';
import { ArrowBackIcon } from '@chakra-ui/icons';
import * as React from 'react';
import { useNavigate, Link as RouterLink } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import { createColumnHelper } from '@tanstack/react-table';
import { RedirectToLogin, withRequiredAuthInfo } from '@propelauth/react';

import CopyableBadge from '../CopyableBadge';
import { DataTable } from '../DataTable';
import UniversalsPageHeader from './UniversalsPageHeader';
import SectionBox from '../SectionBox';
import GitHubConnectionSection from './GitHubConnectionSection';
import NotificationSettingsSection from './NotificationSettingsSection';

const PipelinesPage = ({ accessToken, orgHelper }: any) => {
  const orgId = orgHelper.getOrgIds()[0];
  const navigate = useNavigate();

  const {
    data: functions, isLoading, isSuccess, refetch: refetchFunctions,
  } = useQuery(
    ['universalFunctions', orgId],
    () => fetch(`${process.env.REACT_APP_API_URL}/app/${orgId}/universal-functions`, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`,
      },
    }).then((response) => {
      if (response.ok) {
        return response.json();
      }
      throw new Error('Network response was not ok');
    }),
    {
      refetchOnMount: false,
      refetchOnWindowFocus: false,
    },
  );

  type UniversalFunction = {
    id: string;
    function_name: string;
    volume_name: string;
    last_used_at: Date;
  };

  const data: UniversalFunction[] = isSuccess && functions;

  const columnHelper = createColumnHelper<UniversalFunction>();

  const columns = [
    columnHelper.accessor('function_name', {
      cell: (info) => {
        const name = info.getValue();
        return (
          <CopyableBadge
            label={`${name}`}
            value={name}
          />
        );
      },
      header: 'Name',
    }),
    columnHelper.accessor('last_used_at', {
      cell: (info) => (info.getValue()
        ? new Date(info.getValue()).toLocaleDateString('en-US')
        : 'Never used'),
      header: 'Last used',
      meta: {
        isNumeric: false,
      },
    }),
  ];

  const onRowClick = (row: UniversalFunction) => {
    navigate(`./${row.id}`, { state: { runSummary: row } });
  };

  return isLoading ? <div>Loading...</div> : (
    <Box flex='4'>
      <Flex direction="row" align="center">
        <ArrowBackIcon mr={2} color="#667085"/>
        <Text as={RouterLink} to='..' variant="secondary">
          Back to pipelines
        </Text>
      </Flex>
      <Flex mt={4}>
        <Heading as='h2' size='xl'>
          Pipeline settings
        </Heading>
      </Flex>
      <GitHubConnectionSection orgId={orgId} accessToken={accessToken}/>
      <NotificationSettingsSection orgId={orgId} accessToken={accessToken}/>
      <SectionBox header='Flow configurations'>
        <Box bg='translucent.200' ml={5} mr={5} height='1px'/>
        <DataTable
          data={data}
          columns={columns}
          onRowClick={onRowClick}
          refetch={refetchFunctions}
          TableHeader={UniversalsPageHeader}
          defaultSortId='last_used_at'
          idColumn='function_name'
          noMargin
        />
      </SectionBox>
    </Box>
  );
};

export default withRequiredAuthInfo(PipelinesPage, {
  displayIfLoggedOut: <RedirectToLogin/>,
});
