import * as React from 'react';
import { useState } from 'react';
import { Field, Formik } from 'formik';
import {
  Box,
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  HStack,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay, Select,
  Text,
  useToast,
} from '@chakra-ui/react';
import { withRequiredAuthInfo } from '@propelauth/react';
import { useQuery } from '@tanstack/react-query';
import { DrivesContext } from './DriveProvider';

async function connectS3Bucket({
  orgId, bucketName, accessToken,
}) {
  return fetch(`${process.env.REACT_APP_API_URL}/app/${orgId}/data-repos`, {
    method: 'PUT',
    body: JSON.stringify({ bucket_name: bucketName }),
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${accessToken}`,
    },
  }).then(async (response) => {
    const body = await response.json();
    if (response.ok) {
      return body;
    }
    throw new Error(body.error);
  });
}
const DataRepoConnectModal = ({
  isOpen,
  onClose,
  accessToken,
  orgHelper,
}: any) => {
  if (!isOpen) {
    return (<></>);
  }

  const orgId = orgHelper.getOrgIds()[0];
  const [newBucketName, setNewBucketName] = useState('');
  const {
    refetchDataRepos,
    setSelectedFileSystem,
  } = React.useContext(DrivesContext);
  const toast = useToast();

  const { refetch, isFetching } = useQuery(
    ['connectDataRepo'],
    async () => {
      try {
        const connectDataRepoRes = await connectS3Bucket({
          orgId,
          bucketName: newBucketName,
          accessToken,
        });
        if (connectDataRepoRes.success) {
          toast({
            title: 'Successfully connected data repository',
            status: 'success',
            duration: 5_000,
            isClosable: true,
          });
          onClose();
          await refetchDataRepos();
          setSelectedFileSystem(`s3://${newBucketName}`);
        } else {
          toast({
            title: 'Failed to connect data repository',
            description: connectDataRepoRes.error,
            status: 'error',
            duration: 7_000,
            isClosable: true,
          });
        }
        return connectDataRepoRes;
      } catch (err) {
        toast({
          title: 'Failed to connect data repo',
          description: err.message,
          status: 'error',
          duration: 7_000,
          isClosable: true,
        });
        return undefined;
      }
    },
    {
      enabled: false,
    },
  );

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay bg='blackAlpha.300' backdropFilter='blur(5px)'/>
      <ModalContent>
        <ModalHeader>Connect data repository</ModalHeader>
        <ModalCloseButton/>
        <Formik
          initialValues={{
            repo_type: null,
            bucket_name: null,
          }}
          onSubmit={async (values) => {
            await setNewBucketName(values.bucket_name);
            await refetch();
          }}
          initialErrors={{}}
          enableReinitialize
        >
          {({
            handleSubmit, errors, touched, isValid,
          }: any) => (
            <form onSubmit={handleSubmit}>
              <ModalBody>
                <FormControl
                  isInvalid={
                    (!!errors.repo_type)
                    || (touched.repo_type && !!errors.repo_type)
                  }
                >
                  <FormLabel htmlFor="repo_type">Data repository type</FormLabel>
                  <Select placeholder='S3 bucket' isDisabled>
                    <option value='s3'>S3 bucket</option>
                    <option value='fd'>FlowDeploy shared file system</option>
                  </Select>
                  <FormErrorMessage>{errors.bucket_name}</FormErrorMessage>
                </FormControl>
                <Box height='0.75em' />
                <FormControl
                  isInvalid={
                    (!!errors.bucket_name)
                    || (touched.bucket_name && !!errors.bucket_name)
                  }
                >
                  <FormLabel htmlFor="bucket_name">S3 bucket</FormLabel>
                  <HStack>
                    <Text>s3://</Text>
                    <Field
                      as={Input}
                      id="bucket_name"
                      name="bucket_name"
                      type="text"
                      validate={(value: string) => {
                        if (!value) {
                          return 'Name must not be empty';
                        }
                        if (value.length < 1) {
                          return 'Name must contain at least one character';
                        }
                        if (value.length > 255) {
                          return 'Maximum name length is 255 characters';
                        }
                        if (/^\s*$/.test(value)) {
                          return 'Name must contain at least one non-whitespace character';
                        }
                        if (/^\s|\s$/.test(value)) {
                          return 'Name must not start or end with whitespace';
                        }
                        if (/^.*\/.*$/.test(value)) {
                          return 'Name must not contain slashes';
                        }
                        return '';
                      }}
                    />
                  </HStack>
                  <FormErrorMessage>{errors.bucket_name}</FormErrorMessage>
                </FormControl>
              </ModalBody>
              <ModalFooter>
                <Button
                  type="submit"
                  colorScheme='blue'
                  isDisabled={!touched || !isValid}
                  isLoading={isFetching}
                  loadingText="Connecting..."
                >
                  Connect
                </Button>
              </ModalFooter>
            </form>
          )}
        </Formik>
      </ModalContent>
    </Modal>
  );
};

export default withRequiredAuthInfo(DataRepoConnectModal);
