import * as React from 'react';
import { useEffect } from 'react';
import {
  Accordion,
  AccordionItem, Alert, AlertDescription, AlertTitle,
  Box,
  Button,
  Flex, Icon,
  Spacer,
  Text,
  useDisclosure,
} from '@chakra-ui/react';
import {
  AddIcon,
} from '@chakra-ui/icons';
import { FieldArray } from 'formik';
import { useQuery } from '@tanstack/react-query';
import { withRequiredAuthInfo } from '@propelauth/react';
import { FaPython } from 'react-icons/fa6';
import { MdVerified } from 'react-icons/md';

import FileSelection from '../../../data/FileSelection';
import SectionBox from '../../../SectionBox';
import { UserContext } from '../../../UserContext';
import PythonCodeModal from './PythonCodeModal';
import DataSection from '../../../data/inputOutputSelection/DataSection';
import InputFormElement from '../InputFormElement';
import VersionInstanceSelection from './VersionInstanceSelection';
import PipelineSettingsSection from './PipelineSettingsSelection';
import { validateExportLocation, validateExportLocationSource, validateOutdir } from './validate';
import { NoFileSystemError, NoVersionsError } from './LaunchErrors';
import fetchPipelineVersions from './fetchPipelineVersions';

const OutputSelection = ({ setFieldValue, required }) => (
  <>
    <FileSelection
      label='Output folder'
      fieldName={'outdir'}
      description='Where to place pipeline outputs'
      setFieldValue={setFieldValue}
      isDirOnly
      isFdOnly
      validate={(value) => validateOutdir(required, value)}
      isRequired={required}
    />
    <Box bg='translucent.200' height='1px' mt='0.75rem' mb='0.75rem'/>
  </>
);

const TemplateAlert = () => (
  <>
    <Alert
      mt='1.875rem'
      status='info'
      flexDirection='row'
      alignItems='center'
      bg='blue.50'
      gap='0.75rem'
      borderRadius='0.625rem'
      id='pipeline-settings-template-alert'
    >
      <Icon as={MdVerified} color='blue.600' boxSize='1.5rem'/>
      <Box>
        <AlertTitle>This template pipeline was validated with these settings</AlertTitle>
        <AlertDescription>
          <Text>
            You can make changes, but the changes are not guaranteed to work.
          </Text>
        </AlertDescription>
      </Box>
      <Spacer />
    </Alert>
  </>
);

const SettingsForm = ({
  values,
  errors,
  setFieldValue,
  setActiveStep,
  isValid,
  isFetching,
  selectedPipeline,
  accessToken,
  orgHelper,
}: any) => {
  const orgId = orgHelper.getOrgIds()[0];
  const { isOpen: isPythonOpen, onOpen: onPythonOpen, onClose: onPythonClose } = useDisclosure();
  const context = React.useContext(UserContext);
  const hasRemoteMountName = !!context.orgData?.remote_mount_name;

  // Single-instance and multi-instance launches are currently exclusive and gated by feature flag
  const useSingleInstanceLaunch = context?.orgData.is_single_instance;

  // todo: integrate with actual field
  const isNextflow = values.wf_manager === 'nextflow';
  const isSnakemake = values.wf_manager === 'snakemake';

  // This query could be moved into VersionInstanceSelection, but keeping it here because of the NoVersionsError
  const { data: versions, isPlaceholderData } = useQuery(
    ['versionsKey', selectedPipeline],
    async () => {
      const pipelineVersions = await fetchPipelineVersions({
        orgId,
        accessToken,
        pipelineName: values.pipeline,
      });

      setFieldValue('imported', null);
      return pipelineVersions;
    },
    {
      placeholderData: () => ({ releases: [], branches: [] }),
      refetchOnMount: true,
      refetchOnWindowFocus: false,
      staleTime: 5 * 60 * 1000,
      retry: 1,
    },
  );
  useEffect(() => {
    if (!values.pipeline_version && versions.branches.length > 0) {
      if (versions.releases.length === 0) {
        setFieldValue('use_git_release', false);
        setFieldValue('pipeline_version', versions.branches[0].name);
      } else {
        setFieldValue('use_git_release', true);
        setFieldValue('pipeline_version', versions.releases[0].name);
      }
    }
  }, [versions, setFieldValue]);

  if (!hasRemoteMountName) {
    return <NoFileSystemError selectedPipeline={selectedPipeline} setActiveStep={setActiveStep} />;
  }
  if (!isPlaceholderData && versions.branches.length === 0 && versions.releases.length === 0) {
    return (
      <NoVersionsError
        selectedPipeline={selectedPipeline}
        setActiveStep={setActiveStep}
        values={values}
        orgId={orgId}
      />
    );
  }
  return (
    <>
      {values.templateId && <TemplateAlert />}
      <VersionInstanceSelection
        setFieldValue={setFieldValue}
        versions={versions}
        isPlaceholderData={isPlaceholderData}
        values={values}
        selectedPipeline={selectedPipeline}
      />
      <SectionBox header='Input data' isFormatted hidden={useSingleInstanceLaunch}>
        <FieldArray
          name="inputs"
          render={(arrayHelpers) => (
            <Accordion allowMultiple defaultIndex={[0]} key={`inputs${errors}`}>
              {values.inputs && values.inputs.length > 0 ? (
                values.inputs.map((input, index) => (
                  <AccordionItem
                    key={`inputs${index}${errors}`}
                  >
                    {({ isExpanded }) => (
                      <>
                        <InputFormElement
                          index={index}
                          key={`inputForm${index}${errors}`}
                          setFieldValue={setFieldValue}
                          isExpanded={isExpanded}
                          onRemove={() => arrayHelpers.remove(index)}
                          values={values}
                          mountName={context.orgData?.remote_mount_name}
                        />
                      </>
                    )}
                  </AccordionItem>
                ))
              ) : (
                <></>
              )}
              <Button
                mt={values.inputs?.length > 0 ? '1.5rem' : '0rem'}
                width='12rem'
                colorScheme='gray'
                variant='outline'
                leftIcon={<AddIcon/>}
                onClick={() => arrayHelpers.push({
                  source: '',
                  destination: '',
                  arg: '',
                })}
              >
                Add an input
              </Button>
            </Accordion>
          )}
        />
      </SectionBox>
      <SectionBox
        header='Choose input and output data'
        headerId='pipeline-settings-input-output-selection'
        hidden={!useSingleInstanceLaunch}
      >
        <DataSection
          setFieldValue={setFieldValue}
          formValues={values}
        />
      </SectionBox>
      {!useSingleInstanceLaunch && <SectionBox header='Output data (locations and targets)' isFormatted>
        {isNextflow && <OutputSelection setFieldValue={setFieldValue} required={isNextflow}/>}
        <FileSelection
          label='Export location'
          fieldName='export_location'
          description='An S3 location to export results'
          setFieldValue={setFieldValue}
          isDirOnly
          isS3Only
          validate={validateExportLocation}
          isRequired={isSnakemake && !!values.export_location_source}
        />
        <Box mt='0.75rem' mb='0.75rem' />
        {isSnakemake && <FileSelection
          label='Export location source'
          fieldName='export_location_source'
          description='The directory from where results will be exported'
          setFieldValue={setFieldValue}
          isDirOnly
          isFdOnly
          validate={validateExportLocationSource}
          isRequired={!!values.export_location}
        />}
      </SectionBox>
      }
      <PipelineSettingsSection
        setFieldValue={setFieldValue}
        values={values}
        isSnakemake={isSnakemake}
      />
      <Flex mt='2rem' mb='2rem'>
        <Button colorScheme='blue' onClick={() => setActiveStep(0)}>
          Back
        </Button>
        <Spacer/>
        <Button
          onClick={onPythonOpen}
          loadingText="Generating Python command..."
          leftIcon={<FaPython />}
        >
          Show Python launch code
        </Button>
        <PythonCodeModal
          isOpen={isPythonOpen}
          onClose={onPythonClose}
          values={values}
        />
        <Flex>
          <Button
            id='pipeline-settings-launch'
            ml='1ch'
            type="submit"
            colorScheme='blue'
            isDisabled={!isValid}
            isLoading={isFetching}
            loadingText="Launching..."
          >
            Launch "{selectedPipeline}"
          </Button>
        </Flex>
      </Flex>
    </>
  );
};

export default withRequiredAuthInfo(SettingsForm);
