import * as React from 'react';
import {
  Button,
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
} from '@chakra-ui/react';
import { withRequiredAuthInfo } from '@propelauth/react';

const DeleteTemplateConfirmationModal = ({
  isOpen,
  onClose,
  onSubmit,
  isLoading,
  templateName,
  templateDescription,
}: any) => (
  <Modal isOpen={isOpen} onClose={onClose} >
    <ModalOverlay bg='blackAlpha.300' backdropFilter='blur(5px)'/>
    <ModalContent>
      <ModalHeader>Are you sure you want to delete this template?</ModalHeader>
      <ModalCloseButton/>
      <ModalBody>
        <Flex flexDirection='column'>
          <Text>
            <b>Name:</b> {templateName}
          </Text>
          <Text>
            <b>Description:</b> {templateDescription}
          </Text>
        </Flex>
      </ModalBody>
      <ModalFooter>
        <Button
          colorScheme='red'
          onClick={onSubmit}
          isLoading={isLoading}
          loadingText="Deleting template..."
        >
          Delete
        </Button>
      </ModalFooter>
    </ModalContent>
  </Modal>
);

export default withRequiredAuthInfo(DeleteTemplateConfirmationModal);
