import * as React from 'react';
import { useQuery } from '@tanstack/react-query';
import { RedirectToLogin, withRequiredAuthInfo } from '@propelauth/react';
import {
  Box,
  Button,
  Flex,
  Grid,
  Heading,
  Image,
  Spacer,
  Spinner,
  Text,
  Tooltip,
  useDisclosure,
} from '@chakra-ui/react';
import { AddIcon } from '@chakra-ui/icons';
import { createSearchParams, Link as RouterLink, useParams } from 'react-router-dom';
import { orderBy } from 'lodash';

import ErrorIcon from '../images/error_icon.svg';
import CopyableBadge from '../CopyableBadge';
import { RawRunSummary } from '../runs/types';
import RunsSummaryTable from '../runs/RunsSummaryTable';
import { convertRunTypes } from '../constants';
import UniversalSSHModal from './UniversalSSHModal';
import SectionBox from '../SectionBox';

type UniversalDetail = {
  id: string,
  function_name: string,
  volume_name?: string,
  last_used_at: Date,
  recent_errors: any[],
  runs: RawRunSummary[],
}

type InstanceError = {
  pipeline_segment_instance_id?: string,
  tes_task_id?: string,
  error_message: string,
  created_at: string,
  run_type: string,
}

const errorBox = (error: InstanceError) => (
  <Flex bg="#FEF3F2" m={4} borderRadius={8}>
    <Flex flex={1} direction="row" justify="center" align="center" height="100%">
      <Box bg="#FEE4E2" padding={4} borderRadius={100} mt={6} justifyContent="center" alignItems="center">
        <Image src={ErrorIcon} mt={-1}/>
      </Box>
    </Flex>
    <Flex flex={10}>
      <Box mt={6} mb={6}>
        <Text variant="secondary">
          {new Date(error.created_at).toLocaleString('en-US')}
        </Text>
        <Text>
          {error.error_message}
        </Text>
        <RouterLink
          to={{
            pathname: `/dashboard/${error.pipeline_segment_instance_id || error.tes_task_id}`,
            search: createSearchParams({
              runType: convertRunTypes(error.run_type).toString(),
            }).toString(),
          }}
        >
          <Button
            mt={2}
            colorScheme="reds"
          >
              Run details
          </Button>
        </RouterLink>
      </Box>
    </Flex>
  </Flex>
);

// Recent errors disabled during initial integration
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const recentErrors = (errors: InstanceError[]) => {
  if (!errors) {
    return (<Text m={4}>No errors to see here! 🎉</Text>);
  }
  const sortedErrors = orderBy(errors, 'created_at', 'desc');
  const errorBoxes = sortedErrors.map((error) => (errorBox(error)));
  return (<Box>{errorBoxes}</Box>);
  // todo: test multiple error rendering
};

const UniversalDetailPage = ({ accessToken, orgHelper }: any) => {
  const { id } = useParams();
  const { isOpen: isDebugOpen, onOpen: onDebugOpen, onClose: onDebugClose } = useDisclosure();

  const universalSummary = {
    id,
  };

  // isLoggedIn and user are injected automatically by withAuthInfo
  const orgId = orgHelper.getOrgIds()[0];

  const {
    data: universalDetail, isLoading, isSuccess, refetch,
  } = useQuery(
    [`universalDetail${universalSummary.id}`, orgId],
    async (): Promise<UniversalDetail> => {
      const response = await
      fetch(`${process.env.REACT_APP_API_URL}/app/${orgId}/universal-functions/${universalSummary.id}`, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${accessToken}`,
        },
      });
      if (response.ok) {
        const res: UniversalDetail = await response.json();
        return res;
      }
      throw new Error('Network response was not ok');
    },
    {
      refetchOnMount: true,
      refetchOnWindowFocus: false,
      refetchInterval: () => 10 * 1000,
    },
  );

  return (
    <Box>
      <Flex direction="column">
        {isLoading || !isSuccess || !universalSummary.id
          ? <Flex alignItems="center" justifyContent="center"><Spinner mt={150}/></Flex>
          : (
            <Box width="100%">
              <Flex direction="row" align="center">
                <Heading size='xl' mr={6}>
                  Flow details
                </Heading>
                <Spacer />
                <Button
                  onClick={onDebugOpen}
                  isDisabled={true}
                  colorScheme="blue"
                  m={3}
                  leftIcon={<AddIcon />}
                >
                  Start interactive run with volume
                </Button>
                <UniversalSSHModal
                  isOpen={isDebugOpen}
                  onClose={onDebugClose}
                  isLoading={false}
                  name={universalDetail.function_name}
                  onSubmit={async () => {
                    onDebugClose();
                  }}
                />
              </Flex>
              <Flex
                direction="column"
                borderWidth="1.5px"
                borderRadius="15px"
                bg={'white'}
                borderColor={'#EDF0FF'}
                mt={6}
                mb={6}
              >
                <Flex direction="row" width="100%">
                  <Flex direction="column" flex="2" pl={5} pr={5} mt={6} mb={6}>
                    <Grid templateRows='auto' gap={5}>
                      <Flex align="center" height="9">
                        <Text variant="secondary">Universal name</Text>
                      </Flex>
                      <Flex align="center" height="9">
                        <Text variant="secondary">Last used</Text>
                      </Flex>
                    </Grid>
                  </Flex>
                  <Flex direction="column" flex="4" pl={5} pr={5} mt={6} mb={6}>
                    <Grid templateRows='auto' gap={5}>
                      <Flex pb="9">
                        <CopyableBadge
                          expanding={true}
                          label={universalDetail.function_name.length > 16
                            ? `${universalDetail.function_name.substring(0, 16)}...` : universalDetail.function_name}
                          value={universalDetail.function_name}
                        />
                      </Flex>
                      <Flex align="center" height="9">
                        <Text>
                          {
                            universalDetail.last_used_at
                              ? new Date(universalDetail.last_used_at).toLocaleString('en-US')
                              : 'Never'
                          }
                        </Text>
                      </Flex>
                    </Grid>
                  </Flex>
                  <Flex flexDirection="column" flex="2" pl={5} pr={5} mt={6}>
                    <Grid templateRows='auto' gap={5}>
                      <Tooltip
                        label='This volume is only used for sequential instances (e.g. Lug, not a parallel workflow)'
                        closeDelay={200}
                      >
                        <Flex align="center" height="9">
                          <Text variant="secondary">Volume name*</Text>
                        </Flex>
                      </Tooltip>
                    </Grid>
                  </Flex>
                  <Flex direction="column" flex="4" pl={5} pr={5} mt={6}>
                    <Grid templateRows='auto' gap={5}>
                      <Flex align="center" height="9">
                        <Text>{universalDetail.volume_name || 'N/A'}</Text>
                      </Flex>
                    </Grid>
                  </Flex>
                </Flex>
              </Flex>
              <SectionBox header='Flow runs'>
                <RunsSummaryTable
                  runs={universalDetail.runs}
                  refetch={refetch}
                  noMargin={true}
                />
              </SectionBox>
            </Box>
          )
        }
      </Flex>
    </Box>

  );
};

export default withRequiredAuthInfo(UniversalDetailPage, {
  displayIfLoggedOut: <RedirectToLogin />,
});
