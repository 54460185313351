import * as React from 'react';
import { useEffect, useState } from 'react';
import {
  ChevronDownIcon, ChevronRightIcon, ExternalLinkIcon, InfoOutlineIcon, RepeatIcon,
} from '@chakra-ui/icons';
import {
  Accordion,
  AccordionButton,
  AccordionItem,
  AccordionPanel,
  Box,
  Button,
  Flex,
  HStack,
  IconButton,
  Link,
  Spacer,
  Spinner,
  Text,
} from '@chakra-ui/react';
import { Link as RouterLink } from 'react-router-dom';
import { useQueries, useQuery } from '@tanstack/react-query';
import { withRequiredAuthInfo } from '@propelauth/react';
import _ from 'lodash';

import RunLogs, { LogBox } from './RunLogs';
import { runTypes } from '../../constants';
import ActivityMonitor from './ActivityMonitor';

const RunAccordionItem = ({
  header, children, isDisabled, id, hide,
}: any) => {
  if (hide) {
    return <Box/>;
  }
  return (
    <AccordionItem isDisabled={isDisabled} key={id} id={id}>
      {({ isExpanded }) => (
        <Box>
          <AccordionButton bg={'white'} pl={5}>
            {isExpanded ? (
              <ChevronDownIcon fontSize='18px'/>
            ) : (
              <ChevronRightIcon fontSize='18px'/>
            )}
            <Text fontSize='lg' fontWeight={600} p={2} ml={3} alignContent='center'>
              {header}
            </Text>
          </AccordionButton>
          <AccordionPanel pb={0} paddingInlineStart={0} paddingInlineEnd={0}>
            {children}
          </AccordionPanel>
        </Box>
      )}
    </AccordionItem>
  );
};

const RunAccordion = ({ children, isActive }: any) => (
  <Accordion defaultIndex={isActive ? [0] : []} allowMultiple>
    {children}
  </Accordion>
);

async function getLogContent(logFile: any) {
  if (!logFile.url) {
    return {
      ...logFile,
      body: 'No log file yet',
    };
  }
  if (logFile.size > 120000) {
    return {
      ...logFile,
      body: 'Raw file too big to render. Please view raw file.',
    };
  }
  const response = await fetch(logFile.url, {});
  if (!response.ok) {
    const body = await response.json();
    throw new Error(body.error || 'Error connecting to server');
  }
  return {
    ...logFile,
    body: await response.text(),
  };
}

const RunInfoAccordion = ({
  hide,
  taskId,
  activeRun,
  runDetail,
  isTESChildTask,
  logFiles,
  orgHelper,
  accessToken,
}: any) => {
  const orgId = orgHelper.getOrgIds()[0];
  const {
    data: cloudLogs,
    error: cloudLogsQueryError,
    refetch: refetchCloudLogs,
    isFetching: cloudLogsFetching,
  } = useQuery(
    ['cloudLogs', taskId],
    async () => fetch(`${process.env.REACT_APP_API_URL}/app/${orgId}/runs/tes-tasks/${taskId}/cloud-logs`, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`,
      },
    }).then(async (response) => {
      if (!response.ok) {
        const body = await response.json();
        throw new Error(body.error || 'Error connecting to server');
      }
      const logResponse = await response.json();
      return {
        logs: logResponse.logs,
        truncated_logs: [{
          message: '(Showing latest 20 lines. You can switch to showing full logs above.)',
        }].concat(logResponse.truncated_logs),
        errors: logResponse.errors,
      };
    }),
    {
      refetchOnMount: true,
      refetchOnWindowFocus: false,
      refetchInterval: runDetail && 10 * 1000,
    },
  );

  const logQueries = useQueries({
    queries: logFiles.map((logFile: any) => ({
      queryKey: ['log', logFile.name],
      queryFn: async () => getLogContent(logFile),
      initialData: () => ({ data: null, name: logFile.name, size: logFile.size }),
      enabled: isTESChildTask && logFile.url && logFile.size < 120000,
      refetchOnMount: true,
      refetchOnWindowFocus: false,
    })),
  });
  const [pullAllLogs, setPullAllLogs] = useState(false);
  const [truncated, setTruncated] = useState(true);

  useEffect(() => {
    if (cloudLogs?.truncated_logs.length < (cloudLogs?.logs.length ?? 0)) {
      setTruncated(true);
    } else {
      setTruncated(false);
    }
  }, [cloudLogs]);

  function DisplayLogItem({ logQuery }: any) {
    const logFile = logQuery.data;
    const logLines = logFile.body && logFile.body.length > 0
      ? logFile.body.split(/\r?\n/).map((logLine: string) => ({ message: logLine }))
      : [{ message: '(empty)' }];
    return (
      <RunAccordionItem header={logFile.name} isDisabled={logFile.size === null} id={`${logFile.name}`}>
        <Flex direction='row' alignItems='center' mb={4}>
          <Text variant='secondary' noOfLines={1} ml={4}>
            The {logFile.name} file, as generated by Nextflow.
          </Text>
          <Spacer/>
          <Link
            href={logFile.url} isExternal={true}
            mr={4} width='3em' height='2em'
            _hover={{ background: 'blues.50' }}
          >
            <Flex alignItems='center' justifyContent='center' height='100%'>
              <ExternalLinkIcon/>
            </Flex>
          </Link>
        </Flex>
        <LogBox logs={logLines} id={`${logFile.name}_box`}/>
      </RunAccordionItem>
    );
  }

  if (hide) {
    return <Box/>;
  }
  const headerMessage = activeRun
    ? 'Console output from the run, as it happens.'
    : 'The run has ended! This log stream will be accessible for one week from the ending time.';
  return (
    <RunAccordion maxWidth='100%' isActive={activeRun}>
      {isTESChildTask
        && <Flex direction='row' alignItems='center' mb={4} ml={4} mt={2}>
          <InfoOutlineIcon color='greys.500'/>
          <Text variant='secondary' ml='1ch'>
            This is a child task. See <Link as={RouterLink}
              to={`/dashboard/${runDetail.parent_id}?runType=${runTypes.TES}`}>the parent
            run</Link> for higher level logs.
          </Text>
        </Flex>
      }
      <RunAccordionItem header='Streamed logs' id={`${runDetail.id}_live`}>
        <HStack direction='row' alignItems='center' mb={4} ml={4}>
          <InfoOutlineIcon color='greys.500'/>
          <Text variant='secondary' noOfLines={1}>
            {headerMessage}
          </Text>
          <Spacer/>
          {!_.isEmpty(cloudLogs?.truncated_logs) && truncated && (<>
            {pullAllLogs
              ? <Button onClick={() => setPullAllLogs(false)}>Show latest 20 lines</Button>
              : <Button onClick={() => setPullAllLogs(true)}>Show more logs</Button>
            }
          </>)}
          <IconButton
            aria-label='Refresh logs'
            onClick={() => refetchCloudLogs()}
            mr='1ch'
            icon={cloudLogsFetching ? <Spinner size='sm'/> : <RepeatIcon/>}
            isDisabled={cloudLogsFetching}
          />
        </HStack>
        <RunLogs
          taskId={taskId}
          activeRun={activeRun}
          cloudLogs={!truncated || pullAllLogs ? cloudLogs?.logs : cloudLogs?.truncated_logs}
          error={cloudLogsQueryError}
        />
      </RunAccordionItem>
      <RunAccordionItem header='Activity monitor' isDisabled={!activeRun} id={`${runDetail.id}_activity`}>
        <ActivityMonitor
          taskId={runDetail.id}
          isDisabled={!activeRun}
          isParent={!isTESChildTask}
        />
      </RunAccordionItem>
      <RunAccordionItem header='Generated command' isDisabled={!runDetail.wf_command} hide={isTESChildTask}
        id={`${runDetail.id}_command`}>
        <Text variant='secondary' noOfLines={1} mb={4} ml={4}>
          The base workflow manager command generated by FlowDeploy. You can use this to check for unexpected or
          missing arguments.
        </Text>
        {runDetail.wf_command && <LogBox logs={[{ message: runDetail.wf_command }]}/>}
      </RunAccordionItem>
      {logQueries && (
        logQueries.map((logQuery: any, index: number) => (
          <DisplayLogItem logQuery={logQuery} key={`log_file_${index}`}/>
        ))
      )}
      <RunAccordionItem header='Debug info' hide={!isTESChildTask} id={`${runDetail.id}_debug`}>
        <LogBox
          logs={[
            { message: `Run location: ${runDetail.run_location}` },
            { message: `FlowDeploy parent ID: ${runDetail.parent_id}` },
            { message: `Workflow manager work directory: ${runDetail.nf_workdir || 'N/A'}` },
            { message: `Workflow manager hash: ${runDetail.nf_hash || 'N/A'}` },
          ]}
          id='debug_box'
        />
      </RunAccordionItem>
      <Box bg='white' borderBottom='1px solid' borderColor='gray.200' />
    </RunAccordion>
  );
};

export default withRequiredAuthInfo(RunInfoAccordion);
