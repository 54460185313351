import {
  Box, Button, Flex, IconButton, Spacer, Tooltip,
} from '@chakra-ui/react';
import { Link as RouterLink } from 'react-router-dom';
import { ChevronLeftIcon, ChevronRightIcon } from '@chakra-ui/icons';
import * as React from 'react';
import {
  BsColumnsGap, BsFolder, BsGear, BsWater,
} from 'react-icons/bs';
import PageTab from './PageTab';
import { themeConfig } from './theme';
import { ReactComponent as LaunchIcon } from './images/launch_glyph.svg';

function LaunchButton({ isCollapsed }) {
  return isCollapsed
    ? (
      <Tooltip label='Launch a pipeline' placement='right' mb={1} bg="#333333">
        <IconButton aria-label='Launch a pipeline'
          as={RouterLink}
          to='pipelines/launch'
          colorScheme='blue'
          icon={<LaunchIcon width=".7em" height=".7em" fill={themeConfig.colors.blues['50']}/>}
          size='md'
          state={{ resetStep: true }}
        />
      </Tooltip>
    ) : (
      <Button
        as={RouterLink}
        to='pipelines/launch'
        colorScheme='blue'
        leftIcon={<LaunchIcon width=".7em" height=".7em" fill={themeConfig.colors.blues['50']}/>}
        width='15rem'
        mb={1}
        state={{ resetStep: true }}
      >
        Launch a pipeline
      </Button>
    );
}

const Sidebar = ({
  isCollapsed, setCollapsed, setForceOpen, width, maxWidth, heightCalculation,
}: any) => (
  <Box width={isCollapsed ? '2rem' : '16%'} borderRight='solid 2px' borderColor='gray.100'
    maxHeight='100%' minWidth={isCollapsed ? '2rem' : 'max-content'} maxWidth='300px' position='sticky'>
    <Flex direction='column' alignItems='center' justifyContent='center' pr='2rem' position='sticky'
      height={heightCalculation} pt='2vh'
      top={'7vh'} right={0} left={0} bottom={0}>
      <LaunchButton isCollapsed={isCollapsed}/>
      <RouterLink to="dashboard">
        <PageTab
          label="Dashboard"
          link="dashboard"
          icon={BsColumnsGap}
          collapsed={isCollapsed}
        />
      </RouterLink>
      <RouterLink to="data">
        <PageTab
          label="Data"
          link="data"
          icon={BsFolder}
          collapsed={isCollapsed}
        />
      </RouterLink>
      <RouterLink
        to="pipelines"
      >
        <PageTab
          label="Pipelines"
          link="pipelines"
          icon={BsWater}
          collapsed={isCollapsed}
        />
      </RouterLink>
      <RouterLink to="developer-settings">
        <PageTab
          label="Developer settings"
          link="developer-settings"
          icon={BsGear}
          collapsed={isCollapsed}
        />
      </RouterLink>
      <Spacer/>
      <Box
        as='button'
        onClick={() => {
          const newCollapsed = !isCollapsed;
          setCollapsed(newCollapsed);
          localStorage.setItem('isCollapsed', `${newCollapsed}`);
          if (newCollapsed) {
            localStorage.setItem('forceOpen', 'false');
            setForceOpen(false);
          } else if (width < maxWidth) {
            localStorage.setItem('forceOpen', 'true');
            setForceOpen(true);
          }
        }}
        alignItems='center' justifyContent='center'
        top={0}
        right={0}
        left={0}
        bottom={0}
        pb='1vh'
      >
        <PageTab
          label='Collapse sidebar'
          link='n/a'
          icon={isCollapsed
            ? ChevronRightIcon
            : ChevronLeftIcon
          }
          collapsed={isCollapsed}
        />
      </Box>
    </Flex>
  </Box>
);

export default Sidebar;
