import { orderBy } from 'lodash';

export default async function fetchPipelineVersions({ orgId, accessToken, pipelineName }: any)
  : Promise<{ releases: any[], branches: any[] } | undefined> {
  return fetch(`${process.env.REACT_APP_API_URL}/app/${orgId}/gh/pipeline/versions/${pipelineName}`, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${accessToken}`,
    },
  }).then(async (response) => {
    if (response.ok) {
      const versions = await response.json();
      return {
        releases: versions.releases
          .sort((a, b) => new Date(b.published_at).getTime() - new Date(a.publish_at).getTime()),
        branches: orderBy(versions.branches, ['default_branch', 'name'], ['desc', 'asc']),
      };
    }
    return { releases: [], branches: [] };
  });
}
