import * as React from 'react';
import {
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton, FormLabel, Text, FormControl, Input, FormErrorMessage, FormHelperText,
} from '@chakra-ui/react';
import { withRequiredAuthInfo } from '@propelauth/react';
import { useState } from 'react';
import { useQuery } from '@tanstack/react-query';

async function createNewFolder({
  prefix, folderName, accessToken, orgId,
}) {
  const response = await fetch(`${process.env.REACT_APP_API_URL}/app/${orgId}/files/registered/folder`, {
    method: 'POST',
    body: JSON.stringify({
      is_directory: false,
      prefix,
      folder_name: folderName,
    }),
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${accessToken}`,
    },
  });
  if (response.ok) {
    return response.json();
  }
  const body = await response.json();
  throw new Error(body.error || 'Network response was not ok');
}

const NewFolderModal = ({
  isOpen,
  onClose,
  isLoading,
  refetchFiles,
  prefix,
  accessToken,
  orgHelper,
}: any) => {
  const orgId = orgHelper.getOrgIds()[0];
  const [folderName, setFolderName] = useState('');

  const {
    refetch: fetchCreateNewFolder, isSuccess, isError, failureReason,
  } = useQuery(
    ['fetchCreateNewFolder', prefix, folderName],
    () => createNewFolder({
      prefix,
      folderName,
      accessToken,
      orgId,
    }),
    {
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      enabled: false,
      retry: false,
      onSuccess: () => {
        refetchFiles().then();
        onClose();
      },
    },
  );

  async function onSubmit(e) {
    e.preventDefault();
    await fetchCreateNewFolder();
  }

  return (
    <Modal isOpen={isOpen} onClose={onClose} >
      <ModalOverlay bg='blackAlpha.300' backdropFilter='blur(5px)'/>
      <form onSubmit={onSubmit}>
        <ModalContent>
          <ModalHeader>New folder</ModalHeader>
          <ModalCloseButton/>
          <ModalBody>
            <FormLabel size='sm' color='greys.900'>
              <Text>Folder name</Text>
            </FormLabel>
            <FormControl isInvalid={isError && !isSuccess} isRequired={true}>
              <Input mb={1}
                value={folderName}
                onChange={(event: any) => setFolderName(event.target.value)}
                isDisabled={isLoading}
                placeholder='Untitled'
              />
              <FormErrorMessage>{`${failureReason}`}</FormErrorMessage>
              <FormHelperText>
                Create a name for the new folder.
              </FormHelperText>
            </FormControl>
          </ModalBody>
          <ModalFooter>
            <Button
              type='submit'
              colorScheme='blue'
              isLoading={isLoading}
              isDisabled={!folderName}
              loadingText='Creating...'
            >
              Create
            </Button>
          </ModalFooter>
        </ModalContent>
      </form>
    </Modal>
  );
};

export default withRequiredAuthInfo(NewFolderModal);
