import { createColumnHelper } from '@tanstack/react-table';
import { useNavigate } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import { Progress, Tooltip } from '@chakra-ui/react';
import { withRequiredAuthInfo } from '@propelauth/react';

import { DataTable } from '../DataTable';
import PipelinesTableHeader from './PipelinesTableHeader';
import { RawPipelineInfo } from './types';
import fetchPipelines from './fetchPipelines';

const PipelinesTable = ({
  emptyMessage, isDisabled, orgHelper, accessToken, customOnRowClick,
}: any) => {
  const orgId = orgHelper.getOrgIds()[0];
  const { data: pipelines, refetch } = useQuery(
    ['pipelinesKey'],
    () => fetchPipelines({
      orgId,
      accessToken,
    }),
    {
      placeholderData: () => ([
        { name: <Progress size='xs' isIndeterminate />, versions: [], last_used_at: '' },
      ]),
      refetchOnMount: true,
      refetchOnWindowFocus: false,
      staleTime: 60 * 1000,
    },
  );

  const navigate = useNavigate();
  const onRowClick = (row: RawPipelineInfo) => {
    if (!isDisabled) {
      if (customOnRowClick) {
        customOnRowClick(row);
      } else {
        navigate(
          {
            pathname: `/pipelines/${row.id}`,
          },
          {
            state: { pipeline: row },
          },
        );
      }
    }
  };

  const columnHelper = createColumnHelper<RawPipelineInfo>();
  const columns = [
    columnHelper.accessor('name', {
      cell: (info) => info.getValue(),
      header: 'Pipeline name',
    }),
    columnHelper.accessor('last_used_at', {
      cell: (info) => (info.getValue()
        ? new Date(info.getValue()).toLocaleString('en-US')
        : 'Never used'),
      header: 'Last used',
      meta: {
        isNumeric: false,
      },
    }),
  ];

  return (
    <Tooltip
      label='The free tier is limited to validated templates. Add a credit card to run custom pipelines.'
      textAlign='center'
      placement='top'
      isDisabled={!isDisabled}
      shouldWrapChildren
    >
      <DataTable
        data={pipelines}
        columns={columns}
        refetch={refetch}
        TableHeader={PipelinesTableHeader}
        onRowClick={onRowClick}
        defaultSortId='last_used_at'
        noMargin
        idColumn='name'
        emptyMessage={emptyMessage}
        isRowSelectable={isDisabled ? () => false : undefined}
      />
    </Tooltip>
  );
};

export default withRequiredAuthInfo(PipelinesTable);
