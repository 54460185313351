import {
  Breadcrumb, BreadcrumbItem, BreadcrumbLink, Icon, Text,
} from '@chakra-ui/react';
import { ChevronRightIcon } from '@chakra-ui/icons';

const FilesBreadCrumb = ({ protocol, pathSegments, onBreadCrumbClick }: any) => (
  <Breadcrumb
    ml={protocol ? '1rem' : 0}
    spacing='0.25ch'
    separator={<Icon as={ChevronRightIcon} color='gray.500'/>}
    alignItems='center'
  >
    {protocol && (
      <BreadcrumbItem key='fd'>
        <Text variant='secondary' color='gray.500'>{protocol}</Text>
      </BreadcrumbItem>
    )}
    { pathSegments.map((segment: string, index: number) => <BreadcrumbItem key={`${segment}${index}`}>
      <BreadcrumbLink
        key={`link${segment}${index}`}
        onClick={() => onBreadCrumbClick({ index })}
      >
        <Text
          color={index === pathSegments.length - 1 ? 'gray.700' : 'gray.500'} // highlight current level
        >
          {segment}
        </Text>
      </BreadcrumbLink>
    </BreadcrumbItem>)
    }
  </Breadcrumb>
);

export default FilesBreadCrumb;
