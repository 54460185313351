import {
  Box, Button, Flex, Icon, Text, Tooltip, useDisclosure, useToast,
} from '@chakra-ui/react';
import { BsFolder } from 'react-icons/bs';
import * as React from 'react';
import { createColumnHelper } from '@tanstack/react-table';
import { findIndex, isEmpty, map } from 'lodash';
import TableEmpty from '../NewFilesTable/TableEmpty';
import FileSelectModal from '../FileSelectModal';
import NewFilesTable from '../NewFilesTable/NewFilesTable';
import { LaunchInputFile } from '../types';
import { Actions, DirectoryEntry, EditableField } from '../NewFilesTable/ColumnComponents';
import DataLocationInput from './DataLocationInput';
import UndoToastTitle from './UndoToastTitle';
import NewFilesTableHeader from '../NewFilesTable/NewFilesTableHeader';

const InputDataSection = ({
  onAdd, onEditArg, onEditRelativePath, onRemove, inputs,
}: any) => {
  const { isOpen: isSelectOpen, onOpen: onSelectOpen, onClose: onSelectClose } = useDisclosure();
  const toast = useToast();

  const undoneToast = () => {
    toast.closeAll();
    toast({
      title: 'Action undone',
      position: 'bottom',
      status: 'success',
      isClosable: true,
    });
  };

  function findExistingURLIndex(url) {
    if (isEmpty(inputs)) {
      return -1;
    }
    return findIndex(inputs, (input: LaunchInputFile) => input.url === url);
  }

  function removeByURL(url) {
    const urlIndex = findExistingURLIndex(url);
    onRemove(urlIndex);
  }

  function addIfNotDuplicate(selectedFile) {
    if (findExistingURLIndex(selectedFile.url) === -1) {
      onAdd({
        selectedFile,
      });
    }
  }

  function onAddMultipleWithUndo(selectedFiles) {
    const shuckedSelectedFiles: LaunchInputFile[] = map(selectedFiles, (selectedFile) => ({
      url: selectedFile?.url,
      label: selectedFile?.file?.label,
      file: selectedFile?.file,
    }));
    function undoAdd() {
      map(shuckedSelectedFiles, (selectedFile) => removeByURL(selectedFile.url));
      undoneToast();
    }
    map(shuckedSelectedFiles, (selectedFile) => addIfNotDuplicate(selectedFile));
    toast({
      title: <UndoToastTitle title='Added to your chosen input data' undo={undoAdd} />,
      position: 'bottom',
      status: 'success',
      isClosable: true,
    });
  }

  function onAddWithUndo({ pathValue, file }) {
    function undoAdd() {
      removeByURL(pathValue);
      undoneToast();
    }
    const selectedFile: LaunchInputFile = {
      url: pathValue,
      file: !pathValue.startsWith('s3://') && file,
    };
    onAdd({ selectedFile });
    toast({
      title: <UndoToastTitle title='Added to your chosen input data' undo={undoAdd} />,
      position: 'bottom',
      status: 'success',
      isClosable: true,
    });
  }

  function onRemoveWithUndo({ index, original }) {
    function undoRemove() {
      onAdd({
        selectedFile: original,
      });
      undoneToast();
    }
    onRemove(index);
    toast({
      title: <UndoToastTitle title='Removed chosen input data' undo={undoRemove} />,
      position: 'bottom',
      status: 'success',
      isClosable: true,
    });
  }

  function validateInputURL(value) {
    // Further validation (size, FD URL exists) will happen separately after the file is added
    // If length greater than four, must start with fd:// or s3://
    const isS3 = value.startsWith('s3://');
    const isFd = value.startsWith('fd://');
    if (value.length > 4 && !(isS3 || isFd)) {
      return 'Must start with fd:// or s3://';
    }
    // Check if already in table
    const existingUri = findExistingURLIndex(value) !== -1;
    if (existingUri) {
      return 'Cannot add two of the same URL';
    }
    // todo:
    // If length greater than two, must start with fd or s3
    // Data must not be a root fd path
    return '';
  }

  const columnHelper = createColumnHelper<LaunchInputFile>();
  const columns = [
    columnHelper.accessor('label', {
      cell: (info) => <DirectoryEntry info={info} />,
      header: () => 'Label',
    }),
    columnHelper.accessor('url', {
      cell: (info) => (
        <Tooltip label={info.getValue()} placement='top' hasArrow>
          {info.getValue() || <Text color='orange.500'>Warning: invalid empty URL!</Text>}
        </Tooltip>),
      header: 'URL',
    }),
    columnHelper.accessor('arg', {
      cell: ({ row }: any) => (
        <EditableField
          defaultValue={row.original.arg}
          onSubmit={(newArg) => onEditArg({ index: row.index, newArg })}
        />
      ),
      header: 'Arg',
    }),
    columnHelper.accessor('relative_path', {
      cell: ({ row }: any) => (
        <EditableField
          defaultValue={row.original.relative_path}
          onSubmit={(relativePath) => onEditRelativePath({ index: row.index, relativePath })}
        />
      ),
      header: 'Instance path',
    }),
    {
      id: 'actions',
      header: '',
      cell: ({ row }: any) => <Actions
        onRemove={() => onRemoveWithUndo({
          index: row.index,
          original: row.original,
        })}
        file={row.original}
      />,
    },
  ];

  return (
    <>
      <Flex
        id='input-data-section'
        display='flex'
        padding='1.25rem 1.5625rem'
        direction='column'
        justify='center'
        align='flex-start'
        gap='1.25rem'
        alignSelf='stretch'
        borderRadius='0.625rem 0.625rem 0rem 0rem'
      >
        <Flex
          id='secondary-header-input'
          direction='column'
          justify='center'
          align='flex-start'
          alignSelf='stretch'
        >
          <Flex
            id='heading-box'
            direction='column'
            justify='center'
            align='flex-start'
            gap='0.75rem'
            alignSelf='stretch'
          >
            <Text variant='text-xl/lineHeight-7/font-semibold'>
              Input data
            </Text>
          </Flex>
          <Flex>
            <Text variant='text-md/lineHeight-6/font-medium' alignSelf='stretch'>
              Choose your input data by either typing the data’s URL, or selecting the data from your connected
              file system.
            </Text>
            <Box id='pipeline-settings-input-selection' width='0.1rem' height='0.01rem' />
          </Flex>
        </Flex>
        <Flex
          id='data-selection-box'
          direction='column'
          justify='center'
          align='flex-start'
          gap='0.9375rem'
          alignSelf='stretch'
        >
          <Flex
            id='browse-and-select-box'
            direction='column'
            justify='center'
            align='flex-start'
            gap='0.3125rem'
          >
            <FileSelectModal
              isOpen={isSelectOpen}
              onClose={onSelectClose}
              handleSelect={(selectedFiles) => onAddMultipleWithUndo(selectedFiles)}
              isLocalMultiSelect
            />
            <Text variant='text-md/lineHeight-6/font-normal'>
              Browse and select data:
            </Text>
            <Button
              variant='outline'
              colorScheme='gray'
              size='md'
              leftIcon={<Icon as={BsFolder} height='1rem' width='1rem'/>}
              onClick={onSelectOpen}
            >
              Connected file system
            </Button>

          </Flex>
          <Flex
            id='url-box'
            direction='column'
            width='100%'
            justify='center'
            align='flex-start'
            gap='1.25rem'
          >
            <Box
              id='url-input-container'
              width='100%'
              pr='0.75rem'
              gap='0.3125rem'
            >
              <Text mb='0.3125rem'>
                Type the URL of the data:
              </Text>
              <DataLocationInput
                onSubmit={onAddWithUndo}
                typeLabel='input'
                placeholder='fd://my-drive/example.txt'
                tooltipText='Where is the input file is located? Include the protocol (e.g. fd://, s3://).'
                validate={validateInputURL}
                minLength={5}
              />
            </Box>
          </Flex>
        </Flex>
      </Flex>
      <Flex
        id='selected-input-data-section'
        padding='1.25rem 1.5625rem 1.5625rem 1.5625rem'
        direction='column'
        gap='0.625rem'
        alignItems='flex-start'
        justify='center'
        alignSelf='stretch'
      >
        <Flex
          id='secondary-header-output'
          direction='column'
          alignItems='flex-start'
          width='100%'
        >
          <Text variant='text-lg/lineHeight-7/font-medium' mb='0.625rem'>
            Input data chosen
          </Text>
          <NewFilesTable
            hide={inputs?.length === 0}
            idColumn='url'
            files={inputs}
            columns={columns}
            TableHeader={NewFilesTableHeader}
            enableRowSelection={false}
          />
          <TableEmpty
            hide={inputs?.length !== 0}
            headingText='No input data chosen'
            bodyText='The data you choose as your inputs will appear here.'
          />
        </Flex>
      </Flex>
    </>
  );
};

export default InputDataSection;
