import * as React from 'react';
import {
  Checkbox,
  Flex,
  Icon,
} from '@chakra-ui/react';
import { createColumnHelper } from '@tanstack/react-table';
import { GoUnlink } from 'react-icons/go';
import { useState } from 'react';
import { DrivesContext } from '../DriveProvider';
import withInitialProps from '../inputOutputSelection/withInitalProps';
import NewFilesTableHeader, { Breadcrumb, BreadcrumbConfig } from '../NewFilesTable/NewFilesTableHeader';
import NewFilesTable from '../NewFilesTable/NewFilesTable';
import { Actions, DirectoryEntry } from '../NewFilesTable/ColumnComponents';
import formatBytes from '../../utils';
import { GenericFile } from '../types';

type SelectingConfig = {
  onSelect: any,
  onDeselect: any,
  isSelectingDirsOnly: boolean,
  enableMultiRowSelection: boolean,
}

type FilesProps = {
  selectingConfig?: SelectingConfig,
}

const Files = ({ selectingConfig }: FilesProps) => {
  const {
    searchParams,
    setSearchParams,
    selectedFileSystem,
    originalPath,
    files,
    refetchFiles,
  } = React.useContext(DrivesContext);

  // The current registered directory
  const [registeredDirectoryUrl, setRegisteredDirectoryUrl] = useState('');
  const [breadcrumbs, setBreadcrumbs] = useState([{
    displayName: selectedFileSystem.slice(5),
    to: '',
    isRegisteredDirectory: false,
    isAboveRegisteredDirectory: true,
    subdirectory: '',
  }]);

  const breadcrumbConfig: BreadcrumbConfig = {
    path: registeredDirectoryUrl,
    setPath: (breadcrumb: Breadcrumb) => {
      if (breadcrumb.isRegisteredDirectory) {
        setRegisteredDirectoryUrl(breadcrumb.to);
      } else if (breadcrumb.isAboveRegisteredDirectory) {
        setRegisteredDirectoryUrl('');
      }
      setSearchParams({
        path: breadcrumb.to,
        subdir: breadcrumb.subdirectory,
      });
    },
    breadcrumbs,
    setBreadcrumbs,
  };

  // This row click handling is similar to run output files, but is more generalized and slightly different
  const onRowClick = (row: GenericFile) => {
    if (row.is_dir) {
      // Warning: this section is tightly coupled to breadcrumbs and DriveProvider search params
      // Clicking into a registered directory for the first time
      const isClickRegisteredDirectory = !registeredDirectoryUrl && row.is_registered_file;
      // Clicking a directory that is not a registered directory nor in a registered directory
      const isClickNotInRegisteredDirectory = !registeredDirectoryUrl && !row.is_registered_file;

      let subdirectoryFromRegisteredUrl = '';
      if (isClickRegisteredDirectory) {
        setRegisteredDirectoryUrl(row.url);
        const updatedPath = `${searchParams.get('path')}${row.name}/`;
        setSearchParams({ path: updatedPath });
      } else if (isClickNotInRegisteredDirectory) {
        const updatedPath = `${searchParams.get('path')}${row.name}/`;
        setSearchParams({ path: updatedPath });
      } else {
        // We must be clicking a directory inside a registered directory
        // eslint-disable-next-line prefer-destructuring
        subdirectoryFromRegisteredUrl = row.url.split(`${registeredDirectoryUrl}`)[1];
        setSearchParams({ path: `${registeredDirectoryUrl}`, subdir: subdirectoryFromRegisteredUrl });
      }
      setBreadcrumbs([
        ...breadcrumbs,
        {
          displayName: row.name || row.url,
          to: `${row.url}`,
          isRegisteredDirectory: row.is_registered_file,
          isAboveRegisteredDirectory: !registeredDirectoryUrl,
          subdirectory: subdirectoryFromRegisteredUrl,
        },
      ]);

      refetchFiles().then();
    }
  };

  const columnHelper = createColumnHelper<GenericFile>();
  const columns = [
    {
      id: 'select',
      header: '',
      cell: ({ row }: any) => (
        <Flex width='100%' align='center' justify='left'>
          <Checkbox
            size='lg'
            isChecked={row.getIsSelected()}
          />
        </Flex>
      ),
    },
    columnHelper.accessor('name', {
      cell: (info) => <DirectoryEntry info={info} />,
      header: 'File name',
    }),
    columnHelper.accessor('label', {
      cell: (info) => info.getValue(),
      header: () => 'Label',
    }),
    columnHelper.accessor('size', {
      cell: (info) => {
        const size = info.row.original.is_dir ? null : info.getValue();
        if (info.row.original.is_alias && !info.row.original.size) {
          return (
            <Icon as={GoUnlink} boxSize='1.2em' color='orange.600'/>
          );
        }
        return formatBytes(size);
      },
      header: 'Size',
    }),
    columnHelper.accessor('url', {
      cell: (info) => (
        <Actions
          enableDownload
          file={info.row.original}
        />
      ),
      header: () => 'Actions',
    }),
  ];

  return (
    <>
      <NewFilesTable
        idColumn='url'
        columns={columns}
        files={files}
        refetch={refetchFiles}
        TableHeader={withInitialProps({
          originalPath,
          enableEditHeader: true,
          enableAddData: true, // todo: disable adding data if inside a registered directory
          breadcrumbConfig,
        })(NewFilesTableHeader)}
        onRowClick={onRowClick}
        emptyMessage={'There\'s nothing in this directory yet.'}
        isSelecting
        onSelect={selectingConfig?.onSelect}
        onDeselect={selectingConfig?.onDeselect}
        isSelectingDirsOnly={selectingConfig?.isSelectingDirsOnly}
        enableMultiRowSelection={selectingConfig?.enableMultiRowSelection}
      />
    </>
  );
};

export default Files;
