async function fetchPresignedUrl({
  registeredUrl, subdir, accessToken, orgId,
}) {
  const response = await fetch(`${process.env.REACT_APP_API_URL}/app/${orgId}/files/registered/download-url`, {
    method: 'PUT',
    body: JSON.stringify({
      object_path: subdir,
      url: registeredUrl,
    }),
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${accessToken}`,
    },
  });
  if (response.ok) {
    const { url } = await response.json();
    return url;
  }
  const body = await response.json();
  throw new Error(body.error || 'Network response was not ok');
}

export default fetchPresignedUrl;
