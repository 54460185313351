export type GenericFile = {
  url: string,
  id: string,
  label?: string,
  name: string,
  size: number,
  is_registered_file: boolean,
  last_modified?: Date;
  is_dir: boolean,
  subdir?: string,
  is_alias?: boolean,
  registered_directory_root: any,
  is_input?: boolean, // is the file referenced in the context of a run's input file
  not_selectable?: boolean,
}

export type LaunchInputFile = {
  label?: string;
  url: string;
  arg?: string;
  relative_path?: string;
  file?: GenericFile;
}

export type LaunchOutputFile = {
  path: string;
  label?: string;
}

export enum TransferTypes {
  File = 'file',
  Directory = 'directory',
}
