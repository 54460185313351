/* eslint-disable max-len */
const publicPipelines = [
  {
    name: 'nf-core/airrflow', latestVersion: '3.1.0', description: 'B-cell and T-cell Adaptive Immune Receptor Repertoire (AIRR) sequencing analysis pipeline using the Immcantation wf_manager', wf_manager: 'nextflow',
  },
  {
    name: 'nf-core/ampliseq', latestVersion: '2.6.1', description: 'Amplicon sequencing analysis workflow using DADA2 and QIIME2', wf_manager: 'nextflow',
  },
  {
    name: 'nf-core/atacseq', latestVersion: '2.1.2', description: 'ATAC-seq peak-calling and QC analysis pipeline', wf_manager: 'nextflow',
  },
  {
    name: 'nf-core/bacass', latestVersion: '2.0.0', description: 'Simple bacterial assembly and annotation pipeline', wf_manager: 'nextflow',
  },
  {
    name: 'nf-core/bactmap', latestVersion: '1.0.0', description: 'A mapping-based pipeline for creating a phylogeny from bacterial whole genome sequences', wf_manager: 'nextflow',
  },
  {
    name: 'nf-core/bamtofastq', latestVersion: '2.0.0', description: 'Converts bam or cram files to fastq format and does quality control.', wf_manager: 'nextflow',
  },
  {
    name: 'nf-core/cageseq', latestVersion: '1.0.2', description: 'CAGE-sequencing analysis pipeline with trimming, alignment and counting of CAGE tags.', wf_manager: 'nextflow',
  },
  {
    name: 'nf-core/chipseq', latestVersion: '2.0.0', description: 'ChIP-seq peak-calling, QC and differential analysis pipeline.', wf_manager: 'nextflow',
  },
  {
    name: 'nf-core/circdna', latestVersion: '1.0.4', description: 'Pipeline for the identification of extrachromosomal circular DNA (ecDNA) from Circle-seq, WGS, and ATAC-seq data that were generated from cancer and other eukaryotic cells.', wf_manager: 'nextflow',
  },
  {
    name: 'nf-core/clipseq', latestVersion: '1.0.0', description: 'CLIP sequencing analysis pipeline for QC, pre-mapping, genome mapping, UMI deduplication, and multiple peak-calling options.', wf_manager: 'nextflow',
  },
  {
    name: 'nf-core/coproid', latestVersion: '1.1.1', description: 'Coprolite host Identification pipeline', wf_manager: 'nextflow',
  },
  {
    name: 'nf-core/crisprseq', latestVersion: '2.0.0', description: 'A pipeline for the analysis of CRISPR edited data. It allows the evaluation of the quality of gene editing experiments using targeted next generation sequencing (NGS) data (`targeted`) as well as the discovery of important genes from knock-out or activation CRISPR-Cas9 screens using CRISPR pooled DNA (`screening`).', wf_manager: 'nextflow',
  },
  {
    name: 'nf-core/cutandrun', latestVersion: '3.1', description: 'Analysis pipeline for CUT&RUN and CUT&TAG experiments that includes QC, support for spike-ins, IgG controls, peak calling and downstream analysis.', wf_manager: 'nextflow',
  },
  {
    name: 'nf-core/deepvariant', latestVersion: '1', description: 'Please consider using/contributing to https://github.com/nf-core/sarek', wf_manager: 'nextflow',
  },
  {
    name: 'nf-core/demultiplex', latestVersion: '1.3.2', description: 'Demultiplexing pipeline for sequencing data', wf_manager: 'nextflow',
  },
  {
    name: 'nf-core/diaproteomics', latestVersion: '1.2.4', description: 'Automated quantitative analysis of DIA proteomics mass spectrometry measurements.', wf_manager: 'nextflow',
  },
  {
    name: 'nf-core/differentialabundance', latestVersion: '1.2.0', description: 'Differential abundance analysis for feature/ observation matrices from platforms such as RNA-seq', wf_manager: 'nextflow',
  },
  {
    name: 'nf-core/dualrnaseq', latestVersion: '1.0.0', description: 'Analysis of Dual RNA-seq data - an experimental method for interrogating host-pathogen interactions through simultaneous RNA-seq.', wf_manager: 'nextflow',
  },
  {
    name: 'nf-core/eager', latestVersion: '2.4.7', description: 'A fully reproducible and state-of-the-art ancient DNA analysis pipeline', wf_manager: 'nextflow',
  },
  {
    name: 'nf-core/epitopeprediction', latestVersion: '2.2.1', description: 'A bioinformatics best-practice analysis pipeline for epitope prediction and annotation', wf_manager: 'nextflow',
  },
  {
    name: 'nf-core/fetchngs', latestVersion: '1.10.0', description: 'Pipeline to fetch metadata and raw FastQ files from public and private databases', wf_manager: 'nextflow',
  },
  {
    name: 'nf-core/funcscan', latestVersion: '1.1.3', description: '(Meta-)genome screening for functional and natural product gene sequences', wf_manager: 'nextflow',
  },
  {
    name: 'nf-core/hgtseq', latestVersion: '1.1.0', description: 'A pipeline to investigate horizontal gene transfer from NGS data', wf_manager: 'nextflow',
  },
  {
    name: 'nf-core/hic', latestVersion: '2.1.0', description: 'Analysis of Chromosome Conformation Capture data (Hi-C)', wf_manager: 'nextflow',
  },
  {
    name: 'nf-core/hicar', latestVersion: '1.0.0', description: 'Pipeline for HiCAR data, a robust and sensitive multi-omic co-assay for simultaneous measurement of transcriptome, chromatin accessibility and cis-regulatory chromatin contacts.', wf_manager: 'nextflow',
  },
  {
    name: 'nf-core/hlatyping', latestVersion: '2.0.0', description: 'Precision HLA typing from next-generation sequencing data', wf_manager: 'nextflow',
  },
  {
    name: 'nf-core/imcyto', latestVersion: '1.0.0', description: 'Image Mass Cytometry analysis pipeline', wf_manager: 'nextflow',
  },
  {
    name: 'nf-core/isoseq', latestVersion: '1.1.4', description: 'Genome annotation with PacBio Iso-Seq. Takes raw subreads as input, generate Full Length Non Chemiric (FLNC) sequences and produce a bed annotation.', wf_manager: 'nextflow',
  },
  {
    name: 'nf-core/mag', latestVersion: '2.3.2', description: 'Assembly and binning of metagenomes', wf_manager: 'nextflow',
  },
  {
    name: 'nf-core/marsseq', latestVersion: '1.0.3', description: 'MARS-seq v2 pre-processing pipeline with velocity', wf_manager: 'nextflow',
  },
  {
    name: 'nf-core/metaboigniter', latestVersion: '1.0.1', description: 'Pre-processing of mass spectrometry-based metabolomics data with quantification and identification based on MS1 and MS2 data.', wf_manager: 'nextflow',
  },
  {
    name: 'nf-core/methylseq', latestVersion: '2.4.0', description: 'Methylation (Bisulfite-Sequencing) analysis pipeline using Bismark or bwa-meth + MethylDackel', wf_manager: 'nextflow',
  },
  {
    name: 'nf-core/mhcquant', latestVersion: '2.4.1', description: 'Identify and quantify MHC eluted peptides from mass spectrometry raw data', wf_manager: 'nextflow',
  },
  {
    name: 'nf-core/mnaseseq', latestVersion: '1.0.0', description: 'MNase-seq analysis pipeline using BWA and DANPOS2.', wf_manager: 'nextflow',
  },
  {
    name: 'nf-core/nanoseq', latestVersion: '3.1.0', description: 'Nanopore demultiplexing, QC and alignment pipeline', wf_manager: 'nextflow',
  },
  {
    name: 'nf-core/nanostring', latestVersion: '1.1.1', description: 'An analysis pipeline for Nanostring nCounter expression data.', wf_manager: 'nextflow',
  },
  {
    name: 'nf-core/nascent', latestVersion: '2.1.1', description: 'Nascent Transcription Processing Pipeline', wf_manager: 'nextflow',
  },
  {
    name: 'nf-core/pangenome', latestVersion: '1.0.0', description: 'Renders a collection of sequences into a pangenome graph.', wf_manager: 'nextflow',
  },
  {
    name: 'nf-core/pgdb', latestVersion: '1.0.0', description: 'The ProteoGenomics database generation workflow creates different protein databases for ProteoGenomics data analysis.', wf_manager: 'nextflow',
  },
  {
    name: 'nf-core/phyloplace', latestVersion: '1.0.0', description: 'nf-core/phyloplace is a bioinformatics best-practice analysis pipeline that performs phylogenetic placement with EPA-NG.', wf_manager: 'nextflow',
  },
  {
    name: 'nf-core/proteinfold', latestVersion: '1.0.0', description: 'Protein 3D structure prediction pipeline', wf_manager: 'nextflow',
  },
  {
    name: 'nf-core/proteomicslfq', latestVersion: '1.0.0', description: 'Proteomics label-free quantification (LFQ) analysis pipeline', wf_manager: 'nextflow',
  },
  {
    name: 'nf-core/quantms', latestVersion: '1.1.1', description: 'Quantitative mass spectrometry workflow. Currently supports proteomics experiments with complex experimental designs for DDA-LFQ, DDA-Isobaric and DIA-LFQ quantification.', wf_manager: 'nextflow',
  },
  {
    name: 'nf-core/raredisease', latestVersion: '1.1.1', description: 'Call and score variants from WGS/WES of rare disease patients.', wf_manager: 'nextflow',
  },
  {
    name: 'nf-core/rnafusion', latestVersion: '2.3.4', description: 'RNA-seq analysis pipeline for detection gene-fusions', wf_manager: 'nextflow',
  },
  {
    name: 'nf-core/rnaseq', latestVersion: '3.12.0', description: 'RNA sequencing analysis pipeline using STAR, RSEM, HISAT2 or Salmon with gene/isoform counts and extensive quality control.', wf_manager: 'nextflow',
  },
  {
    name: 'nf-core/rnavar', latestVersion: '1.0.0', description: 'gatk4 RNA variant calling pipeline', wf_manager: 'nextflow',
  },
  {
    name: 'nf-core/sarek', latestVersion: '3.2.3', description: 'Analysis pipeline to detect germline or somatic variants (pre-processing, variant calling and annotation) from WGS / targeted sequencing', wf_manager: 'nextflow',
  },
  {
    name: 'nf-core/scrnaseq', latestVersion: '2.4.0', description: 'A single-cell RNAseq pipeline for 10X genomics data', wf_manager: 'nextflow',
  },
  {
    name: 'nf-core/slamseq', latestVersion: '1.0.0', description: 'SLAMSeq processing and analysis pipeline', wf_manager: 'nextflow',
  },
  {
    name: 'nf-core/smrnaseq', latestVersion: '2.2.1', description: 'A small-RNA sequencing analysis pipeline', wf_manager: 'nextflow',
  },
  {
    name: 'nf-core/taxprofiler', latestVersion: '1.0.1', description: 'Highly parallelised multi-taxonomic profiling of shotgun short- and long-read metagenomic data', wf_manager: 'nextflow',
  },
  {
    name: 'nf-core/viralintegration', latestVersion: '0.1.1', description: 'Analysis pipeline for the identification of viral integration events in genomes using a chimeric read approach.', wf_manager: 'nextflow',
  },
  {
    name: 'nf-core/viralrecon', latestVersion: '2.6.0', description: 'Assembly and intrahost/low-frequency variant calling for viral samples', wf_manager: 'nextflow',
  },
  {
    name: 'snakemake-workflows/chipseq', latestVersion: 'master', description: 'ChIP-seq peak-calling, QC and differential analysis pipeline (Snakemake port of the nextflow pipeline at https://nf-co.re/chipseq).', wf_manager: 'snakemake',
  },
  {
    name: 'snakemake-workflows/ngs-test-data', latestVersion: 'v1.0.0', description: 'A workflow for creating small NGS test data sets, useful for continuous integration.', wf_manager: 'snakemake',
  },
  {
    name: 'snakemake-workflows/dna-seq-short-read-circle-map', latestVersion: 'v1.0.0', description: 'A snakemake workflow for calling extrachromosomal circular DNA in Illumina short-read sequencing data with Circle-Map', wf_manager: 'snakemake',
  },
  {
    name: 'snakemake-workflows/rna-seq-star-deseq2', latestVersion: 'v2.0.0', description: 'RNA-seq workflow using STAR and DESeq2', wf_manager: 'snakemake',
  },
  {
    name: 'snakemake-workflows/cyrcular-calling', latestVersion: 'v2.0.0', description: 'A Snakemake workflow for ecDNA detection in Nanopore or Illumina sequencing reads derived from DNA samples enriched for circular DNA.', wf_manager: 'snakemake',
  },
  {
    name: 'snakemake-workflows/dna-seq-gatk-variant-calling', latestVersion: 'v2.1.1', description: 'This Snakemake pipeline implements the GATK best-practices workflow', wf_manager: 'snakemake',
  },
  {
    name: 'snakemake-workflows/dna-seq-neoantigen-prediction', latestVersion: 'neopredict-lung-v0.1', description: 'Snakemake workflow for neoantigen prediction', wf_manager: 'snakemake',
  },
  {
    name: 'snakemake-workflows/dna-seq-mtb', latestVersion: 'v1.4.1', description: 'A flavor of https://github.com/snakemake-workflows/dna-seq-varlociraptor preconfigured for molecular tumor boards', wf_manager: 'snakemake',
  },
  {
    name: 'snakemake-workflows/dna-seq-benchmark', latestVersion: 'v1.8.4', description: 'A snakemake workflow for benchmarking variant calling approaches with Genome in a Bottle (GIAB), CHM (syndip) or other custom datasets', wf_manager: 'snakemake',
  },
  {
    name: 'snakemake-workflows/rna-seq-kallisto-sleuth', latestVersion: 'v2.5.1', description: 'A Snakemake workflow for differential expression analysis of RNA-seq data with Kallisto and Sleuth.', wf_manager: 'snakemake',
  },
  {
    name: 'snakemake-workflows/dna-seq-varlociraptor', latestVersion: 'v5.0.1', description: 'A Snakemake workflow for calling small and structural variants under any kind of scenario (tumor/normal, tumor/normal/relapse, germline, pedigree, populations) via the unified statistical model of Varlociraptor.', wf_manager: 'snakemake',
  },
];

export default publicPipelines;
