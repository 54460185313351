import {
  Flex, Link, Spacer, Text,
} from '@chakra-ui/react';
import { throttle } from 'lodash';
import * as React from 'react';

const UndoToastTitle = ({ title, undo }) => (
  <Flex align='center'>
    <Text>{title}</Text>
    <Spacer/>
    <Link
      onClick={throttle(undo, 300)}
      variant='embedded'
      ml='2rem'
    >
      Undo
    </Link>
  </Flex>
);

export default UndoToastTitle;
