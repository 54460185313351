import { Box, Flex, Text } from '@chakra-ui/react';
import { withRequiredAuthInfo } from '@propelauth/react';
import { useQuery } from '@tanstack/react-query';
import { useNavigate, useSearchParams } from 'react-router-dom';

const GithubConnectPage = ({ orgHelper, accessToken }: any) => {
  const [searchParams] = useSearchParams();
  const stateOrg = searchParams.get('state');
  const installId = searchParams.get('installation_id');
  const orgId = orgHelper.getOrgIds()[0];
  const navigate = useNavigate();

  useQuery(
    ['connectGithub', orgId],
    () => fetch(`${process.env.REACT_APP_API_URL}/app/${orgId}/gh/connect?installation_id=${installId}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`,
      },
    }).then((response) => {
      if (response.ok) {
        navigate('/developer-settings?connected=true');
      } else {
        navigate('/developer-settings?connected=false');
      }
    }),
    {
      enabled: true,
      refetchOnMount: false,
      refetchOnWindowFocus: false,
    },
  );

  return stateOrg === orgId ? (
    <>
      <Flex justify="right">
        <Box>
          <Text>Account connected</Text>
        </Box>
      </Flex>
    </>
  ) : (
    <>
      <Text> ERROR </Text>
    </>
  );
};

export default withRequiredAuthInfo(GithubConnectPage);
