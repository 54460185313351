import { Flex, Image, Text } from '@chakra-ui/react';
import * as React from 'react';

const TableEmpty = ({
  image, headingText, bodyText, hide,
}: any) => {
  if (hide) {
    return (<></>);
  }
  return (
    <Flex
      id='table-empty'
      padding='3.13rem 1.25rem'
      pt={image ? 0 : undefined}
      justify='center'
      align='center'
      gap='3.125rem'
      borderRadius='0.75rem'
      border='1px solid'
      borderColor='gray.200'
      bgColor='gray.50'
      width='100%'
    >
      <Flex
        id='message-body'
        direction='column'
        justify='center'
        align='center'
        gap='1.25rem'
      >
        <Flex
          id='message-text'
          direction='column'
          gap='0.625rem'
          justify='center'
          align='center'
        >
          <Image as={image} mb={0} pb={0} />
          <Text variant='text-xl/lineHeight-7/font-medium'>
            {headingText}
          </Text>
          <Text variant='text-sm/lineHeight-5/font-normal' maxWidth='28.125rem' textAlign='center'>
            {bodyText}
          </Text>
        </Flex>
      </Flex>
    </Flex>
  );
};

export default TableEmpty;
