import { createContext } from 'react';

export type OrgDataType = {
  remote_mount_name: string
}

export const UserContext = createContext({
  orgData: {
    remote_mount_name: '',
    team_name: '',
    billing_portal_url: null,
    is_trialing: false,
    is_single_instance: false,
  },
});
