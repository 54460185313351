import * as React from 'react';
import { useState } from 'react';
import {
  Box,
  Button,
  Flex,
  Image,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Skeleton, Spacer,
  Text,
} from '@chakra-ui/react';
import { withRequiredAuthInfo } from '@propelauth/react';
import { useQuery } from '@tanstack/react-query';
import { LogBox } from '../runs/details/RunLogs';
import fetchPresignedUrl from './fetchPresignedUrl';
import DownloadMenuItem from './DownloadMenuItem';

function parsePreviewInput({ content, convertToMessages }) {
  if (!content) {
    return [({ message: '(empty)' })];
  }
  if (convertToMessages) {
    const parsed = content.split(/\r?\n/);
    return parsed.map((logLine: string) => ({ message: logLine }));
  }
  return content;
}

async function fetchS3File({
  registeredUrl, convertToMessages = true, ...otherProps
}) {
  const presignedUrl = await fetchPresignedUrl({ registeredUrl, ...otherProps });

  const response = await fetch(presignedUrl, {
    headers: {
      'Content-Type': 'application/json',
    },
  });
  if (response.ok) {
    const content = await response.text();
    return parsePreviewInput({ content, convertToMessages });
  }
  const body = await response.json();
  throw new Error(body.error || 'Network response was not ok');
}

async function fetchFileViaAPI({
  orgId, path, accessToken, convertToMessages = true,
}) {
  const response = await
  fetch(`${process.env.REACT_APP_API_URL}/app/${orgId}/files/content?path=${encodeURIComponent(path)}`, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${accessToken}`,
    },
  });
  if (response.ok) {
    const jsonResponse = await response.json();
    const { content } = jsonResponse;
    if (!content) {
      return [({ message: '(empty)' })];
    }
    if (convertToMessages) {
      const parsed = atob(content).split(/\r?\n/);
      return parsed.map((logLine: string) => ({ message: logLine }));
    }
    return content;
  }
  const body = await response.json();
  throw new Error(body.error || 'Network response was not ok');
}

function fetchFile(args) {
  if (args.registeredUrl) {
    return fetchS3File(args);
  }
  return fetchFileViaAPI(args);
}

const FilePreviewModal = ({
  isOpen,
  onClose,
  path,
  file,
  registeredUrl,
  subdir,
  orgHelper,
  accessToken,
}: any) => {
  const warnings = [];
  if (file && file.size > 25 * 1024) {
    warnings.push(' a large file');
  }
  const imageExtensions = [
    'png', 'jpg', 'jpeg', 'gif', 'bmp', 'ico', 'svg', 'webp', 'tiff',
  ];
  const nonConvertibleExtensions = [...imageExtensions, 'html', 'pdf', 'gz', 'zip'];
  const knownExtensions = [
    ...nonConvertibleExtensions,
    'txt', 'run', 'sh', 'md', 'README', 'a', 'b', 'config', 'yaml', 'yml', 'json', 'Snakefile', 'log', 'csv',
  ];
  const extension = file?.name.split('.').at(-1);
  if (!knownExtensions.includes(extension)) {
    warnings.push(' a non-validated extension');
  }
  const confirmationNeeded = !imageExtensions.includes(extension) && warnings.length > 0;
  const [confirmed, setConfirmed] = useState(!confirmationNeeded);
  const orgId = orgHelper.getOrgIds()[0];
  const { data: filePreview } = useQuery(
    ['files', path, file?.name],
    async () => fetchFile({
      registeredUrl,
      orgId,
      subdir,
      path,
      accessToken,
      convertToMessages: !nonConvertibleExtensions.includes(extension),
    }),
    {
      placeholderData: () => [{ message: 'Loading...' }],
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      enabled: isOpen && !!(file?.name) && confirmed,
    },
  );

  function displayWarnings() {
    if (confirmed) {
      return <></>;
    }
    const warningMessage = warnings.join(' and');
    return (
      <Box>
        <Text as='h4' size='md' mb={2}>
          This is
          {warningMessage}
          , are you sure you want to preview this file?
        </Text>
        <Flex mt={2} >
          <Spacer />
          <Button
            onClick={() => setConfirmed(true)}
            colorScheme='black'
            variant='outline'
          >
            Yes, display preview
          </Button>
        </Flex>
      </Box>
    );
  }

  if (imageExtensions.includes(extension)) {
    const isSvg = extension === 'svg';
    const mimeType = isSvg ? 'svg+xml' : extension;
    const encodedFilePreview = isSvg
      ? btoa(filePreview) // may break shared FS svg preview
      : filePreview;

    return (
      <Modal isOpen={isOpen} onClose={onClose} size='5xl' isCentered scrollBehavior='inside'>
        <ModalOverlay bg='blackAlpha.300' backdropFilter='blur(5px)'/>
        <ModalContent>
          <ModalHeader bg='gray.100' borderTopRadius='md'>Preview</ModalHeader>
          <ModalCloseButton mt={2}/>
          <ModalBody pt='1.5rem' pb='1.5rem'>
            <Box width='100%'>
              <>
                {confirmed && filePreview?.length === 1
                  && <Skeleton width='100%' height='10rem' />
                }              {confirmed && filePreview && filePreview.length > 1
                && (<Box>
                  <Image
                    fit='scale-down'
                    src={`data:image/${mimeType};base64, ${encodedFilePreview}`}
                  />
                </Box>)
                }
              </>
            </Box>
          </ModalBody>
        </ModalContent>
      </Modal>
    );
  }

  if (nonConvertibleExtensions.includes(extension)) {
    return (
      <Modal isOpen={isOpen} onClose={onClose} size='5xl' isCentered scrollBehavior='inside'>
        <ModalOverlay bg='blackAlpha.300' backdropFilter='blur(5px)'/>
        <ModalContent>
          <ModalHeader bg='gray.100' borderTopRadius='md'>Preview</ModalHeader>
          <ModalCloseButton mt={2}/>
          <ModalBody pt='1.5rem' pb='1.5rem'>
            <Flex flexDirection='column' >
              <Text fontSize='md'>
                No preview available.
              </Text>
            </Flex>
            <Flex mt={2} >
              <Spacer />
              <DownloadMenuItem
                url={registeredUrl || file?.url}
                subdir={subdir}
                isRegisteredFile={file?.is_registered_file}
                asButton
              />
            </Flex>
          </ModalBody>
        </ModalContent>
      </Modal>
    );
  }

  if (file?.size > 50 * 1024) {
    return (
      <Modal isOpen={isOpen} onClose={onClose} size='5xl' isCentered scrollBehavior='inside'>
        <ModalOverlay bg='blackAlpha.300' backdropFilter='blur(5px)'/>
        <ModalContent>
          <ModalHeader bg='gray.100' borderTopRadius='md'>Preview</ModalHeader>
          <ModalCloseButton mt={2}/>
          <ModalBody pt='1.5rem' pb='1.5rem'>
            <Flex flexDirection='column' >
              <Text fontSize='md'>
                This file is too large to preview.
              </Text>
            </Flex>
          </ModalBody>
        </ModalContent>
      </Modal>
    );
  }

  return (
    <Modal isOpen={isOpen} onClose={onClose} size='5xl' isCentered scrollBehavior='inside'>
      <ModalOverlay bg='blackAlpha.300' backdropFilter='blur(5px)'/>
      <ModalContent>
        <ModalHeader bg='gray.100' borderTopRadius='md'>Preview</ModalHeader>
        <ModalCloseButton mt={2}/>
        <ModalBody pt='1.5rem' pb='1.5rem'>
          <Flex flexDirection='column'>
            {displayWarnings()}
            {confirmed
              && <LogBox
                logs={filePreview}
                id='debug_box'
                customEmptyMessage='Failed to fetch file content'
              />
            }
          </Flex>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default withRequiredAuthInfo(FilePreviewModal);
