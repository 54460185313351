import {
  Button, Icon, MenuItem, useToast,
} from '@chakra-ui/react';
import { withRequiredAuthInfo } from '@propelauth/react';
import { BsBoxArrowInDown } from 'react-icons/bs';
import * as React from 'react';
import { useQuery } from '@tanstack/react-query';

const DownloadMenuItem = ({
  accessToken, orgHelper, isDisabled, url, subdir, isRegisteredFile, asButton,
}: any) => {
  const orgId = orgHelper.getOrgIds()[0];
  const toast = useToast({
    position: 'bottom-right',
    variant: 'subtle',
  });

  function getDownloadUrl() {
    const requestSuffix = isRegisteredFile
      ? 'registered/download-url'
      : 'customer-s3-download-url';
    return fetch(`${process.env.REACT_APP_API_URL}/app/${orgId}/files/${requestSuffix}`, {
      method: 'PUT',
      body: JSON.stringify({ url, object_path: subdir }),
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`,
      },
    });
  }

  const { refetch: fetchDownloadUri, isFetching } = useQuery(
    ['downloadUri', url, subdir],
    async () => {
      const response = await getDownloadUrl();
      if (response.ok) {
        toast({
          title: 'Download started',
          status: 'success',
          duration: 5_000,
          isClosable: true,
        });
        return response.json();
      }
      toast({
        title: 'Failed to generate download',
        status: 'error',
        duration: 5_000,
        isClosable: true,
      });
      throw new Error('Download API request failed');
    },
    {
      enabled: false,
      onSuccess: ({ url: presignedUrl }) => {
        window.open(presignedUrl, '_blank');
      },
    },
  );

  if (asButton) {
    return (
      <Button
        onClick={() => fetchDownloadUri()}
        leftIcon={<Icon as={BsBoxArrowInDown} height='1.4em' width='1.4em'/>}
        isDisabled={isDisabled || isFetching}
        variant='outline'
        colorScheme='blackAlpha'
        textColor='gray.700'
      >
        {isFetching ? 'Starting download...' : 'Download'}
      </Button>
    );
  }

  return (
    <MenuItem
      onClick={() => fetchDownloadUri()}
      icon={<Icon as={BsBoxArrowInDown} height='1.4em' width='1.4em'/>}
      isDisabled={isDisabled || isFetching}
    >
      {isFetching ? 'Starting download...' : 'Download'}
    </MenuItem>
  );
};

export default withRequiredAuthInfo(DownloadMenuItem);
