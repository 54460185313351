import {
  Box, Flex, Text, useToast,
} from '@chakra-ui/react';
import * as React from 'react';
import { createColumnHelper } from '@tanstack/react-table';
import { findIndex, isEmpty } from 'lodash';
import TableEmpty from '../NewFilesTable/TableEmpty';
import { Actions, EditableField } from '../NewFilesTable/ColumnComponents';
import NewFilesTable from '../NewFilesTable/NewFilesTable';
import { LaunchOutputFile } from '../types';
import DataLocationInput from './DataLocationInput';
import UndoToastTitle from './UndoToastTitle';
import NewFilesTableHeader from '../NewFilesTable/NewFilesTableHeader';

const OutputDataSection = ({
  onAdd, onRemove, onEditLabel, outputs,
}: any) => {
  const toast = useToast();

  const undoneToast = () => {
    toast.closeAll();
    toast({
      title: 'Action undone',
      position: 'bottom',
      status: 'success',
      isClosable: true,
    });
  };

  function findExistingPathIndex(path) {
    if (isEmpty(outputs)) {
      return -1;
    }
    return findIndex(outputs, (output: LaunchOutputFile) => output.path === path);
  }

  function onAddWithUndo({ pathValue }) {
    function undoAdd() {
      const pathIndex = findExistingPathIndex(pathValue);
      onRemove(pathIndex);
      undoneToast();
    }
    onAdd({ path: pathValue });
    toast({
      title: <UndoToastTitle title='Added to your chosen output data' undo={undoAdd} />,
      position: 'bottom',
      status: 'success',
      isClosable: true,
    });
  }

  function onRemoveWithUndo({ index, original }) {
    function undoRemove() {
      onAdd({
        ...original,
      });
      undoneToast();
    }
    onRemove(index);
    toast({
      title: <UndoToastTitle title='Removed chosen output data' undo={undoRemove} />,
      position: 'bottom',
      status: 'success',
      isClosable: true,
    });
  }

  function validateOutputPath(value) {
    const isS3 = value.startsWith('s3://');
    const isFd = value.startsWith('fd://');
    if (isS3 || isFd) {
      return 'Output path cannot start with fd:// or s3://';
    }
    if (value.startsWith('/')) {
      return 'Output path cannot start with a slash';
    }
    // Check if already in table
    const existingPath = findExistingPathIndex(value) !== -1;
    if (existingPath) {
      return 'Cannot add two of the same path';
    }
    return '';
  }

  const columnHelper = createColumnHelper<LaunchOutputFile>();
  const columns = [
    columnHelper.accessor('path', {
      cell: (info) => info.getValue(),
      header: () => 'Path',
      size: 50,
    }),
    columnHelper.accessor('label', {
      cell: ({ row }: any) => (
        <EditableField
          defaultValue={row.original.label}
          onSubmit={(newLabel) => onEditLabel({ index: row.index, newLabel })}
        />
      ),
      header: 'Label',
      size: 50,
    }),
    {
      id: 'actions',
      header: '',
      size: 10,
      cell: ({ row }: any) => (
        <Actions
          onRemove={() => onRemoveWithUndo({ index: row.index, original: row.original })}
          hidePreview={true}
        />
      ),
    },
  ];

  return (
    <>
      <Flex
        id='output-data-section'
        display='flex'
        padding='1.25rem 1.5625rem'
        direction='column'
        justify='center'
        align='flex-start'
        gap='1.25rem'
        alignSelf='stretch'
        borderRadius='0.625rem 0.625rem 0rem 0rem'
      >
        <Flex
          id='secondary-header-output'
          direction='column'
          justify='center'
          align='flex-start'
          alignSelf='stretch'
        >
          <Flex
            id='heading-box'
            direction='column'
            justify='center'
            align='flex-start'
            gap='0.75rem'
            alignSelf='stretch'
          >
            <Text variant='text-xl/lineHeight-7/font-semibold'>
              Output data
            </Text>
          </Flex>
          <Flex>
            <Text variant='text-md/lineHeight-6/font-medium' alignSelf='stretch'>
              Choose your output data by typing the data's instance path.
            </Text>
            <Box id='pipeline-settings-output-selection' width='0.1rem' height='0.01rem' />
          </Flex>
        </Flex>
        <Flex
          id='data-selection-box-output'
          direction='column'
          justify='center'
          align='flex-start'
          gap='0.9375rem'
          alignSelf='stretch'
        >
          <Flex
            id='url-box-output'
            direction='column'
            width='100%'
            justify='center'
            align='flex-start'
            gap='1.25rem'
          >
            <Box
              id='url-output-container'
              width='100%'
              pr='0.75rem'
              gap='0.3125rem'
            >
              <DataLocationInput
                onSubmit={onAddWithUndo}
                typeLabel='output'
                placeholder='output/example.txt'
                tooltipText='Relative to where the pipeline is running, where is the output file?'
                validate={validateOutputPath}
                minLength={2}
              />
            </Box>
          </Flex>
        </Flex>
      </Flex>
      <Flex
        id='selected-output-data-section'
        padding='1.25rem 1.5625rem 1.5625rem 1.5625rem'
        direction='column'
        gap='0.625rem'
        alignItems='flex-start'
        justify='center'
        alignSelf='stretch'
      >
        <Flex
          id='secondary-header-output'
          direction='column'
          alignItems='flex-start'
          width='100%'
        >
          <Text variant='text-lg/lineHeight-7/font-medium' mb='0.625rem'>
            Output data chosen
          </Text>
          <NewFilesTable
            idColumn='path'
            hide={outputs?.length === 0}
            columns={columns}
            files={outputs}
            TableHeader={NewFilesTableHeader}
            enableRowSelection={false}
          />
          <TableEmpty
            hide={outputs?.length !== 0}
            headingText='No output data chosen'
            bodyText='The data you choose as your outputs will appear here.'
          />
        </Flex>
      </Flex>
    </>
  );
};

export default OutputDataSection;
