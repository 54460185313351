import * as React from 'react';
import {
  AccordionButton,
  AccordionPanel,
  Box,
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Select,
  Spacer,
  Text,
} from '@chakra-ui/react';
import { ChevronDownIcon, ChevronRightIcon, DeleteIcon } from '@chakra-ui/icons';
import { Field } from 'formik';
import _ from 'lodash';

import FileSelection from './FileSelection';
import { TransferTypes } from './types';

function validateRequiredStringField(value: string, fieldName: string) {
  if (!value) {
    return `${fieldName} must be set`;
  }
  if (value.length < 1) {
    return `${fieldName} must contain at least one character`;
  }
  if (/^\s*$/.test(value)) {
    return `${fieldName} must contain at least one non-whitespace character`;
  }
  if (/^\s|\s$/.test(value)) {
    return `${fieldName} must not start or end with whitespace`;
  }
  return '';
}

function validateSource(value: string) {
  const error = validateRequiredStringField(value, 'Source');
  if (!error && !value.startsWith('s3://') && !value.startsWith('fd://') && !value.startsWith('http')) {
    return 'Source must be an S3 (s3://), HTTPS (https://), or shared file system (fd://) path';
  }
  return error;
}

function validateDestination(value: string) {
  const error = validateRequiredStringField(value, 'Source');
  if (!error && !value.startsWith('s3://') && !value.startsWith('fd://') && !value.startsWith('http')) {
    return 'Destination must be an S3 (s3://) or shared file system (fd://) path';
  }
  return error;
}

function validateFileName(value: string) {
  const error = validateRequiredStringField(value, 'File name');
  if (!error && /.*\/.*/.test(value)) {
    return 'File name must not contain slashes';
  }
  return error;
}

const TransferFormElement = ({
  index, setFieldValue, isExpanded, onRemove, elementValues,
}) => {
  const typeField = `transfers[${index}].type`;
  const sourceField = `transfers[${index}].source`;
  const destinationField = `transfers[${index}].destination`;
  const destinationNameField = `transfers[${index}].destination_name`;
  return (
    <Box alignItems='center' borderLeft='solid 1px' borderRight='solid 1px' borderColor='gray.200'>
      <AccordionButton bg={'white'}>
        {isExpanded ? (
          <ChevronDownIcon fontSize='18px'/>
        ) : (
          <ChevronRightIcon fontSize='18px'/>
        )}
        <Text fontSize='lg' fontWeight={600} ml={1} alignContent='center' pt={2} pb={2}>
          Data #{index + 1}
        </Text>
        <Spacer />
      </AccordionButton>
      <AccordionPanel pb={0} paddingInlineStart={0} paddingInlineEnd={0} p={4}>
        <Field name={typeField} validate={undefined}>
          {({ field, form }) => (
            <FormControl>
              <FormLabel>Transfer type</FormLabel>
              <Select
                width='max-content'
                height='2rem'
                onClick={(e) => e.stopPropagation()}
                onChange={(e) => {
                  setFieldValue(typeField, e.target.value);
                }}
                value={field.value}
                mt={1}
              >
                <option value={TransferTypes.File}>File</option>
                <option value={TransferTypes.Directory}>Directory</option>
              </Select>
              <FormErrorMessage>{form.errors[typeField]}</FormErrorMessage>
            </FormControl>
          )}
        </Field>
        <Box height='1rem' />
        <FileSelection
          label='Copy from (source)'
          fieldName={sourceField}
          description='Where the data is currently located (S3, HTTPS, or shared file system).'
          setFieldValue={setFieldValue}
          isDirOnly={false}
          validate={(validateSource)}
          isRequired
        />
        <Box height='1rem' />
        <FileSelection
          label='Save to (destination)'
          fieldName={destinationField}
          description='Where to move the data (S3 or shared file system).'
          setFieldValue={setFieldValue}
          isDirOnly
          validate={validateDestination}
          isRequired
        />
        <Box height='1rem' />
        {
          elementValues?.type === TransferTypes.File
            && <>
              <Field name={destinationNameField} validate={validateFileName}>
                {({ field, form }) => (
                  <FormControl
                    isInvalid={
                      _.get(form.errors, destinationNameField)
                    && _.get(form.touched, destinationNameField)
                    }
                    isRequired={_.get(form.values, typeField) === TransferTypes.File}
                  >
                    <FormLabel>File name</FormLabel>
                    <Input
                      {...field}
                      placeholder='Enter a file name (e.g. sample.fastq)'
                      value={field.value}
                      onChange={(e) => setFieldValue(destinationNameField, e.target.value)}
                    />
                    <FormErrorMessage>{_.get(form.errors, destinationNameField)}</FormErrorMessage>
                  </FormControl>
                )}
              </Field>
              <Box height='1rem' />
            </>
        }
        <Flex>
          <Spacer />
          <Button
            colorScheme='reds'
            leftIcon={<DeleteIcon />}
            onClick={onRemove}
          >
            Remove
          </Button>
        </Flex>
      </AccordionPanel>
    </Box>
  );
};

export default TransferFormElement;
