import * as React from 'react';
import {
  Alert,
  AlertIcon,
  Button,
  Code,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
} from '@chakra-ui/react';
import { withRequiredAuthInfo } from '@propelauth/react';

const TerminateRunConfirmationModal = ({
  isOpen,
  onClose,
  onSubmit,
  isLoading,
  toolName,
  runId,
  createdAt,
}: any) => (
  <Modal isOpen={isOpen} onClose={onClose} >
    <ModalOverlay bg='blackAlpha.300' backdropFilter='blur(5px)'/>
    <ModalContent>
      <ModalHeader>Terminate {toolName} run</ModalHeader>
      <ModalCloseButton/>
      <ModalBody>
        <b>Are you sure you want to terminate this run?</b><br/>
                    Name: {toolName}<br/>
                    Run ID: <Code>{runId}</Code><br/>
                    Started at: {createdAt}<br/>
        <Alert status='warning' mt='5'>
          <AlertIcon/>
                        Termination is irreversible, and can result in the loss of results.
        </Alert>
      </ModalBody>
      <ModalFooter>
        <Button
          colorScheme='red'
          onClick={onSubmit}
          isLoading={isLoading}
          loadingText="Terminating run..."
        >
                        Terminate
        </Button>
      </ModalFooter>
    </ModalContent>
  </Modal>
);

export default withRequiredAuthInfo(TerminateRunConfirmationModal);
