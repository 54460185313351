import * as React from 'react';
import { useState } from 'react';
import { Field, Formik } from 'formik';
import {
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  HStack,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useToast,
} from '@chakra-ui/react';
import { withRequiredAuthInfo } from '@propelauth/react';
import { useQuery } from '@tanstack/react-query';

async function createDirectory({
  orgId, path, name, accessToken,
}) {
  return fetch(`${process.env.REACT_APP_API_URL}/app/${orgId}/files?path=${encodeURIComponent(path)}`, {
    method: 'PUT',
    body: JSON.stringify({ name }),
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${accessToken}`,
    },
  }).then((response) => {
    if (response.ok) {
      return response.json();
    }
    throw new Error('Network response was not ok');
  });
}
const DirectoryCreationModal = ({
  isOpen,
  onClose,
  originalPath,
  setSearchParams,
  refetchFiles,
  accessToken,
  orgHelper,
}: any) => {
  const orgId = orgHelper.getOrgIds()[0];
  const [createDirName, setCreateDirName] = useState('');
  const toast = useToast();

  const { refetch: createDirRefetch, isFetching: isCreatingDir } = useQuery(
    ['createDir', originalPath],
    async () => {
      try {
        const createDirRes = await createDirectory({
          orgId,
          path: originalPath,
          name: createDirName,
          accessToken,
        });
        if (createDirRes.success) {
          toast({
            title: 'Successfully created folder',
            status: 'success',
            duration: 5_000,
            isClosable: true,
          });
          onClose();
          setSearchParams({ path: `${originalPath}${createDirName}/` });
          await refetchFiles();
        } else {
          toast({
            title: 'Failed to create folder',
            description: createDirRes.error,
            status: 'error',
            duration: 7_000,
            isClosable: true,
          });
        }
        return createDirRes;
      } catch (err) {
        toast({
          title: 'Failed to create folder',
          description: err.message,
          status: 'error',
          duration: 7_000,
          isClosable: true,
        });
        return undefined;
      }
    },
    {
      enabled: false,
    },
  );

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay bg='blackAlpha.300' backdropFilter='blur(5px)'/>
      <ModalContent>
        <ModalHeader>Create new folder</ModalHeader>
        <ModalCloseButton/>
        <Formik
          initialValues={{
            directory_name: null,
          }}
          onSubmit={async (values) => {
            await setCreateDirName(values.directory_name);
            await createDirRefetch();
          }}
          initialErrors={{}}
          enableReinitialize
        >
          {({
            handleSubmit, errors, touched, isValid,
          }: any) => (
            <form onSubmit={handleSubmit}>
              <ModalBody>
                <FormControl
                  isInvalid={
                    (!!errors.directory_name)
                    || (touched.directory_name && !!errors.directory_name)
                  }
                >
                  <FormLabel htmlFor="directory_name">Name</FormLabel>
                  <HStack>
                    <Field
                      as={Input}
                      id="directory_name"
                      name="directory_name"
                      type="text"
                      validate={(value: string) => {
                        if (!value) {
                          return 'Name must not be empty';
                        }
                        if (value.length < 1) {
                          return 'Name must contain at least one character';
                        }
                        if (value.length > 255) {
                          return 'Maximum name length is 255 characters';
                        }
                        if (/^\s*$/.test(value)) {
                          return 'Name must contain at least one non-whitespace character';
                        }
                        if (/^\s|\s$/.test(value)) {
                          return 'Name must not start or end with whitespace';
                        }
                        if (/^.*\/.*$/.test(value)) {
                          return 'Name must not contain slashes';
                        }
                        if (/^.*\.\..*$/.test(value)) {
                          return 'Name must not traverse directories';
                        }
                        return '';
                      }}
                    />
                    <Text>/</Text>
                  </HStack>
                  <FormErrorMessage>{errors.directory_name}</FormErrorMessage>
                </FormControl>
              </ModalBody>
              <ModalFooter>
                <Button
                  type="submit"
                  colorScheme='blue'
                  isDisabled={!touched || !isValid}
                  isLoading={isCreatingDir}
                  loadingText="Creating..."
                >
                  Create
                </Button>
              </ModalFooter>
            </form>
          )}
        </Formik>
      </ModalContent>
    </Modal>
  );
};

export default withRequiredAuthInfo(DirectoryCreationModal);
