import { Flex, Link } from '@chakra-ui/react';
import { withRequiredAuthInfo } from '@propelauth/react';
import * as React from 'react';

const emptyMessage = ({ orgHelper }) => {
  const orgId = orgHelper.getOrgIds()[0];
  return (
    <Flex>
      There's nothing here yet.
      <Link isExternal
        href={`https://github.com/apps/flowdeploy-pipeline-manager/installations/new?state=${orgId}`}
        ml='0.5ch'>
        Connect or manage your GitHub account.
      </Link>
    </Flex>
  );
};
export default withRequiredAuthInfo(emptyMessage);
