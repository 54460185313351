import { useQueryClient } from '@tanstack/react-query';
import { useEffect, Fragment } from 'react';
import {
  Box, Flex, Link, Text,
} from '@chakra-ui/react';
import { ExternalLinkIcon } from '@chakra-ui/icons';
import LinkifyIt from 'linkify-it';

function replaceLinks({ message, index }: any) {
  const linkify = new LinkifyIt();
  const lineUrls = linkify.match(message) || [];
  if (lineUrls.length > 0) {
    const firstMatch = lineUrls[0];
    const urlStart = firstMatch.index;
    const urlEnd = firstMatch.lastIndex;
    const linkTo = firstMatch.url;
    return <Fragment key={index}>
      {message.substring(0, urlStart)}
      <Link color='blues.500' alignItems='center' isExternal={true} href={linkTo}>
        {message.substring(urlStart, urlEnd)}<ExternalLinkIcon ml='1ch' />
      </Link>
      {message.substring(urlEnd)}
    </Fragment>;
  }
  return message;
}

const LogLine = ({ logLine, index }: any) => {
  if (!logLine) {
    return <></>;
  }
  let timePrefix = '';
  if (logLine.date) {
    const time = new Date(logLine.date).toLocaleTimeString(
      'en-US',
      {
        hour: '2-digit', minute: '2-digit', second: '2-digit', timeZoneName: 'short',
      },
    );
    timePrefix = `${time}: `;
  }

  const { message } = logLine;
  const log = replaceLinks({ message: logLine.message, index });

  // nf specific
  let logColor = 'greys.50';
  if (message.includes('ERROR ~')) {
    logColor = 'red.400';
  } else if (message.includes('WARN:')) {
    logColor = 'orange.300';
  }

  return (
    <Flex direction='row' key={index}>
      <Flex
        pr='1ch'
        mr='2ch'
        minWidth='5ch'
        justifyContent='right'
        borderRight='solid 2px'
        borderRightColor='greys.700'
      >
        <Text variant='monospace' color="greys.500">
          {index}
        </Text>
      </Flex>
      <Text
        pr='2ch'
        maxWidth={{
          base: '35ch', sm: '35ch', md: '70ch', lg: '80ch', xl: '200ch',
        }}
        variant='monospace'
        color={logColor}
        whiteSpace='pre-wrap'
      >
        {timePrefix}{log}
      </Text>
    </Flex>
  );
};

export const LogBox = ({ logs, id, customEmptyMessage }: any) => {
  let emptyMessage = customEmptyMessage ?? 'Waiting for logs...';
  if (logs && logs.length === 0) {
    emptyMessage = '(empty)';
  }
  return (
    <Box bg='greys.800' pb={5} pt={5} key={id}>
      <Fragment key={`logbox_${id}`}>
        {logs && logs.length > 0 ? (
          logs.map((logLine: any, index: number) => (
            <LogLine logLine={logLine} index={index} key={index} />
          ))
        ) : (
          <LogLine logLine={{ message: emptyMessage }} index={0} key={0} />
        )
        }
      </Fragment>
    </Box>
  );
};

const RunLogs = ({
  taskId, activeRun, cloudLogs, error,
}: any) => {
  const queryClient = useQueryClient();

  useEffect(() => {
    if (!activeRun) {
      queryClient.invalidateQueries({ queryKey: [`cloudLogs${taskId}`] });
      queryClient.invalidateQueries({ queryKey: [`logFiles${taskId}`] });
    }
  }, [activeRun, queryClient, taskId]);

  const messages = cloudLogs || error;
  return (
    <LogBox logs={messages} />
  );
};

export default RunLogs;
