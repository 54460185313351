import {
  Flex,
  Icon,
  IconButton,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  Spacer,
} from '@chakra-ui/react';
import * as React from 'react';
import { withRequiredAuthInfo } from '@propelauth/react';
import { IoSearchSharp } from 'react-icons/io5';
import { HiXCircle } from 'react-icons/hi';
import { useEffect, useState } from 'react';

const RunsTableHeader = ({
  setGlobalSearchQuery,
}: any) => {
  const [searchQuery, setSearchQuery] = useState('');

  useEffect(() => {
    setGlobalSearchQuery(searchQuery);
  }, [setGlobalSearchQuery, searchQuery]);

  return (
    <>
      <Flex
        id='icons-and-search'
        height='3.4375rem'
        p='0.625rem'
        pl='1.22rem'
        justify='space-between'
        align='center'
        bg='gray.50'
      >
        <></>
        <Spacer/>
        <InputGroup
          id='search-table-group'
          width='16.625rem'
          alignItems='center'
          flexShrink={0}
          borderRadius='0.4375rem'
          size='sm'
        >
          <InputLeftElement justifyContent='center' alignItems='center' width='2rem' height='2rem'>
            <Icon as={IoSearchSharp} color='gray.800' />
          </InputLeftElement>
          <Input
            id='search-input'
            height='2rem'
            padding='0rem 0.75rem 0rem 2rem'
            flexDirection='column'
            alignItems='center'
            justifyContent='center'
            borderRadius='1.25rem'
            border='1px solid'
            borderColor='gray.200'
            bg='white'
            size='sm'
            variant='outline'
            placeholder='Search'
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
          />
          <InputRightElement
            hidden={!searchQuery}
            justifyContent='center'
            alignItems='center'
            width='2rem'
            height='2rem'
            ml='1rem'
          >
            <IconButton
              aria-label='clear search'
              variant='ghost'
              size='sm'
              icon={<Icon as={HiXCircle} color='gray.400'/>}
              onClick={() => setSearchQuery('')}
              _hover={{
                bg: 'transparent',
              }}
            />
          </InputRightElement>
        </InputGroup>
      </Flex>
    </>
  );
};

export default withRequiredAuthInfo(RunsTableHeader);
