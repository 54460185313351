import * as React from 'react';
import {
  Alert,
  AlertIcon,
  Box,
  Button,
  Flex,
  Heading,
  Spacer,
  Text,
} from '@chakra-ui/react';
import { withRequiredAuthInfo } from '@propelauth/react';
import { result } from 'lodash';

import { BsFileEarmarkText, BsFolder } from 'react-icons/bs';
import { DrivesContext } from './DriveProvider';
import DriveTabs from './DataPage/DriveTabs';

const EmbeddedFileSelect = ({
  handleSelect, isDirOnly, isS3Only, isFdOnly, isLocalMultiSelect,
}: any) => {
  const {
    fdMountName,
    dataRepos,
    selectedFileSystem,
    setSelectedFileSystem,
    setSearchParams,
    selectedFiles,
    setSelectedFiles,
    originalPath,
    selectedFileName,
    setSelectedFileName,
  } = React.useContext(DrivesContext);
  const [localSelectedFiles, setLocalSelectedFiles] = React.useState({});
  const requiresS3ButNoneFound = isS3Only && (!dataRepos || dataRepos?.length === 0);
  const shouldHideFiles = requiresS3ButNoneFound;

  // These setStates might cause an issue down the road. todo: handle the parent/child state update better
  if (isS3Only && selectedFileSystem.startsWith('fd://')) {
    if (!requiresS3ButNoneFound) {
      setSelectedFileSystem(dataRepos[0].uri);
    }
  }
  if (isFdOnly && selectedFileSystem.startsWith('s3://')) {
    setSelectedFileSystem(fdMountName);
  }

  const pathFileName = isDirOnly && !selectedFiles[selectedFileName]?.is_dir ? '' : selectedFileName;
  const fullSelectedPath = `${originalPath}${pathFileName}`;

  function onSelect(selectedFile) {
    // Global (URL) selected files
    if (isDirOnly && !selectedFile.is_dir) {
      return;
    }
    setSelectedFileName(selectedFile.name);
    const newSelectedFiles = {
      [selectedFile.name]: {
        ...selectedFile,
      },
    };
    setSelectedFiles(newSelectedFiles);

    // Locally (just state) selected files
    const url = `${originalPath}${selectedFile.name}`;
    setLocalSelectedFiles({
      ...localSelectedFiles,
      [url]: {
        url,
        file: selectedFile,
      },
    });
  }

  function onDeselect(deselectedFile) {
    // Global (URL) selected files
    setSelectedFiles({});
    setSearchParams({ path: originalPath });

    // Locally (just state) selected files
    const updatedLocalSelectedFiles = { ...localSelectedFiles }; // defs a better way to do this
    delete updatedLocalSelectedFiles[deselectedFile.url];
    setLocalSelectedFiles(updatedLocalSelectedFiles);
  }

  return (
    <Box flex='4'>
      {isDirOnly && !requiresS3ButNoneFound
        && <Alert status='info' mb='1.2rem'>
          <AlertIcon/>
            This field allows directories only.
        </Alert>
      }
      {requiresS3ButNoneFound
        && <Alert status='warning' mb='1.2rem'>
          <AlertIcon/>
          <Text>
            This field allows S3 URIs only, but you have no S3 buckets attached. Either enter the URI manually or
            connect an S3 bucket.
          </Text>
        </Alert>
      }
      <DriveTabs
        key={selectedFileSystem}
        selectedFileSystem={selectedFileSystem}
        setSelectedFileSystem={setSelectedFileSystem}
        fdMountName={fdMountName}
        dataRepos={dataRepos}
        selectingConfig={{
          onSelect,
          onDeselect,
          isSelectingDirsOnly: isDirOnly,
          enableMultiRowSelection: isLocalMultiSelect,
        }}
      />
      <Heading as='h5' size='sm' mt={2} hidden={isLocalMultiSelect}>Selected</Heading>
      <Flex alignItems='center' mt={6} mb={4}>
        {!shouldHideFiles && !isLocalMultiSelect
          && <Flex>
            {
              result(selectedFiles[selectedFileName], 'is_dir', true)
                ? <BsFolder height='1.4em' width='1.4em' />
                : <BsFileEarmarkText height='1.4em' width='1.4em' />
            }
            <Box width='100%' ml='0.8ch'>{fullSelectedPath}</Box>
          </Flex>
        }
        <Spacer />
        <Button
          isDisabled={!fullSelectedPath || shouldHideFiles}
          colorScheme='blue'
          onClick={() => {
            if (isLocalMultiSelect) {
              // Local state-only select of multiple files
              handleSelect(localSelectedFiles);
            } else {
              // Global URL-stored select of one file
              handleSelect(fullSelectedPath);
            }
          }}
          flexShrink={0}
        >
        Choose
        </Button>
      </Flex>
    </Box>
  );
};

export default withRequiredAuthInfo(EmbeddedFileSelect);
