import * as React from 'react';
import {
  Alert,
  AlertIcon,
  Button,
  Link,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useToast,
} from '@chakra-ui/react';
import { withRequiredAuthInfo } from '@propelauth/react';
import { useQuery } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';

async function hidePipeline({ orgId, pipelineId, accessToken }) {
  const response = await fetch(`${process.env.REACT_APP_API_URL}/app/${orgId}/pipelines/${pipelineId}/hide`, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${accessToken}`,
    },
  });
  if (response.ok) {
    return true;
  }
  const body = await response.json();
  throw new Error(body.error || 'Error connecting to server');
}

const PipelineHideModal = ({
  pipelineId,
  name,
  lastUsedAt,
  isOpen,
  onClose,
  accessToken,
  orgHelper,
}: any) => {
  const orgId = orgHelper.getOrgIds()[0];
  const toast = useToast();
  const navigate = useNavigate();

  const { refetch, isFetching } = useQuery(
    ['importPipelineFromUrl'],
    async () => {
      try {
        await hidePipeline({
          orgId,
          pipelineId,
          accessToken,
        });
      } catch (err) {
        toast({
          title: 'Failed to hide pipeline',
          description: err.message,
          status: 'error',
          duration: 7_000,
          isClosable: true,
        });
        return {};
      }
      toast({
        title: 'Pipeline hidden',
        status: 'success',
        duration: 5_000,
        isClosable: true,
      });
      onClose();
      navigate('/pipelines');
      return {};
    },
    {
      enabled: false,
    },
  );

  return (
    <Modal isOpen={isOpen} onClose={onClose} size='xl'>
      <ModalOverlay bg='blackAlpha.300' backdropFilter='blur(5px)'/>
      <ModalContent>
        <ModalHeader>Hide {name}</ModalHeader>
        <ModalCloseButton/>
        <ModalBody>
          <Text as='b'>Are you sure you want to hide this pipeline?</Text>
          <Text>Name: {name}</Text>
          <Text>Last used: {lastUsedAt}</Text>
          <Alert mt={4} status='info'>
            <AlertIcon/>
            <Text>
              Hidden pipelines can still be launched programmatically. To disconnect access to this pipeline,
              <Link isExternal ml='0.5ch'
                href={`https://github.com/apps/flowdeploy-pipeline-manager/installations/new?state=${orgId}`}>
                manage your GitHub connection.
              </Link>
            </Text>
          </Alert>
        </ModalBody>
        <ModalFooter>
          <Button
            colorScheme='blue'
            onClick={refetch}
            isLoading={isFetching}
            loadingText="Hiding pipeline..."
          >
            Hide
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default withRequiredAuthInfo(PipelineHideModal);
