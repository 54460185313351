import { createColumnHelper } from '@tanstack/react-table';
import {
  Checkbox, Flex, Icon, Progress,
} from '@chakra-ui/react';
import { GoUnlink } from 'react-icons/go';
import * as React from 'react';
import { useQueries, useQuery } from '@tanstack/react-query';
import { useState } from 'react';
import { withRequiredAuthInfo } from '@propelauth/react';
import withInitialProps from '../../../data/inputOutputSelection/withInitalProps';
import NewFilesTableHeader, { Breadcrumb } from '../../../data/NewFilesTable/NewFilesTableHeader';
import NewFilesTable from '../../../data/NewFilesTable/NewFilesTable';
import { GenericFile } from '../../../data/types';
import { Actions, DirectoryEntry } from '../../../data/NewFilesTable/ColumnComponents';
import formatBytes from '../../../utils';
import { ReactComponent as EmptyDesert } from '../../../images/empty_desert.svg';
import TableEmpty from '../../../data/NewFilesTable/TableEmpty';

async function fetchRunDrive({
  orgId, taskId, accessToken, pathPrefix,
}) {
  return fetch(`${process.env.REACT_APP_API_URL}/app/${orgId}/runs/tes-tasks/${taskId}/drive?path=${pathPrefix}`, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${accessToken}`,
    },
  }).then(async (response) => {
    if (response.ok) {
      return response.json();
    }
    throw new Error('Network response was not ok');
  });
}

const RunDriveSection = ({
  runId, accessToken, orgHelper, isActive,
}: any) => {
  const orgId = orgHelper.getOrgIds()[0];

  const [queryPathPrefix, setQueryPathPrefix] = useState('');
  const [breadcrumbs, setBreadcrumbs] = useState([{ displayName: 'run', to: '' }]);

  const queryKey = ['runDriveFiles', `${runId}/${queryPathPrefix}`];
  const {
    data: runDriveFiles,
    refetch: refetchRunDriveFiles,
    isSuccess,
    error,
  } = useQuery(
    queryKey,
    () => fetchRunDrive({
      orgId,
      taskId: runId,
      accessToken,
      pathPrefix: queryPathPrefix,
    }),
    {
      placeholderData: () => ({
        files: [
          {
            name: <Progress size='xs' isIndeterminate />,
            last_modified: 0,
            is_dir: true,
            size: null,
            not_selectable: true,
          },
        ],
      }),
      staleTime: 60 * 1000,
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      enabled: isActive,
      retry: 1,
    },
  );

  // Prefetch subdirs
  const subdirectories = runDriveFiles?.files?.filter((queryFile) => queryFile.is_dir)
    ?? [];
  useQueries({
    queries: subdirectories.slice(0, 10).map((subdirectory: any) => ({
      queryKey: ['files', `${runId}/${queryPathPrefix}${subdirectory.name}`],
      queryFn: async () => fetchRunDrive({
        orgId,
        taskId: runId,
        accessToken,
        pathPrefix: `${queryPathPrefix}${subdirectory.name}`,
      }),
      enabled: isActive && isSuccess && runDriveFiles?.length > 0,
      staleTime: 60 * 1000,
      refetchOnMount: false,
      refetchOnWindowFocus: false,
    })),
  });

  const runDriveColumnHelper = createColumnHelper<GenericFile>();
  const runDriveColumns = [
    {
      id: 'select',
      header: '',
      cell: ({ row }: any) => (
        <Flex width='100%' align='center' justify='left'>
          <Checkbox
            size='lg'
            isChecked={row.getIsSelected()}
          />
        </Flex>
      ),
    },
    runDriveColumnHelper.accessor('name', {
      cell: (info) => <DirectoryEntry info={info} />,
      header: 'File name',
    }),
    runDriveColumnHelper.accessor('last_modified', {
      cell: (info) => {
        const date = info.getValue() && new Date(info.getValue() * 1000);
        return date
          ? `${date.toLocaleDateString('en-US')} at ${date.toLocaleTimeString('en-US')}`
          : 'N/A';
      },
      header: 'Modified at',
      meta: {
        isNumeric: false,
      },
    }),
    runDriveColumnHelper.accessor('size', {
      cell: (info) => {
        const size = info.row.original.is_dir ? null : info.getValue();
        if (info.row.original.is_alias && !info.row.original.size) {
          return (
            <Icon as={GoUnlink} boxSize='1.2em' color='orange.600'/>
          );
        }
        return formatBytes(size);
      },
      header: 'Size',
    }),
    {
      id: 'actions',
      header: '',
      size: 10,
      cell: ({ row }: any) => (
        <Actions
          enableDownload
          file={row.original}
        />
      ),
    },
  ];

  const onRowClick = (row: GenericFile) => {
    if (row.is_dir) {
      const updatedPath = `${queryPathPrefix}${row.name}/`;
      setBreadcrumbs([...breadcrumbs, { displayName: row.name, to: updatedPath }]);
      setQueryPathPrefix(updatedPath);
      refetchRunDriveFiles().then();
    }
  };

  const showRunDrive = isSuccess && !error;
  const emptyMessage = 'Data may be pending, may have been moved to a retried run, or may have expired. '
  + 'View the run execution panel for more information.';

  return (
    <>
      <TableEmpty
        hide={showRunDrive}
        image={EmptyDesert}
        headingText='Run drive currently unavailable'
        bodyText={emptyMessage}
      />
      <NewFilesTable
        hide={!showRunDrive}
        idColumn='name'
        columns={runDriveColumns}
        onRowClick={onRowClick}
        files={runDriveFiles?.files}
        TableHeader={withInitialProps({
          enableDownload: true,
          enableEditHeader: true,
          breadcrumbConfig: {
            setPath: (breadcrumb: Breadcrumb) => setQueryPathPrefix(breadcrumb.to),
            path: queryPathPrefix,
            breadcrumbs,
            setBreadcrumbs,
          },
        })(NewFilesTableHeader)}
        refetch={refetchRunDriveFiles}
        emptyMessage='This directory in the run drive is empty.'
        enableMultiRowSelection
        isSelecting
      />
    </>
  );
};

export default withRequiredAuthInfo(RunDriveSection);
