import * as React from 'react';
import {
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  Input,
  InputGroup,
  InputLeftElement,
  useDisclosure,
} from '@chakra-ui/react';
import { Field } from 'formik';
import { get } from 'lodash';
import { BsBucket, BsFolder } from 'react-icons/bs';

import FileSelectModal from './FileSelectModal';

type FileSelectionProps = {
  label: string;
  fieldName: string;
  // eslint-disable-next-line @typescript-eslint/ban-types
  setFieldValue: Function; // TODO: define function shape and remove ignore
  description: string;
  isDirOnly?: boolean;
  isS3Only?: boolean;
  isFdOnly?: boolean;
  // eslint-disable-next-line @typescript-eslint/ban-types
  validate?: Function; // TODO: define function shape and remove ignore
  isRequired?: boolean;
}

const FileSelection = ({
  label, fieldName, setFieldValue, description, isDirOnly, isS3Only, isFdOnly, validate, isRequired,
}: FileSelectionProps) => {
  const { isOpen: isSelectOpen, onOpen: onSelectOpen, onClose: onSelectClose } = useDisclosure();
  return (
    <Field name={fieldName} validate={validate || undefined}>
      {({ field, form }) => (
        <FormControl
          isInvalid={get(form.errors, fieldName) && get(form.touched, fieldName) && !isSelectOpen}
          isRequired={isRequired}
        >
          {label && <FormLabel color='greys.900'>{label}</FormLabel>}
          <Flex>
            <InputGroup>
              <InputLeftElement width='7.5rem'>
                <Button
                  variant='ghost'
                  leftIcon={
                    isS3Only
                      ? <BsBucket height='1.4em' width='1.4em' />
                      : <BsFolder height='1.4em' width='1.4em' />
                  }
                  onClick={onSelectOpen}
                  borderRight='solid 2px'
                  borderRadius={0}
                  borderColor='translucent.200'
                >
                    Browse
                </Button>
              </InputLeftElement>
              <Input
                { ...field }
                pl='8rem'
                placeholder='Type a path or use the file browser'
                value={field && field.value}
                onChange={(e) => setFieldValue(fieldName, e.target.value)}
              />
            </InputGroup>
            <FileSelectModal
              isOpen={isSelectOpen}
              onClose={onSelectClose}
              handleSelect={(selectedPath) => setFieldValue(fieldName, selectedPath)}
              isDirOnly={isDirOnly}
              isFdOnly={isFdOnly}
              isS3Only={isS3Only}
            />
          </Flex>
          <FormErrorMessage>{get(form.errors, fieldName)}</FormErrorMessage>
          <FormHelperText>{description}</FormHelperText>
        </FormControl>
      )}
    </Field>
  );
};

export default FileSelection;
