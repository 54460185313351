import {
  Flex, Icon, Link, Tab, TabList, TabPanel, TabPanels, Tabs, Text,
} from '@chakra-ui/react';
import { BsFolder } from 'react-icons/bs';
import { IoServerOutline } from 'react-icons/io5';
import { useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import { withRequiredAuthInfo } from '@propelauth/react';
import SectionBox from '../../../SectionBox';
import { GenericFile } from '../../../data/types';
import RunDriveSection from './RunDriveSection';
import RegisteredFileSection from './RegisteredFileSection';

type RunData = {
  inputs: Array<GenericFile>,
  outputs: Array<GenericFile>,
}

type RunDataSectionProps = {
  runId: string,
  hidden?: boolean,
  accessToken: string,
  orgHelper: any,
}

const RunDataSection = ({
  runId, hidden, accessToken, orgHelper,
}: RunDataSectionProps) => {
  if (hidden || !runId) {
    return (<></>);
  }

  // Outputs / inputs / run drive tab
  const [tabIndex, setTabIndex] = useState(0);
  function openRunDrive() {
    setTabIndex(2);
  }

  const orgId = orgHelper.getOrgIds()[0];
  const {
    data: runData,
  } = useQuery<RunData>(
    ['runData', runId],
    async () => {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/app/${orgId}/runs/tes-tasks/${runId}/data`, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${accessToken}`,
        },
      });
      if (response.ok) {
        return response.json();
      }
      throw new Error('Network response was not ok');
    },
    {
      refetchOnMount: true,
      refetchOnWindowFocus: false,
      refetchInterval: () => 30 * 1000,
    },
  );

  const emptyOutputsMessage = (
    <Flex>
      <Text>
        This section will populate with the output data specified at launch. If you missed anything, search and
        copy it from your{' '}
        <Link onClick={() => openRunDrive()} colorScheme='blue'>run drive</Link>
        {' '}to save it here.
      </Text>
    </Flex>
  );

  return (
    <SectionBox header='Run data' isFormatted>
      <Tabs
        isFitted
        variant='sectionbox'
        size='lg'
        index={tabIndex}
        onChange={setTabIndex}
      >
        <TabList
          mb='1em'
        >
          <Tab>
            <Icon as={BsFolder} height='1.5rem' width='1.5rem'/>
            <Text variant='text-lg/lineHeight-7/font-semibold' ml='0.5rem'>Outputs</Text>
          </Tab>
          <Tab>
            <Icon as={BsFolder} height='1.5rem' width='1.5rem'/>
            <Text variant='text-lg/lineHeight-7/font-semibold' ml='0.5rem'>Inputs</Text>
          </Tab>
          <Tab>
            <Icon as={IoServerOutline} height='1.5rem' width='1.5rem'/>
            <Text variant='text-lg/lineHeight-7/font-semibold' ml='0.5rem'>Run drive</Text>
          </Tab>
        </TabList>
        <TabPanels>
          <TabPanel>
            <RegisteredFileSection
              files={runData?.outputs}
              emptyStateConfig={{
                heading: 'The pipeline hasn’t uploaded your output data yet',
                body: emptyOutputsMessage,
              }}
              openRunDrive={() => setTabIndex(2)}
              rootFileLabel='Outputs'
            />
          </TabPanel>
          <TabPanel>
            <RegisteredFileSection
              files={runData?.inputs}
              emptyStateConfig={{
                heading: 'This pipeline has no inputs',
                body: 'Inputs are added when launching a pipeline. There\'s nothing to do here!',
              }}
              openRunDrive={() => setTabIndex(2)}
              rootFileLabel='Inputs'
            />
          </TabPanel>
          <TabPanel>
            <RunDriveSection
              runId={runId}
              isActive={tabIndex === 2}
            />
          </TabPanel>
        </TabPanels>
      </Tabs>
    </SectionBox>
  );
};

export default withRequiredAuthInfo(RunDataSection);
