import * as React from 'react';
import {
  Button, Flex, Heading, HStack, Icon, IconButton, Text, useDisclosure, useToast,
} from '@chakra-ui/react';
import { GoOrganization } from 'react-icons/go';
import { DeleteIcon } from '@chakra-ui/icons';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { withRequiredAuthInfo } from '@propelauth/react';
import DeleteTemplateConfirmationModal from './PipelineSelection/DeleteTemplateConfirmationModal';

const PipelineCard = ({
  result, onClick, orgHelper, accessToken,
}: any) => {
  if (!result) {
    return <></>;
  }
  const queryClient = useQueryClient();
  const orgId = orgHelper.getOrgIds()[0];
  const toast = useToast();
  const { isOpen: isDeleteOpen, onOpen: onDeleteOpen, onClose: onDeleteClose } = useDisclosure();
  const { isFetching: isDeleteLoading, refetch: deleteTemplate } = useQuery(
    ['deleteTemplate', result.name],
    async () => {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/app/${orgId}/templates/${result.id}`,
        {
          method: 'DELETE',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${accessToken}`,
          },
        },
      );
      if (!response.ok) {
        const body = await response.json();
        throw new Error(body.error || 'Error connecting to server');
      }
      queryClient.invalidateQueries({ queryKey: ['templatesKey'] });
      return response.json();
    },
    {
      enabled: false,
    },
  );

  const onDeleteSubmit = async () => {
    const response = await deleteTemplate();
    if (response.isSuccess) {
      toast({
        title: 'Template deleted',
        description: `Deleted ${result.name}`,
        status: 'success',
        duration: 5_000,
        isClosable: true,
      });
      onDeleteClose();
    } else {
      toast({
        title: 'Failed to delete template',
        description: `${response.failureReason}`,
        status: 'error',
        duration: 7_000,
        isClosable: true,
      });
    }
  };

  return (
    <Flex
      borderWidth="2px" borderColor="translucent.200" borderRadius='15px'
      height='8rem'
      position='relative'
      direction='column'
      _hover={{
        cursor: 'pointer',
        textDecoration: 'underline',
      }}
    >
      <Flex
        position='absolute'
        top={0}
        bottom={0}
        left={0}
        right={0}
        display="flex"
        alignItems="center"
        justifyContent="center"
        opacity={0}
        backgroundColor="rgba(255, 255, 255, 0.8)"
        backdropFilter="blur(5px)"
        transition="opacity 0.3s"
        zIndex={1}
        _hover={{
          opacity: 1,
        }}
      >
        <Button
          onClick={async () => {
            await onClick(result);
          }}
          position='absolute'
          alignItems="center"
          justifyContent="center"
          colorScheme='blue'
        >
          {`Choose "${result.name}"`}
        </Button>
        <IconButton
          aria-label={`delete ${result.name} template`}
          onClick={onDeleteOpen}
          icon={<DeleteIcon />}
          colorScheme='reds'
          alignSelf='flex-start'
          mt={3}
          ml='85%'
          position='absolute'
          variant='ghost'
        />
        <DeleteTemplateConfirmationModal
          isOpen={isDeleteOpen}
          onClose={onDeleteClose}
          isLoading={isDeleteLoading}
          onSubmit={onDeleteSubmit}
          templateName={result.name}
          templateDescription={result.description}
        />
      </Flex>
      <Flex
        top={0}
        bottom={0}
        left={0}
        right={0}
        position='absolute'
        direction='column'
        pt={3}
        pl={4}
        pr={4}
      >
        <Flex justify='space-between'>
          <HStack>
            {result.imported && <Icon as={GoOrganization} fill='blues.700' />}
            <Heading
              as='h3'
              fontWeight='500'
              fontSize='20px'
              color='blues.700'
              _hover={{
                cursor: 'pointer',
                textDecoration: 'underline',
              }}
              onClick={async () => {
                await onClick(result);
              }}
            >
              {result.name}
            </Heading>
          </HStack>
        </Flex>
        <Text variant='secondary' mt={0.5} fontSize='sm'>
          {result.template.pipeline}
        </Text>
        <Text mt={1} mb={1} noOfLines={2}>
          {result.description}
        </Text>
      </Flex>
    </Flex>
  );
};

export default withRequiredAuthInfo(PipelineCard);
