import {
  Box,
  Flex,
  Heading,
  useToast,
} from '@chakra-ui/react';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { Formik } from 'formik';
import { useNavigate, createSearchParams } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import { withRequiredAuthInfo } from '@propelauth/react';

import { runTypes } from '../constants';
import TransferDetailsForm from './TransferDetailsForm';

async function launchTransfer({ orgId, accessToken, formValues }) {
  const response = await fetch(`${process.env.REACT_APP_API_URL}/app/${orgId}/transfers`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${accessToken}`,
    },
    body: JSON.stringify(formValues),
  });
  if (response.ok) {
    return response.json();
  }
  const body = await response.json();
  throw new Error(body.error || 'Error connecting to server');
}

const TransferPage = ({ accessToken, orgHelper }: any) => {
  const orgId = orgHelper.getOrgIds()[0];
  const [finalFormValues, setFinalFormValues] = useState({});
  const toast = useToast();
  const navigate = useNavigate();

  const initialTransferValues = {
    transfers: [{
      source: '',
      destination: '',
      destination_name: null,
      type: 'file',
    }],
  };

  const {
    data, refetch: fetchLaunchTransfer, isSuccess, isFetching, isError, failureReason,
  } = useQuery(
    ['launchTransfer', finalFormValues],
    () => launchTransfer({
      orgId,
      accessToken,
      formValues: finalFormValues,
    }),
    {
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      enabled: false,
      retry: false,
    },
  );

  // Redirect on successful start
  useEffect(() => {
    if (isFetching) {
      return;
    }
    if (isSuccess && data) {
      toast({
        title: 'Transfer started!',
        status: 'success',
        duration: 5_000,
        isClosable: true,
      });
      navigate(
        {
          pathname: `/dashboard/${data.task_id}`,
          search: createSearchParams({
            runType: runTypes.TES.toString(),
          }).toString(),
        },
        {
          state: { runSummary: { id: data.task_id }, showConfetti: true },
        },
      );
    } else if (isError) {
      toast({
        title: 'Failed to start transfer',
        description: `${failureReason}`,
        status: 'error',
        isClosable: true,
      });
    }
  }, [data, isSuccess, isError, failureReason, navigate, toast, isFetching]);

  async function onSubmit(formikValues) {
    await setFinalFormValues(formikValues);
    await fetchLaunchTransfer();
  }

  return (
    <Box flex='4'>
      <Flex mt={4} mb={4}>
        <Heading as='h2' size='xl'>
          Import and export files
        </Heading>
      </Flex>
      <Formik
        initialValues={initialTransferValues}
        onSubmit={async (values) => {
          await onSubmit({
            ...values,
          });
        }}
        enableReinitialize
      >
        {({
          handleSubmit, errors, touched, isValid, setFieldValue, values,
        }: any) => (
          <form onSubmit={handleSubmit}>
            <TransferDetailsForm
              values={values}
              errors={errors}
              setFieldValue={setFieldValue}
              touched={touched}
              isValid={isValid}
              isFetching={isFetching}
            />
          </form>
        )}
      </Formik>
    </Box>
  );
};

export default withRequiredAuthInfo(TransferPage);
