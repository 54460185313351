import { ChakraProvider } from '@chakra-ui/react';
import * as React from 'react';
import {
  createBrowserRouter,
  createRoutesFromElements,
  RouterProvider,
  Route,
  useNavigate,
  Outlet,
} from 'react-router-dom';
import * as ReactDOM from 'react-dom/client';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { useEffect } from 'react';
import { RequiredAuthProvider, RedirectToLogin, useAuthInfo } from '@propelauth/react';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import { Provider as FrigadeProvider } from '@frigade/react';
// "posthog-js" includes posthog-js/react, not sure how to fix this
// eslint-disable-next-line import/no-extraneous-dependencies
import { PostHogProvider } from 'posthog-js/react';
import Cohere from 'cohere-js';

import App from './App';
import ErrorPage from './Error';
import BillingPage from './BillingPage';
import DataPage from './data/DataPage';
import DriveProvider from './data/DriveProvider';
import TransferPage from './data/TransferPage';
import DeveloperSettingsPage from './developerSettings/DeveloperSettingsPage';
import DashboardPage from './DashboardPage';
import GithubConnectPage from './pipelines/GithubConnectPage';
import RunDetailPage from './runs/details/RunDetailPage';
import SettingsPage from './SettingsPage';
import PipelinesPage from './pipelines/PipelinesPage';
import PipelinesLaunchPage from './pipelines/launch/LaunchPage';
import PipelinesSettingsPage from './pipelines/SettingsPage';
import UniversalDetailPage from './pipelines/UniversalDetailPage';
import reportWebVitals from './reportWebVitals';
import * as serviceWorker from './serviceWorker';
import theme from './theme';
import PipelineDetailsPage from './pipelines/PipelineDetailsPage';

Sentry.init({
  dsn: process.env.REACT_APP_DEPLOY_ENVIRONMENT === 'development'
    ? undefined : 'https://e4c6eae7946c4a068a14362cde1bab0e@o1171636.ingest.sentry.io/4504204551716864',
  environment: process.env.REACT_APP_DEPLOY_ENVIRONMENT || 'production',
  integrations: [new BrowserTracing()],
  tracesSampleRate: process.env.REACT_APP_DEPLOY_ENVIRONMENT === 'production' ? 1.0 : 0.0,
});

Cohere.init(process.env.REACT_APP_COHERE_KEY || 'XirLhMV4Qsknxc67L_F83atN');

const posthogOptions = {
  api_host: process.env.REACT_APP_PUBLIC_POSTHOG_HOST,
  session_recording: {
    maskAllInputs: false,
    maskInputOptions: {
      password: true,
    },
  },
};

const FRIGADE_THEME_OVERRIDES = {
  colors: {
    primary: {
      border: '#3182CE', // Chakra blue.500
      surface: '#3182CE',
      hover: {
        border: '#2B6CB0', // Chakra blue.600
        surface: '#2B6CB0',
      },
    },
  },
};

const container = document.getElementById('root');
if (!container) throw new Error('Failed to find the root element');
const root = ReactDOM.createRoot(container);
const client = new QueryClient();

const Redirects = ():any => {
  const navigate = useNavigate();
  useEffect(() => {
    navigate('pipelines/launch');
  });
  return (<div />);
};

const RouteBox = () => (
  <>
    <Outlet />
  </>
);

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route path="/" element={<App />} errorElement={<ErrorPage />}>
      <Route index element={<Redirects />} />
      <Route path="dashboard" element={<RouteBox />} handle={{ crumb: () => 'Dashboard' }}>
        <Route index element={<DashboardPage />} />
        <Route path=":id" element={<RunDetailPage />} handle={{ crumb: () => 'Detail' }} />
      </Route>
      <Route
        path="developer-settings" element={<DeveloperSettingsPage />} handle={{ crumb: () => 'Developer settings' }}
      />
      <Route path="data" element={<RouteBox />} handle={{ crumb: () => 'Data' }}>
        <Route index element={<DriveProvider><DataPage /></DriveProvider>} />
        <Route path="transfer" element={<TransferPage />} handle={{ crumb: () => 'Transfer' }} />
      </Route>
      <Route path="pipelines" element={<RouteBox />} handle={{ crumb: () => 'Pipelines' }}>
        <Route index element={<PipelinesPage />} />
        <Route path=":id" element={<PipelineDetailsPage />} handle={{ crumb: () => 'Detail' }} />
        <Route path="connect" element={<GithubConnectPage />} />
        <Route path="settings" element={<PipelinesSettingsPage />} handle={{ crumb: () => 'Settings' }} />
        <Route path="launch" element={<PipelinesLaunchPage />} handle={{ crumb: () => 'Launch' }} />
        <Route path="settings/:id" element={<UniversalDetailPage />} handle={{ crumb: () => 'Flow detail' }} />
      </Route>
      <Route path="settings" element={<SettingsPage />} handle={{ crumb: () => 'Account settings' }} />
      <Route path="billing" element={<RouteBox />} handle={{ crumb: () => 'Billing' }}>
        <Route index element={<BillingPage />} />
      </Route>
    </Route>,
  ),
);

const Index = () => {
  const authInfo = useAuthInfo();
  const userId = authInfo.isLoggedIn
    ? authInfo.user.userId
    : 'logged-out-user';

  return (
    <FrigadeProvider
      apiKey={process.env.REACT_APP_FRIGADE_KEY}
      theme={FRIGADE_THEME_OVERRIDES}
      userId={userId}
    >
      <ChakraProvider theme={theme} cssVarsRoot='body'>
        <RouterProvider router={router} />
      </ChakraProvider>
    </FrigadeProvider>
  );
};

root.render(
  <QueryClientProvider client={client}>
    <PostHogProvider
      apiKey={process.env.REACT_APP_PUBLIC_POSTHOG_KEY}
      options={posthogOptions}
    >
      <RequiredAuthProvider
        authUrl={process.env.REACT_APP_AUTH_URL}
        displayIfLoggedOut={<RedirectToLogin postLoginRedirectUrl={process.env.REACT_APP_DEPLOY_URL}/>}
      >
        <Index />
      </RequiredAuthProvider>
      <ReactQueryDevtools initialIsOpen={false} position='bottom-right' />
    </PostHogProvider>
  </QueryClientProvider>,
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorker.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
