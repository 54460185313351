import * as React from 'react';
import {
  Button,
  Flex,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Text,
  useToast,
  Link,
} from '@chakra-ui/react';
import { CopyIcon } from '@chakra-ui/icons';

const SetupScriptModal = ({ isOpen, onClose, setupScript }: any) => {
  const toast = useToast();
  const command = setupScript ? `sudo python3 -c "$(wget '${setupScript.presigned_url}' -O -)" $(whoami)` : '';
  return (
    <Modal isOpen={isOpen} onClose={onClose} size="2xl">
      <ModalOverlay bg='blackAlpha.300' backdropFilter='blur(5px)'/>
      <ModalContent>
        <ModalHeader>Connect a device to FlowDeploy</ModalHeader>
        <ModalCloseButton/>
        <ModalBody>
          <Flex>
            <Text>
                      Run the
            </Text>
            <Link
              href='https://flowdeploy.com/docs/reference/automated-device-connection'
              isExternal
              ml='0.5ch'
              mr='0.5ch'
            >
                      setup command
            </Link>
            <Text>
                      in your terminal:
            </Text>
          </Flex>
          <Flex
            maxWidth='100%'
            borderRadius={8}
            flexDirection='row'
            alignItems='center'
            bg="greys.800"
            mt={4}
            mb={4}
            onClick={(e) => {
              e.stopPropagation();
              navigator.clipboard.writeText(command)
                .then(() => toast({
                  title: 'Copied to clipboard',
                  status: 'success',
                  duration: 5_000,
                  isClosable: true,
                }));
            }}
            _hover={{
              cursor: 'pointer',
              bg: 'greys.700',
            }}
            p='2%'
          >
            <CopyIcon boxSize={5} color='blues.50' mr='2%'/>
            <Text maxWidth='96%' variant='monospace'>
              {command}
            </Text>
          </Flex>
          <Text>
                      After the script finishes, restart your terminal to complete the setup.
          </Text>
        </ModalBody>
        <ModalFooter>
          <Button colorScheme='blue' onClick={() => {
            onClose();
          }}>
                        Close
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default SetupScriptModal;
