import * as React from 'react';
import { useEffect, useState } from 'react';
import {
  Box,
  Button,
  Flex,
  Link,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Spacer,
} from '@chakra-ui/react';
import { withRequiredAuthInfo } from '@propelauth/react';
import Cohere from 'cohere-js';
import { usePostHog, useFeatureFlagEnabled } from 'posthog-js/react';
import { Link as RouterLink, Outlet } from 'react-router-dom';
import {
  PhoneIcon, QuestionIcon, Search2Icon,
} from '@chakra-ui/icons';
import { useQuery } from '@tanstack/react-query';

import AlertBar from './AlertBar';
import Sidebar from './Sidebar';
import { ReactComponent as FDIcon } from './images/fd_icon.svg';
import Breadcrumbs from './Breadcrumbs';
import { UserContext } from './UserContext';
import UserProfile from './UserProfile';

async function fetchOrgInfo({
  orgId, accessToken, useSingleInstance, setOrgData,
}) {
  const response = await fetch(`${process.env.REACT_APP_API_URL}/app/${orgId}/`, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${accessToken}`,
    },
  });
  if (response.ok) {
    const data = await response.json();
    setOrgData({
      ...data,
      is_single_instance: useSingleInstance, // hacking this feature flag into the user context to reduce flicker
    });
    return data;
  }
  throw new Error('Network response was not ok');
}

const App = ({
  orgHelper, accessToken, isLoggedIn, user,
}: any) => {
  const [orgData, setOrgData] = useState({
    billing_portal_url: null,
    is_trialing: false,
    remote_mount_name: '',
    team_name: '',
    is_single_instance: false,
  });
  const orgId = orgHelper.getOrgIds()[0];

  // Sidebar attributes
  const [isCollapsed, setCollapsed] = useState(localStorage.getItem('isCollapsed') === 'true');
  const [forceOpen, setForceOpen] = useState(localStorage.getItem('forceOpen') === 'true');

  // Set up PostHog
  const posthog = usePostHog();
  // Workaround for React replacing search params before PostHog can process them
  // See https://posthog.com/docs/toolbar#toolbar-not-loading-or-displaying
  const toolbarJSON = new URLSearchParams(window.location.hash.substring(1)).get('__posthog');
  if (toolbarJSON) {
    posthog.loadToolbar(JSON.parse(toolbarJSON));
  }
  useEffect(() => {
    if (isLoggedIn && process.env.REACT_APP_DEPLOY_ENVIRONMENT === 'production') {
      Cohere.identify(
        user.userId,
        {
          displayName: `${user.firstName} ${user.lastName}`,
          email: user.email,
        },
      );
      posthog.identify(
        user.userId, // PropelAuth user ID
        {
          email: user.email,
          name: `${user.firstName} ${user.lastName}`,
        },
      );
    }
  }, [posthog, isLoggedIn, Cohere, process.env.REACT_APP_DEPLOY_ENVIRONMENT, user]);
  const useSingleInstance = !useFeatureFlagEnabled('use_parallel_launch_only');

  // Get base org info settings
  const { refetch: refetchOrgInfo } = useQuery(
    ['orgInfo', useSingleInstance],
    async () => fetchOrgInfo({
      orgId, accessToken, useSingleInstance, setOrgData,
    }),
    {
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      staleTime: 24 * 60 * 60 * 1000,
    },
  );

  // Refresh org info as needed
  useEffect(() => {
    if (orgData.remote_mount_name === '') {
      refetchOrgInfo().then();
    }
  }, [orgData, refetchOrgInfo]);

  // Track window width, as used for the sidebar collapse
  const [width, setWidth] = useState(window.innerWidth);
  useEffect(() => {
    function handleResize() {
      setWidth(window.innerWidth);
    }
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [width]);

  // Collapse and uncollapse sidebar as needed
  const maxWidth = 1330;
  useEffect(() => {
    if (width < maxWidth && forceOpen) {
      setCollapsed(true);
    } else {
      setCollapsed(localStorage.getItem('isCollapsed') === 'true');
    }
  }, [width]);

  const [heightCalculation, setHeightCalculation] = useState('calc(100vh - max(64px, 4.5vh) - max(64px, 4.5vh))');

  return (
    <UserContext.Provider value={{ orgData }} key={`rerenders_on_${useSingleInstance}`}>
      <Box height='100vh' display='flow-root'>
        <AlertBar setHeightCalculation={setHeightCalculation}/>
        {/* Top bar */}
        <Flex
          bg='#fdfdfd'
          height='4.5vh'
          minHeight='64px'
          pl='2rem'
          pr='2rem'
          alignItems='center'
          borderBottom='solid 2px'
          borderColor='gray.100'
          position='sticky'
          top={0}
          zIndex={1}
        >
          <Flex height='100%' alignItems='center' as={RouterLink} to='/dashboard'>
            <FDIcon height='50%'/>
          </Flex>
          <Breadcrumbs/>
          <Spacer/>
          <Menu matchWidth>
            <MenuButton as={Button} variant='ghost' leftIcon={<QuestionIcon/>} textColor='greys.700'>Help &
                support</MenuButton>
            <MenuList>
              <MenuItem as={Link} href='https://learn.flowdeploy.com/' isExternal>
                <Search2Icon mr='0.7ch'/> Docs
              </MenuItem>
              <MenuItem
                as={Link}
                href='https://calendly.com/lebovic/15min'
                isExternal
              >
                <PhoneIcon mr='0.7ch'/> Schedule a call
              </MenuItem>
            </MenuList>
          </Menu>
          <UserProfile/>
        </Flex>
        <Flex ml="2rem" direction='row'>
          <Sidebar
            isCollapsed={isCollapsed}
            setCollapsed={setCollapsed}
            setForceOpen={setForceOpen}
            width={width}
            maxWidth={maxWidth}
            heightCalculation={heightCalculation}
          />
          {/* Content */}
          <Box ml="2rem" pr="2rem" pt="2rem" width='100vw' height={heightCalculation} overflow='auto'>
            <Outlet/>
          </Box>
        </Flex>
      </Box>
    </UserContext.Provider>
  );
};

export default withRequiredAuthInfo(App);
