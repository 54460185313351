import {
  Box,
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel, Menu,
  MenuButton, MenuItemOption,
  MenuList, MenuOptionGroup,
  Radio,
  RadioGroup,
  Select,
  Stack,
  Text,
} from '@chakra-ui/react';
import { Field } from 'formik';
import * as React from 'react';
import { ChevronDownIcon } from '@chakra-ui/icons';
import { map } from 'lodash';
import { useContext } from 'react';
import SectionBox from '../../../SectionBox';
import { instanceTypes } from '../../../constants';
import { UserContext } from '../../../UserContext';

const SectionLabel = ({ title, subtitle }) => (
  <Flex
    direction='column'
    justify='center'
    align='flex-start'
    alignSelf='stretch'
  >
    <Flex
      direction='column'
      justify='center'
      align='flex-start'
      gap='0.75rem'
      alignSelf='stretch'
    >
      <Text variant='text-xl/lineHeight-7/font-semibold'>
        {title}
      </Text>
    </Flex>
    <Text variant='text-md/lineHeight-6/font-medium' alignSelf='stretch'>
      {subtitle}
    </Text>
  </Flex>
);

const InstanceType = ({ name }) => {
  const instance = instanceTypes[name];
  if (!instance) {
    return <></>;
  }
  return (
    <Flex padding='0.375rem 0.75rem' align='center' justify='left' flexDirection='row'>
      <Box id='two-lines'>
        <Text variant='text-md/lineHeight-6/font-semibold' align='left'>
          {instance.name}
        </Text>
        <Flex align='flex-start' justify='flex-start'>
          <Text variant='text-md/lineHeight-6/font-normal' color='gray.500' align='left'>
            {instance.vcpus}{' vCPUs'}
          </Text>
          <Text ml='1.1rem' variant='text-md/lineHeight-6/font-normal' color='gray.500' align='left'>
            {instance.memory}{' GB Memory'}
          </Text>
        </Flex>
      </Box>
    </Flex>
  );
};

const VersionInstanceSelection = ({
  setFieldValue, versions, isPlaceholderData, values, selectedPipeline,
}) => {
  const { orgData } = useContext(UserContext);
  const useSingleInstanceLaunch = orgData.is_single_instance;

  const versionsForType = values.use_git_release
    ? versions?.releases
    : versions?.branches;

  const headerText = useSingleInstanceLaunch
    ? 'Version and instance type'
    : 'Version selection';

  return (
    <SectionBox header={headerText}>
      <Box padding='0.66rem 1.5rem 1.25rem'>
        <SectionLabel
          title='Pipeline version'
          subtitle={`Select your version for '${selectedPipeline}'. You can use a release version or a Git branch.`}
        />
        <Flex mt='1.25rem'>
          <Box>
            <Field name='use_git_release' validate={undefined}>
              {({ field, form }) => (
                <FormControl isRequired isInvalid={form.errors.use_git_release && form.touched.use_git_release}>
                  <FormLabel>Find version using</FormLabel>
                  <Box
                    border='1px solid'
                    borderColor='gray.200'
                    borderRadius='0.625rem'
                    p='0.5rem 0.75rem'
                  >
                    <RadioGroup
                      width='max-content'
                      onClick={(e) => e.stopPropagation()}
                      onChange={(value) => {
                        const isRelease = value === 'release';
                        setFieldValue('use_git_release', isRelease);
                        setFieldValue(
                          'pipeline_version',
                          isRelease ? versions.releases[0].name : versions.branches[0].name,
                        );
                      }}
                      value={field.value ? 'release' : 'branch'}
                      isDisabled={isPlaceholderData}
                    >
                      <Stack direction='column'>
                        <Radio
                          isDisabled={versions?.releases.length === 0}
                          value='release'
                        >
                          Release
                        </Radio>
                        <Radio
                          isDisabled={versions?.branches.length === 0}
                          value='branch'
                        >
                          Branch
                        </Radio>
                      </Stack>
                    </RadioGroup>
                  </Box>
                  <FormErrorMessage>{form.errors.use_git_release}</FormErrorMessage>
                </FormControl>
              )}
            </Field>
          </Box>
          <Box ml='1.88rem'>
            <Field name='pipeline_version' validate={undefined}>
              {({ field, form }) => (
                <FormControl isRequired isInvalid={form.errors.pipeline_version && form.touched.pipeline_version}>
                  <FormLabel>Version</FormLabel>
                  <Select
                    width='max-content'
                    height='2rem'
                    onClick={(e) => e.stopPropagation()}
                    mt={1}
                    value={field.value}
                    onChange={(e) => setFieldValue('pipeline_version', e.target.value)}
                  >
                    {versionsForType.map(
                      (version) => <option
                        style={{ color: 'blue' }}
                        key={version.name}
                        value={version.name}
                      >
                        {version.name}
                      </option>,
                    )}
                  </Select>
                  <FormErrorMessage>{form.errors.pipeline_version}</FormErrorMessage>
                </FormControl>
              )}
            </Field>
            <Box id='pipeline-settings-version-selection' width='0.1rem' height='0.01rem' />
          </Box>
        </Flex>
      </Box>
      <Box
        hidden={!useSingleInstanceLaunch}
        borderTop='1px solid'
        borderColor='gray.300'
        padding='1.25rem 1.5rem 1.25rem'
      >
        <SectionLabel
          title='Instance selection'
          subtitle='The instance type determines the computing power and memory (RAM) used.'
        />
        <Flex mt='1.25rem'>
          <Field name='instance_type' validate={undefined}>
            {({ field, form }) => (
              <FormControl isRequired isInvalid={form.errors.instance_type && form.touched.instance_type}>
                <FormLabel style={{ fontSize: '1rem', fontWeight: 600, lineHeight: '1.5rem' }}>Instance type</FormLabel>
                <Menu>
                  <MenuButton as={Button} variant='outline' height='4rem' rightIcon={<ChevronDownIcon />}>
                    <InstanceType name={field.value} />
                  </MenuButton>
                  <MenuList>
                    <MenuOptionGroup
                      defaultValue='compute-2'
                      type='radio'
                      onChange={(value) => setFieldValue('instance_type', value)}
                      value={field.value}
                    >
                      {map(
                        instanceTypes,
                        (instance) => (
                          <MenuItemOption key={instance.name} value={instance.name}>
                            <InstanceType name={instance.name} />
                          </MenuItemOption>
                        ),
                      )}
                    </MenuOptionGroup>
                  </MenuList>
                </Menu>
                <FormErrorMessage>{form.errors.instance_type}</FormErrorMessage>
              </FormControl>
            )}
          </Field>
        </Flex>
        <Box id='pipeline-settings-instance-info' width='0.1rem' height='0.01rem' />
      </Box>
    </SectionBox>
  );
};

export default VersionInstanceSelection;
