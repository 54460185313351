import {
  Box, Button, Flex, useDisclosure, useToast,
} from '@chakra-ui/react';
import { PlusSquareIcon } from '@chakra-ui/icons';
import * as React from 'react';
import { withRequiredAuthInfo } from '@propelauth/react';
import { useQuery } from '@tanstack/react-query';

import UniversalFunctionCreateModal from './UniversalFunctionCreateModal';

const UniversalsPageHeader = ({
  accessToken,
  orgHelper,
  refetch,
}: any) => {
  const { isOpen: isCreateOpen, onOpen: onCreateOpen, onClose: onCreateClose } = useDisclosure();
  const toast = useToast();

  const [newFunctionName, setNewFunctionName] = React.useState('');
  const [serverErrors, setServerErrors] = React.useState({});
  const orgId = orgHelper.getOrgIds()[0];

  const { isFetching: isNewFunctionLoading, refetch: fetchNewFunction } = useQuery(
    ['newFunction', newFunctionName, toast, orgId],
    () => fetch(`${process.env.REACT_APP_API_URL}/app/${orgId}/universal-functions`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`,
      },
      body: JSON.stringify({
        name: newFunctionName,
      }),
    }).then(async (response) => {
      if (!response.ok) {
        const body = await response.json();
        throw new Error(body.error || 'Error connecting to server');
      }
      return response.json();
    }),
    {
      enabled: false,
    },
  );

  return (
    <Flex justify="right">
      <Box>
        <Button
          onClick={onCreateOpen}
          colorScheme="blue"
          m={3}
          leftIcon={<PlusSquareIcon />}
        >
          Create
        </Button>
        <UniversalFunctionCreateModal
          isOpen={isCreateOpen}
          onClose={onCreateClose}
          isLoading={isNewFunctionLoading}
          newFunctionName={newFunctionName}
          serverErrors={serverErrors}
          onSubmit={async (name: string) => {
            await setNewFunctionName(name);
            const response = await fetchNewFunction();
            if (response.isSuccess) {
              toast({
                title: 'Universal function created',
                description: 'New universal function successfully created.',
                status: 'success',
                duration: 5_000,
                isClosable: true,
              });
              refetch(); // this is a promise, but we don't care about waiting for the promise to complete
              onCreateClose();
            } else {
              toast({
                title: 'Failed to create universal function',
                description: `${response.failureReason}`,
                status: 'error',
                duration: 5_000,
                isClosable: true,
              });
              setServerErrors({ function_name: `${response.failureReason}` });
            }
          }}
        />
      </Box>
    </Flex>
  );
};

export default withRequiredAuthInfo(UniversalsPageHeader);
