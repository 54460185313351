import * as React from 'react';
import { withRequiredAuthInfo } from '@propelauth/react';
import {
  Button, Flex, Icon, Menu, MenuButton, MenuItem, MenuList, Spacer, useDisclosure,
} from '@chakra-ui/react';
import { GoLink, GoMultiSelect } from 'react-icons/go';
import { BsPlusLg } from 'react-icons/bs';
import PipelineConnectFromUrlModal from './PipelineConnectFromUrlModal';

const PipelinesTableHeader = ({ refetch }: any) => {
  const {
    isOpen: isPipelineImportOpen,
    onOpen: onPipelineImportOpen,
    onClose: onPipelineImportClose,
  } = useDisclosure();

  return (
    <Flex
      id='pipelines-header'
      height='3.4375rem'
      p='0.625rem'
      justify='space-between'
      align='center'
      bg='gray.50'
    >
      <PipelineConnectFromUrlModal
        isOpen={isPipelineImportOpen}
        onClose={onPipelineImportClose}
        refetchPipelines={refetch}
      />
      <Spacer/>
      <Menu>
        <MenuButton
          as={Button}
          leftIcon={<Icon as={BsPlusLg} width='0.875rem' height='0.875rem' mr={0} color='white'/>}
          variant='solid'
          colorScheme='blue'
          size='sm'
        >
            Add a pipeline
        </MenuButton>
        <MenuList>
          <MenuItem
            onClick={onPipelineImportOpen}
            icon={<Icon as={GoLink} width='1.2rem' height='1.2rem'/>}>
              From GitHub URL
          </MenuItem>
          <MenuItem
            onClick={null}
            icon={<Icon as={GoMultiSelect} height='1.4em' width='1.4em'/>}
            isDisabled={true}
          >
              From connected account
          </MenuItem>
        </MenuList>
      </Menu>
    </Flex>
  );
};

export default withRequiredAuthInfo(PipelinesTableHeader);
