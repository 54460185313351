import { useRouteError } from 'react-router-dom';
import { Box, Text } from '@chakra-ui/react';

export default function ErrorPage() {
  const error: any = useRouteError();
  // eslint-disable-next-line no-console
  console.error(error);

  return (
    <Box m={8} id="error-page">
      <Text fontSize='4xl' mb={4}>Gah!</Text>
      <Text>Sorry, an unexpected error has occurred: <i>{error.statusText || error.message}</i></Text>
    </Box>
  );
}
