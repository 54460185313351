import { useMatches, Link as RouterLink, Params } from 'react-router-dom';
import * as React from 'react';
import {
  Breadcrumb, BreadcrumbItem, BreadcrumbLink, Flex,
} from '@chakra-ui/react';
import { ChevronRightIcon } from '@chakra-ui/icons';

type RouterMatches = {
  id: string;
  pathname: string;
  params: Params;
  data: any;
  handle: any;
}[]

const Breadcrumbs = (): any => {
  const matches: RouterMatches = useMatches();
  const crumbs = matches
    // first get rid of any matches that don't have handle and crumb
    .filter((match) => Boolean(match.handle?.crumb))
    // now map them into an array of elements, passing the loader
    // data to each one
    .map((match) => (
      <BreadcrumbItem key={match.pathname}>
        <BreadcrumbLink as={RouterLink} to={match.pathname}>
          {match.handle.crumb(match.data)}
        </BreadcrumbLink>
      </BreadcrumbItem>
    ));

  return (
    <Flex alignItems='center'>
      <ChevronRightIcon color='gray.500' ml='1' mr='-1' />
      <Breadcrumb ml='1rem' spacing='0.5rem' separator={<ChevronRightIcon color='gray.500' />} alignItems='center'>
        {crumbs}
      </Breadcrumb>
    </Flex>
  );
};

export default Breadcrumbs;
