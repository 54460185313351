import * as React from 'react';
import {
  Button,
  Flex,
  Link,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay, Spacer,
  Text,
  useToast,
} from '@chakra-ui/react';
import { CopyIcon } from '@chakra-ui/icons';
import _ from 'lodash';
import { PrismLight as SyntaxHighlighter } from 'react-syntax-highlighter';
import python from 'react-syntax-highlighter/dist/esm/languages/prism/python';
import darkPrism from 'react-syntax-highlighter/dist/esm/styles/prism/vs-dark';

SyntaxHighlighter.registerLanguage('python', python);

function generatePythonCommand({ values }: any) {
  const commandParts = [
    'import flowdeploy',
    '',
    '# flowdeploy.set_key("YOUR_KEY")',
    '',
    `flowdeploy.${values.wf_manager}(`,
  ];
  const nonConsistentKeys = ['wf_manager', 'snakemake_targets', 'use_git_release', 'pipeline_version'];
  const stringifyWithTabs = (value: any) => JSON.stringify(value, null, '\t');
  const indentedParts = _.reduce(values, (acc, value, key) => {
    if (!nonConsistentKeys.includes(key) && !_.isEmpty(value)) {
      acc.push(`${key}=${stringifyWithTabs(value)},`);
    }
    if (key === 'snakemake_targets' && !_.isEmpty(value)) {
      acc.push(`targets=${stringifyWithTabs(value)},`);
    }
    if (key === 'use_git_release') {
      if (values.use_git_release) {
        acc.push(`release=${stringifyWithTabs(values.pipeline_version)},`);
      } else {
        acc.push(`branch=${stringifyWithTabs(values.pipeline_version)},`);
      }
    }
    return acc;
  }, []);
  indentedParts.push('is_async=True');
  const indentedString = indentedParts.join('\n')
    .replaceAll('\n', '\n\t'); // Replaces the newlines from the stringify calls as well
  commandParts.push(`\t${indentedString}`);
  commandParts.push(')\n');
  return commandParts.join('\n');
}

const PythonCodeModal = ({ isOpen, onClose, values }: any) => {
  const toast = useToast();
  const pythonCommand = generatePythonCommand({ values });
  return (
    <Modal isOpen={isOpen} onClose={onClose} size="5xl">
      <ModalOverlay bg='blackAlpha.300' backdropFilter='blur(5px)'/>
      <ModalContent>
        <ModalHeader>Python code to launch this pipeline</ModalHeader>
        <ModalCloseButton/>
        <ModalBody>
          <Flex>
            <Text>
              {'Make sure '}
              <Link
                href='https://learn.flowdeploy.com/reference/python' isExternal
              >
                the FlowDeploy Python package is installed
              </Link>
              {' and you have a key'}
            </Text>
          </Flex>
          <Flex
            maxWidth='100%'
            borderRadius={8}
            flexDirection='row'
            alignItems='center'
            bg="greys.800"
            mt={4}
            mb={4}
            onClick={(e) => {
              e.stopPropagation();
              navigator.clipboard.writeText(pythonCommand)
                .then(() => toast({
                  title: 'Copied to clipboard',
                  status: 'success',
                  duration: 5_000,
                  isClosable: true,
                }));
            }}
            _hover={{
              cursor: 'pointer',
              bg: 'greys.700',
            }}
            p='2%'
          >
            <SyntaxHighlighter language="python" style={darkPrism} customStyle={{
              background: 'greys.800',
            }}>
              {pythonCommand}
            </SyntaxHighlighter>
            <Spacer/>
            <CopyIcon boxSize={5} color='blues.50' mr='2%'/>
          </Flex>
        </ModalBody>
        <ModalFooter>
          <Button colorScheme='blue' onClick={() => {
            onClose();
          }}>
            Close
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default PythonCodeModal;
