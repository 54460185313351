import {
  Box, Button, Flex, Spacer, Tab, Tabs, useDisclosure, useToast,
} from '@chakra-ui/react';
import { LockIcon } from '@chakra-ui/icons';
import * as React from 'react';
import { withRequiredAuthInfo } from '@propelauth/react';
import { useQuery } from '@tanstack/react-query';

import DisableKeyConfirmationModal from './DisableKeyConfirmationModal';
import KeyCreateModal from './KeyCreateModal';
import { ReactComponent as KeyIcon } from '../images/key_icon.svg';
import { themeConfig } from '../theme';
import TabOptions from '../TabOptions';

const KeysTableHeader = ({
  accessToken,
  orgHelper,
  table,
  filter,
  refetch,
}: any) => {
  const { isOpen: isCreateOpen, onOpen: onCreateOpen, onClose: onCreateClose } = useDisclosure();
  const { isOpen: isDisableOpen, onOpen: onDisableOpen, onClose: onDisableClose } = useDisclosure();
  const [localTabIndex, setLocalTabIndex] = React.useState(0);
  const toast = useToast();

  const selectedRows = table.getSelectedRowModel().rows;
  const orgId = orgHelper.getOrgIds()[0];
  const { isFetching: isLoading, refetch: disableKey } = useQuery(
    ['disableKey', selectedRows, orgId],
    () => fetch(`${process.env.REACT_APP_API_URL}/app/${orgId}/api-keys/disable`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`,
      },
      body: JSON.stringify({
        key_prefix: selectedRows[0].original.key_prefix,
      }),
    }).then((response) => {
      if (response.ok) {
        return response.json();
      }
      return { status: response.status };
    }),
    {
      enabled: false,
    },
  );

  const { data: newKey, isFetching: isNewKeyLoading, refetch: fetchKey } = useQuery(
    ['newKey', orgId],
    () => fetch(`${process.env.REACT_APP_API_URL}/app/${orgId}/api-keys`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`,
      },
    }).then((response) => {
      if (response.ok) {
        return response.json();
      }
      return { status: response.status };
    }),
    {
      enabled: false,
    },
  );

  function handleTabChange(tab: TabOptions) {
    setLocalTabIndex(tab);
    filter(tab);
  }

  async function generateNewKey() {
    await fetchKey();
    onCreateOpen();
  }

  return (
    <>
      <Flex justify="right">
        <Flex justify="center" align="center">
          <Box height="60%" m={3}>
            <Tabs
              colorScheme='blue'
              variant='soft-rounded'
              orientation="vertical"
              height="100%"
              onChange={handleTabChange}
            >
              <Tab>Active</Tab>
              <Tab>Disabled</Tab>
            </Tabs>
          </Box>
        </Flex>
        <Spacer />
        <Box>
          <Button
            onClick={onDisableOpen}
            isDisabled={(selectedRows && selectedRows.length !== 1) || localTabIndex === TabOptions.Disabled}
            colorScheme="blue"
            m={3}
            mr={0}
            leftIcon={<LockIcon />}
          >
                  Disable key
          </Button>
          <DisableKeyConfirmationModal
            isOpen={isDisableOpen}
            onClose={onDisableClose}
            isLoading={isLoading}
            onSubmit={async () => {
              await disableKey();
              toast({
                title: 'Key disabled',
                description: 'Key was successfully disabled.',
                status: 'success',
                duration: 7000,
                isClosable: true,
              });
              table.toggleAllRowsSelected(false);
              refetch(); // this is a promise, but we don't care about waiting for the promise to complete
              onDisableClose();
            }}
          />
          <Button
            onClick={generateNewKey}
            isLoading={isNewKeyLoading}
            loadingText="Generating new key"
            colorScheme="blue"
            m={3}
            leftIcon={<KeyIcon fill={themeConfig.colors.blues['50']}/>}
          >
                Generate new key
          </Button>
          <KeyCreateModal
            isOpen={isCreateOpen}
            onClose={onCreateClose}
            newKey={newKey}
            isNewKeyLoading={isNewKeyLoading}
            refetchKeys={refetch}
          />
        </Box>
      </Flex>
    </>
  );
};

export default withRequiredAuthInfo(KeysTableHeader);
