import * as React from 'react';
import { DataTable } from '../../DataTable';

const NewFilesTable = ({
  files,
  onSelect,
  onDeselect,
  onRowClick,
  selectedFileName,
  hide,
  idColumn,
  headerButtons,
  TableHeader,
  columns,
  emptyMessage,
  isSelectingDirsOnly,
  enableRowSelection,
  enableMultiRowSelection,
  refetch,
}: any) => {
  if (hide) {
    return <></>;
  }

  function isRowSelectable(row) {
    if (isSelectingDirsOnly) {
      return row.original.is_dir;
    }
    return enableRowSelection ?? enableMultiRowSelection;
  }

  const defaultEmptyMessage = "There's no data that matches your search.";
  return (
    <DataTable
      data={files}
      columns={columns}
      TableHeader={TableHeader}
      headerButtons={headerButtons}
      defaultSortId={idColumn}
      noTopMargin
      onSelect={onSelect}
      onDeselect={onDeselect}
      onRowClick={onRowClick}
      idColumn={idColumn}
      initialRowSelection={selectedFileName ? [selectedFileName] : null}
      isRowSelectable={isRowSelectable}
      emptyMessage={emptyMessage || defaultEmptyMessage}
      refetch={refetch}
      enableMultiRowSelection={enableMultiRowSelection ?? enableRowSelection ?? true}
      enableGlobalSearch
      noBottomMargin
    />
  );
};
export default NewFilesTable;
