import {
  Icon, Tab, TabList, Tabs, Text,
} from '@chakra-ui/react';
import { BsBucket, BsFolder } from 'react-icons/bs';
import { findIndex, map } from 'lodash';
import { useContext } from 'react';

import Files from './Files';
import { UserContext } from '../../UserContext';

const DriveTabs = ({
  selectedFileSystem, setSelectedFileSystem, fdMountName, dataRepos, selectingConfig,
}) => {
  const { orgData } = useContext(UserContext);

  const externalDrives = map(
    dataRepos,
    (dataRepo) => ({
      type: 's3',
      title: dataRepo.uri.split('s3://')[1],
      name: dataRepo.uri,
      isPublic: false,
      isFd: false,
      id: dataRepo.id,
    }),
  );

  // SharedFS if parallel, mock 'My Drive' if single instance
  const useSingleInstanceLaunch = orgData.is_single_instance;
  const shouldUseMockDrive = useSingleInstanceLaunch && orgData?.team_name;
  const mockDriveName = `fd://${orgData?.team_name}`;
  let drives = [];
  if (shouldUseMockDrive) {
    drives = [{
      type: 'fd',
      title: 'My Drive',
      name: mockDriveName,
      isPublic: false,
      isFd: true,
      id: 'mydrive',
    }, ...externalDrives,
    {
      type: 'fd',
      title: 'FlowDeploy Public',
      name: 'fd://flowdeploy-public',
      isPublic: true,
      isFd: true,
      id: 'fd://flowdeploy-public',
    }];
  } else {
    drives = [{
      type: 'fd',
      title: fdMountName.split('fd://')[1],
      name: fdMountName,
      isPublic: false,
      isFd: true,
      id: fdMountName,
    }, ...externalDrives];
  }

  const initialTabIndex = findIndex(drives, { name: selectedFileSystem });

  return (
    <Tabs
      isFitted
      variant='sectionbox'
      size='lg'
      index={initialTabIndex === -1 ? 0 : initialTabIndex}
      onChange={(newIndex) => {
        setSelectedFileSystem(drives[newIndex].name);
      }}
    >
      <TabList
        mb='1.25em'
      >
        {map(
          drives,
          (drive) => (
            <Tab key={drive.name}>
              <Icon as={drive.isFd ? BsFolder : BsBucket} height='1.5rem' width='1.5rem'/>
              <Text ml='0.5rem' noOfLines={1}>{drive.title}</Text>
            </Tab>
          ),
        )}
      </TabList>
      <Files selectingConfig={selectingConfig} />
    </Tabs>
  );
};

export default DriveTabs;
