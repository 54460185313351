import { Field } from 'formik';
import * as React from 'react';
import {
  AccordionButton,
  AccordionPanel,
  Box,
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  Input,
  Spacer,
  Text,
} from '@chakra-ui/react';
import { ChevronDownIcon, ChevronRightIcon, DeleteIcon } from '@chakra-ui/icons';

import { get, set } from 'lodash';
import FileSelection from '../../data/FileSelection';

function validateInputSource(value: string) {
  if (!value) {
    return 'Input source must be set';
  }
  if (value.length < 1) {
    return 'Input source must contain at least one character';
  } if (/^\s*$/.test(value)) {
    return 'Input source must contain at least one non-whitespace character';
  } if (/^\s|\s$/.test(value)) {
    return 'Input source must not start or end with whitespace';
  } if (!value.startsWith('s3://') && !value.startsWith('fd://')) {
    return 'Input source must be an S3 (s3://) or shared file system (fd://) path';
  }
  return '';
}

const InputFormElement = ({
  index, setFieldValue, isExpanded, onRemove, values, mountName,
}) => {
  const sourceField = `inputs[${index}].source`;
  const destinationField = `inputs[${index}].destination`;
  const argField = `inputs[${index}].arg`;
  return (
    <Box alignItems='center' borderLeft='solid 1px' borderRight='solid 1px' borderColor='gray.200'>
      <AccordionButton bg={'white'}>
        {isExpanded ? (
          <ChevronDownIcon fontSize='18px'/>
        ) : (
          <ChevronRightIcon fontSize='18px'/>
        )}
        <Text fontSize='lg' fontWeight={600} ml={1} alignContent='center' pt={2} pb={2}>
          Input #{index + 1}
        </Text>
        <Spacer />
      </AccordionButton>
      <AccordionPanel pb={0} paddingInlineStart={0} paddingInlineEnd={0} p={4}>
        <FileSelection
          label='Where is the file right now?'
          fieldName={sourceField}
          description='Where the input file is located including protocol (e.g. fd://, s3://).'
          setFieldValue={setFieldValue}
          isDirOnly={false}
          validate={validateInputSource}
          isRequired
        />
        <Box height='1rem' />
        { get(values, sourceField)?.startsWith('s3://')
          ? (<>
            <FileSelection
              label={`Where in fd://${mountName} should this copy?`}
              fieldName={destinationField}
              description='This transfer will finish before the pipeline starts.'
              setFieldValue={setFieldValue}
              isDirOnly={false}
              isRequired
              isFdOnly
            />
            <Box height='1rem' />
          </>) : set(values, destinationField, '') && <></>
        }
        <Field name={argField}>
          {({ field, form }) => (
            <FormControl isInvalid={form.errors[argField] && form.touched[argField]}>
              <FormLabel>Arg</FormLabel>
              <Input
                placeholder='--samplesheet'
                value={field && field.value}
                onChange={(e) => setFieldValue(argField, e.target.value)}
              />
              <FormHelperText>Command-line argument use with the file (e.g. --samplesheet)</FormHelperText>
              <FormErrorMessage>{form.errors[argField]}</FormErrorMessage>
            </FormControl>
          )}
        </Field>
        <Flex>
          <Spacer />
          <Button
            colorScheme='reds'
            leftIcon={<DeleteIcon />}
            variant='outline'
            onClick={onRemove}
          >
            Remove
          </Button>
        </Flex>
      </AccordionPanel>
    </Box>
  );
};

export default InputFormElement;
