import * as React from 'react';
import {
  Box, Flex, Image, Text, Tooltip,
} from '@chakra-ui/react';
import { useMatch } from 'react-router-dom';

const PageTab = ({
  label, link, icon, collapsed,
}: any) => {
  const colors = useMatch(link) ? {
    bg: 'blue.100',
    icon: 'gray.900',
    label: 'gray.900',
  } : {
    bg: '',
    icon: 'gray.900',
    label: 'gray.900',
  };
  return (
    <Box width={collapsed ? '3rem' : '15rem'} height="3.5rem" bg={colors.bg} mt={2} borderRadius={8}
      _hover={{
        cursor: 'pointer',
      }}
    >
      <Tooltip label={label} placement='right'>
        <Flex align="left" justify={collapsed ? 'center' : 'left'} height="100%">
          <Flex direction="row" justify="center" align="center" height="100%">
            <Box mr='0.75rem' ml='1rem' justifyContent='center' alignItems='center'>
              <Image
                as={icon}
                boxSize='1.5rem'
                fill={colors.icon}
                objectFit='scale-down'
                objectPosition='bottom 0 left 0'
              />
            </Box>
            {!collapsed && (
              <Text color={colors.label} fontSize='1rem'>
                {label}
              </Text>
            )}
          </Flex>
        </Flex>
      </Tooltip>
    </Box>
  );
};

export default PageTab;
