import { withRequiredAuthInfo } from '@propelauth/react';
import { Progress } from '@chakra-ui/react';
import { useQueries, useQuery } from '@tanstack/react-query';
import {
  createContext, FC, useEffect, useState,
} from 'react';
import * as React from 'react';
import { useSearchParams } from 'react-router-dom';
import { UserContext } from '../../UserContext';
import useSearchParamsState from '../../searchParamsSatate/useSearchParamsState';
import { fetchDataRepos, fetchFilesAtPath } from './queries';
import { DataRepoContextType, DataRepoParentProps } from './types';

export const DrivesContext = createContext<DataRepoContextType>({
  dataRepos: [],
  selectedFileSystem: '',
  setSelectedFileSystem: () => null,
  fdMountName: null,
  defaultPath: null,
  searchParams: null,
  setSearchParams: null,
  selectedFiles: null,
  setSelectedFiles: null,
  originalPath: null,
  files: [],
  refetchDataRepos: () => null,
  refetchFiles: () => null,
  selectedFileName: '',
  setSelectedFileName: () => null,
});

function parseFileSystemFromPath(path, defaultPath) {
  if (!path) {
    return defaultPath;
  }
  const pathSegments = path.split('/');
  const fileSystemNameIsEmpty = !pathSegments[2];
  if (pathSegments.length < 3 || fileSystemNameIsEmpty) {
    return defaultPath;
  }
  return `${pathSegments[0]}//${pathSegments[2]}`;
}

const DriveProvider: FC<DataRepoParentProps> = ({ children, accessToken, orgHelper }) => {
  const orgId = orgHelper.getOrgIds()[0];
  const [searchParams, setSearchParams] = useSearchParams();
  const context = React.useContext(UserContext);
  const fdMountName = `fd://${context.orgData?.remote_mount_name}`;

  // State for selected file system
  const defaultSelectedFileSystem = context.orgData.is_single_instance
    ? `fd://${context.orgData.team_name}` // "My Drive"
    : fdMountName; // Shared file system
  // Automatically updates search param "path" and state in tandem
  // todo: propagate to all "path" usages of this type
  const [path, setPath] = useSearchParamsState(
    'path',
    parseFileSystemFromPath(
      searchParams.get('path'),
      defaultSelectedFileSystem,
    ),
  );
  const [selectedFileSystem, setSelectedFileSystemInState] = useState<string>(
    parseFileSystemFromPath(path, defaultSelectedFileSystem),
  );
  const defaultPath = selectedFileSystem?.endsWith('/')
    ? selectedFileSystem
    : `${selectedFileSystem}/`;

  function setSelectedFileSystem(newFileSystem) {
    setPath(`${newFileSystem}`);
    setSelectedFileSystemInState(newFileSystem);
  }

  const [selectedFiles, setSelectedFiles] = useState<any>({});

  const hasSharedFs = !!context.orgData?.remote_mount_name;
  const originalPath = path || defaultPath;
  const [file, setFile] = useSearchParamsState(
    'file',
    null,
  );
  const setSelectedFileName = (name) => {
    setFile(name);
  };

  useEffect(() => {
    if (hasSharedFs) {
      setSelectedFileSystem(parseFileSystemFromPath(path, fdMountName));
    }
  }, [hasSharedFs, fdMountName]);

  // Set search params or selected files
  useEffect(() => {
    const searchParamsPathNotSet = selectedFileSystem && !path;
    const searchParamsPathNotEqual = searchParams.get('path') && !searchParams.get('path').startsWith(defaultPath);
    if (searchParamsPathNotSet || searchParamsPathNotEqual) {
      setPath(defaultPath);
    } else if (file && !selectedFiles[file]) {
      setSelectedFiles({
        ...selectedFiles,
        [file]: { name: file },
      });
    }
  }, [defaultPath, selectedFileSystem, file, selectedFiles, searchParams, setSearchParams, setSelectedFiles]);

  const subdir = searchParams.get('subdir') || '';
  const dataRepoPrefixes = ['fd://', 's3://'];
  const queryKey = ['files', originalPath, subdir, context.orgData.is_single_instance];
  const {
    data: files, refetch: refetchFiles, isSuccess,
  } = useQuery(
    queryKey,
    () => fetchFilesAtPath({
      orgId,
      path: path && !dataRepoPrefixes.includes(path)
        ? path
        : defaultPath,
      accessToken,
      useRegisteredFiles: !!context.orgData.is_single_instance,
      subdir,
    }),
    {
      placeholderData: () => ([
        {
          name: <Progress size='xs' isIndeterminate />,
          url: '',
          last_modified: 0,
          is_dir: true,
          size: null,
          not_selectable: true,
        },
      ]),
      staleTime: 60 * 1000,
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      enabled: !!selectedFileSystem && !dataRepoPrefixes.includes(selectedFileSystem),
    },
  );

  const queryFiles = files?.filter((queryFile) => queryFile.is_dir && queryFile.size !== null)
    ?? [];
  useQueries({
    queries: queryFiles.slice(0, 10).map((queryFile: any) => ({
      queryKey: ['files', `${originalPath}${queryFile.name}/`],
      queryFn: async () => fetchFilesAtPath({
        orgId,
        accessToken,
        path: `${originalPath}/${queryFile.name}`,
        useRegisteredFiles: !!context.orgData.is_single_instance,
        subdir,
      }),
      enabled: isSuccess,
      staleTime: 60 * 1000,
      refetchOnMount: false,
      refetchOnWindowFocus: false,
    })),
  });

  // Clear selected files when working directory changes
  useEffect(() => {
    setSelectedFiles({});
  }, [originalPath]);

  // Refetch files on file system change
  useEffect(() => {
    refetchFiles().then();
  }, [selectedFileSystem]);

  const { data: dataRepos, refetch: refetchDataRepos } = useQuery(
    ['dataRepos'],
    () => fetchDataRepos({
      orgId, accessToken,
    }),
    {
      staleTime: 60 * 1000,
      refetchOnMount: true,
      refetchOnWindowFocus: false,
      enabled: true,
    },
  );

  return (
    <DrivesContext.Provider
      value={{
        dataRepos,
        selectedFileSystem,
        setSelectedFileSystem,
        fdMountName,
        defaultPath,
        searchParams,
        setSearchParams,
        selectedFiles,
        setSelectedFiles,
        originalPath,
        files,
        refetchDataRepos,
        refetchFiles,
        selectedFileName: file,
        setSelectedFileName,
      }}
    >
      {children}
    </DrivesContext.Provider>
  );
};

export default withRequiredAuthInfo(DriveProvider);
