import * as React from 'react';
import {
  Alert,
  AlertIcon,
  Box,
  Button,
  Flex,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Text,
} from '@chakra-ui/react';

const KeyCreateModal = ({
  isOpen, onClose, newKey, refetchKeys,
}: any) => (
  <Modal isOpen={isOpen} onClose={onClose} size="2xl">
    <ModalOverlay bg='blackAlpha.300' backdropFilter='blur(5px)'/>
    <ModalContent>
      <>
        <ModalHeader>New key created</ModalHeader>
        <ModalCloseButton/>
        <ModalBody>
                        Copy and save your key now: this is the only time that you can see this key.
          <Alert status='info' mt={8} mb={8} >
            <AlertIcon/>
                        Your key is secret! Make sure not to include it in any public code.
          </Alert>
          <Flex align="center" justify="center">
            <Box borderWidth="1.5px" borderRadius="15px" borderColor="#EDF0FF">
              <Text m={6} color="#1945AF">
                {newKey ? `${newKey.key_prefix}.${newKey.key}` : 'loading...'}
              </Text>
            </Box>
          </Flex>
        </ModalBody>
        <ModalFooter>
          <Button colorScheme='blue' onClick={() => {
            onClose();
            refetchKeys();
          }}>
                            Close
          </Button>
        </ModalFooter>
      </>
    </ModalContent>
  </Modal>
);

export default KeyCreateModal;
