import {
  Box, Button, Flex, Heading, HStack, Progress, Text, useToast,
} from '@chakra-ui/react';
import { withRequiredAuthInfo } from '@propelauth/react';
import { useQuery } from '@tanstack/react-query';
import * as React from 'react';
import { useEffect, useState } from 'react';
import SectionBox from '../SectionBox';
import { StatusBadge, StatusBadges } from '../StatusBadge';

const ComputeEnvironmentsSection = ({ accessToken, orgHelper }: any) => {
  const orgId = orgHelper.getOrgIds()[0];
  const [isWarm, setIsWarm] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);
  const [envStatus, setEnvStatus] = useState('');
  const toast = useToast();

  const {
    isFetching, refetch: getComputeEnvStatus, isSuccess,
  } = useQuery(
    ['computeEnvs', orgId],
    () => fetch(`${process.env.REACT_APP_API_URL}/app/${orgId}/compute-envs`, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`,
      },
    }).then(async (response: any) => {
      const body = await response.json();
      if (!response.ok) {
        throw new Error(body.error || 'Error connecting to server');
      }
      // eslint-disable-next-line no-return-assign,no-param-reassign
      await setIsWarm(body.warmed);
      await setIsUpdating(body.updating);
      return body;
    }),
    {
      refetchOnMount: true,
      refetchOnWindowFocus: false,
      refetchInterval: 30_000,
    },
  );

  const {
    refetch: updateComputeEnv, isFetching: isWarming,
  } = useQuery(
    ['updateCounts', orgId],
    async () => {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/app/${orgId}/compute-envs`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${accessToken}`,
        },
        body: JSON.stringify({
          should_warm: !isWarm,
        }),
      });
      const body = await response.json();
      if (response.ok) {
        toast({
          title: `Started ${isWarm ? 'cooling' : 'warming'} compute env`,
          status: 'success',
          duration: 5000,
          isClosable: true,
        });
        await getComputeEnvStatus();
        return body;
      }
      toast({
        title: `Failed to ${isWarm ? 'cool' : 'warm'} compute env`,
        status: 'error',
        description: body.error || 'Error connecting to server',
        duration: 7000,
        isClosable: true,
      });
      return body;
    },
    {
      enabled: false,
      retry: false,
    },
  );

  useEffect(() => {
    let status = isWarm ? 'Warm' : 'Cool';
    if (isUpdating) {
      status += 'ing';
    }
    setEnvStatus(status);
  }, [isWarm, isUpdating]);

  const getStatusBadgeType = () => {
    if (isUpdating) {
      return StatusBadges.Disabled;
    }
    return isWarm ? StatusBadges.Active : StatusBadges.Executing;
  };

  return (
    <SectionBox header='Compute Environments' isFormatted>
      { isFetching && !isSuccess && <Progress size='xs' isIndeterminate /> }
      {
        !isFetching && !isSuccess
        && (
          <Flex>
            <Text as='b'>Error fetching compute environments.</Text>
          </Flex>
        )
      }
      { isSuccess
        && (
          <Box pt={4}>
            <Heading size='sm'>Default compute environment</Heading>
            <HStack pt={3}>
              <StatusBadge
                label={envStatus}
                badgeType={getStatusBadgeType()}
              />
              {/* {isUpdating && <Spinner size='xs' />} */}
            </HStack>
            <Button
              mt={3}
              onClick={updateComputeEnv}
              width='17em'
              colorScheme="blue"
              isLoading={isWarming}
              loadingText={isWarm ? 'Cooling' : 'Warming'}
              isDisabled={isUpdating}
            >
              {isWarm ? 'Cool' : 'Warm'} compute environment
            </Button>
          </Box>
        )
      }
    </SectionBox>
  );
};

export default withRequiredAuthInfo(ComputeEnvironmentsSection);
