import { every, values } from 'lodash';
import { useQuery } from '@tanstack/react-query';
import * as React from 'react';
import {
  Icon, IconButton, useDisclosure, useToast,
} from '@chakra-ui/react';
import { BsTrash } from 'react-icons/bs';
import { withRequiredAuthInfo } from '@propelauth/react';
import { useContext } from 'react';
import FileDeleteConfirmationModal from '../../DataPage/FileDeleteConfirmationModal';
import { UserContext } from '../../../UserContext';

async function deleteFiles({
  orgId, path, selectedFiles, accessToken,
}) {
  const filePaths = values(selectedFiles).map((file) => `${path}${file.name}`);
  return fetch(`${process.env.REACT_APP_API_URL}/app/${orgId}/files?path=${encodeURIComponent(path)}`, {
    method: 'DELETE',
    body: JSON.stringify({ file_paths: filePaths }),
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${accessToken}`,
    },
  }).then(async (response) => {
    if (response.ok) {
      return response.json();
    }
    const body = await response.json();
    throw new Error(body.error || 'Network response was not ok');
  });
}

const DeleteSelectedIconButton = ({
  uriPath, selectedFiles, refetch, toggleRows, accessToken, orgHelper,
}) => {
  const orgId = orgHelper.getOrgIds()[0];
  const toast = useToast();
  const { isOpen: isDeleteOpen, onOpen: onDeleteOpen, onClose: onDeleteClose } = useDisclosure();
  const { orgData } = useContext(UserContext);
  const useSingleInstanceLaunch = orgData.is_single_instance;

  // Only applicable for multi-select -> delete
  const { refetch: deleteFileRefetch, isFetching: isDeleting } = useQuery(
    ['deleteFiles', uriPath, selectedFiles],
    async () => {
      try {
        const deletedFilesRes = await deleteFiles({
          orgId,
          path: uriPath,
          selectedFiles,
          accessToken,
        });
        await refetch();
        onDeleteClose();
        const deletedAllFiles = every(
          deletedFilesRes,
          (value) => value === 'True',
        );
        if (deletedAllFiles) {
          toast({
            title: 'Successfully deleted data',
            status: 'success',
            duration: 5_000,
            isClosable: true,
          });
        } else {
          toast({
            title: 'Failed to delete some data',
            status: 'error',
            duration: 7_000,
            isClosable: true,
          });
        }
        // Deselect the file(s)
        toggleRows();
        return deletedFilesRes;
      } catch (err) {
        toast({
          title: 'Failed to delete some data',
          description: err.message,
          status: 'error',
          duration: 7_000,
          isClosable: true,
        });
        return undefined;
      }
    },
    {
      enabled: false,
    },
  );

  return (
    <>
      <FileDeleteConfirmationModal
        isOpen={isDeleteOpen}
        onClose={onDeleteClose}
        isLoading={isDeleting}
        onSubmit={() => deleteFileRefetch()}
        selectedFiles={selectedFiles}
        filePath={uriPath}
      />
      <IconButton
        aria-label='delete selected files from drive'
        variant='ghost'
        colorScheme='gray'
        icon={<Icon as={BsTrash} width='1.25rem' height='1.25rem'/>}
        ml='0.75rem '
        onClick={onDeleteOpen}
        isDisabled={useSingleInstanceLaunch}
      />
    </>
  );
};

export default withRequiredAuthInfo(DeleteSelectedIconButton);
