import * as React from 'react';
import { useEffect, useState } from 'react';
import {
  Alert,
  AlertIcon,
  Box,
  Button,
  Checkbox,
  HStack,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
} from '@chakra-ui/react';
import { withRequiredAuthInfo } from '@propelauth/react';
import _ from 'lodash';
import { BsFileEarmarkText, BsFolder } from 'react-icons/bs';

const FileDeleteConfirmationModal = ({
  isOpen,
  onClose,
  onSubmit,
  isLoading,
  selectedFiles,
  filePath,
}: any) => {
  if (!isOpen) {
    return <></>;
  }

  const hasDir = _.some(selectedFiles, ['is_dir', true]);
  const [checked, setChecked] = useState(false);
  useEffect(() => {
    if (!isOpen) {
      setChecked(false);
    }
  }, [isOpen]);
  return (
    <Modal isOpen={isOpen} onClose={onClose} size='xl'>
      <ModalOverlay bg='blackAlpha.300' backdropFilter='blur(5px)'/>
      <ModalContent>
        <ModalHeader>Delete data</ModalHeader>
        <ModalCloseButton/>
        <ModalBody>
          <Text as='b'>Are you sure you want to delete this data?</Text>
          <Text>Within {filePath}:</Text>
          {_.map(selectedFiles, (file) => (
            <HStack key={file.name}>
              {file.is_dir
                ? <BsFolder height='1.4em' width='1.4em'/>
                : <BsFileEarmarkText height='1.4em' width='1.4em'/>
              }
              <Text>{file.name}</Text>
            </HStack>
          ))}
          <Alert status='warning' mt='5'>
            <Box>
              <HStack mb={2}>
                <AlertIcon/>
                <Text>
                  Deletion is irreversible. Make sure any data you need is stored externally before proceeding.
                </Text>
              </HStack>
              { hasDir
                && (
                  <Checkbox onChange={() => setChecked(!checked)} ml={10} spacing={5}>
                    I understand deleting a directory will delete its contents. Deleting a directory with many files
                    (100k+) may take over a minute.
                  </Checkbox>
                )
              }
            </Box>
          </Alert>
        </ModalBody>
        <ModalFooter>
          <Button
            colorScheme='red'
            onClick={onSubmit}
            isDisabled={hasDir && !checked}
            isLoading={isLoading}
            loadingText="Deleting data..."
          >
          Delete
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default withRequiredAuthInfo(FileDeleteConfirmationModal);
