export function validateOutdir(required: boolean, value: string) {
  if (!value) {
    if (!required) {
      return '';
    }
    return 'Output folder is a required field';
  }
  if (value.length > 0 && !value.startsWith('fd://')) {
    return 'Output folder must be in the shared file system';
  }
  if (/^\s|\s$/.test(value)) {
    return 'Output folder must not start or end with whitespace';
  }
  return '';
}

export function validateExportLocation(value: string) {
  if (value.length > 0 && !value.startsWith('s3://')) {
    return 'Export location must be in S3';
  }
  if (/^\s|\s$/.test(value)) {
    return 'Export Location must not start or end with whitespace';
  }
  return '';
}

export function validateExportLocationSource(value: string) {
  if (value.length > 0 && !value.startsWith('fd://')) {
    return 'Export source directory must be in the shared file system';
  }
  if (/^\s|\s$/.test(value)) {
    return 'Export source directory must not start or end with whitespace';
  }
  return '';
}
