import * as React from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  ModalCloseButton,
} from '@chakra-ui/react';

import EmbeddedFileSelect from './EmbeddedFileSelect';
import DriveProvider from './DriveProvider';

const FileSelectModal = ({
  isOpen, onClose, handleSelect, isDirOnly, isS3Only, isFdOnly, isLocalMultiSelect,
}: any) => {
  function onSelect(args) {
    handleSelect(args);
    onClose();
  }

  return (
    <Modal isOpen={isOpen} onClose={onClose} size='5xl' isCentered>
      <ModalOverlay bg='blackAlpha.300' backdropFilter='blur(5px)'/>
      <ModalContent>
        <ModalCloseButton/>
        <ModalBody pt={12}>
          <DriveProvider>
            <EmbeddedFileSelect
              handleSelect={onSelect}
              isDirOnly={isDirOnly}
              isS3Only={isS3Only}
              isFdOnly={isFdOnly}
              isLocalMultiSelect={isLocalMultiSelect}
            />
          </DriveProvider>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default FileSelectModal;
