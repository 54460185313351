import * as React from 'react';
import {
  Box,
  Flex,
  Heading,
  Spacer,
} from '@chakra-ui/react';
import { DrivesContext } from '../DriveProvider';
import DriveTabs from './DriveTabs';

const DataPage = () => {
  const {
    fdMountName,
    dataRepos,
    selectedFileSystem,
    setSelectedFileSystem,
  } = React.useContext(DrivesContext);

  return (
    <Box flex='4'>
      <Flex direction='row'>
        <Heading as='h2' size='xl' mb={2}>
          Data
        </Heading>
        <Spacer />
      </Flex>
      <Box bg='gray.200' height='1px' mt='1.88rem' mb='1.88rem'/>
      <DriveTabs
        key={selectedFileSystem}
        selectedFileSystem={selectedFileSystem}
        setSelectedFileSystem={setSelectedFileSystem}
        fdMountName={fdMountName}
        dataRepos={dataRepos}
        selectingConfig={{
          isSelectingDirsOnly: false,
          enableMultiRowSelection: true,
        }}
      />
    </Box>
  );
};

export default DataPage;
