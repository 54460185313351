import {
  useContext, useEffect, useRef, useState,
} from 'react';
import { QueryClient, useQuery, useQueryClient } from '@tanstack/react-query';
import { RedirectToLogin, withRequiredAuthInfo } from '@propelauth/react';
import {
  Box,
  Button,
  Flex,
  Heading,
  Spacer,
  Spinner,
  Text,
  useDisclosure,
  useToast,
} from '@chakra-ui/react';
import {
  useLocation, useParams, useSearchParams,
} from 'react-router-dom';

import { convertStatusToBadge } from '../../StatusBadge';
import { RunSummary } from '../types';
import TerminateRunConfirmationModal from './TerminateRunConfirmationModal';
import { runTypes } from '../../constants';
import RunsSummaryTable from '../RunsSummaryTable';
import RunInfoAccordion from './RunInfoAccordion';
import SectionBox from '../../SectionBox';
import RunSummarySection from './RunSummarySection';
import TemplateCreationModal from './TemplateCreationModal';
import RunDataSection from './RunDataSection';
import RunExecutionSection from './RunExecutionSection';
import { UserContext } from '../../UserContext';

function getCostEstimate(runDetail: any) {
  let costEstimate;
  if (
    runDetail
    && runDetail.is_default_pricing
    && runDetail.provider === 'aws'
    && !Number.isNaN(runDetail.cost_per_minute)
    && runDetail.execution_started_at
  ) {
    const endDate = runDetail.execution_ended_at ? new Date(runDetail.execution_ended_at) : new Date();
    const startDate = new Date(runDetail.execution_started_at);
    const diff = endDate.getTime() - startDate.getTime();
    const differenceInMinutes = diff / (1000 * 60);
    costEstimate = (differenceInMinutes * runDetail.cost_per_minute) + 0.10;
  }
  return costEstimate;
}

async function callRetryRun(
  orgId: string,
  runTypeRoute: string,
  runSummary: RunSummary,
  accessToken: string,
  queryClient: QueryClient,
  unlock = false,
) {
  const response = await fetch(
    `${process.env.REACT_APP_API_URL}/app/${orgId}/runs/${runTypeRoute}/${runSummary.id}/retry`,
    {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`,
      },
      body: JSON.stringify({ unlock }),
    },
  );
  if (!response.ok) {
    const body = await response.json();
    throw new Error(body.error || 'Error connecting to server');
  }
  queryClient.invalidateQueries({ queryKey: [`runDetail${runSummary.id}`] });
  return response.json();
}

const RunDetailPage = ({ accessToken, orgHelper }: any) => {
  const { orgData } = useContext(UserContext);
  const useSingleInstanceLaunch = orgData.is_single_instance;
  const { state } = useLocation();
  const [searchParams] = useSearchParams();
  const { id } = useParams();
  const runSummary: RunSummary = {
    id: id || '',
    status: undefined,
    tool_name: undefined,
    run_type: searchParams.get('runType') === runTypes.TES.toString()
      ? runTypes.TES
      : runTypes.PipelineSegmentInstance,
    subtasks: [],
  };
  const runTypeRoute = runSummary.run_type === runTypes.TES
    ? 'tes-tasks'
    : 'pipeline-segment-instances'; // defaults to pipeline segment instance if not specified

  if (state) {
    runSummary.status = state.status;
    runSummary.tool_name = state.tool_name;
  }
  // isLoggedIn and user are injected automatically by withAuthInfo
  const orgId = orgHelper.getOrgIds()[0];
  const [costEstimate, setCostEstimate] = useState(getCostEstimate({}));
  const { isOpen: isTerminateOpen, onOpen: onTerminateOpen, onClose: onTerminateClose } = useDisclosure();
  const { isOpen: isTemplateOpen, onOpen: onTemplateOpen, onClose: onTemplateClose } = useDisclosure();
  const dataSectionRef = useRef(null);

  const toast = useToast();
  const isActiveRun = (status: string) => {
    if (status === undefined) {
      return false;
    }
    const finishedStates = ['terminated', 'ready_to_transfer', 'ready_to_transfer_to_client', 'failed', 'complete'];
    return !finishedStates.includes(status);
  };

  const getRefetchInterval = (runDetail: any) => {
    const defaultInterval = 1 * 60 * 1000;
    const fastInterval = 2 * 1000;
    const slowInterval = 10 * 60 * 1000;
    if (runDetail === undefined) {
      return defaultInterval;
    }
    if (['terminating', 'awaiting_execution', 'beginning_execution', 'executing'].includes(runDetail.status)) {
      return fastInterval;
    }
    return isActiveRun(runDetail.status)
      ? defaultInterval
      : slowInterval;
  };

  const queryClient = useQueryClient();

  const {
    data: runDetail,
    isLoading,
    isSuccess: isRunDetailSuccess,
    refetch: refetchDetail,
  } = useQuery(
    [`runDetail${runSummary.id}`, orgId],
    () => fetch(`${process.env.REACT_APP_API_URL}/app/${orgId}/runs/${runTypeRoute}/${runSummary.id}`, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`,
      },
    }).then(async (response) => {
      if (!response.ok) {
        const body = await response.json();
        throw new Error(body.error || 'Error connecting to server');
      }
      return response.json();
    }),
    {
      refetchOnMount: true,
      refetchOnWindowFocus: false,
      refetchInterval: (data) => getRefetchInterval(data),
      onSuccess: (data) => setCostEstimate(getCostEstimate(data)),
    },
  );

  useEffect(() => {
    const timer = setTimeout(() => {
      setCostEstimate(getCostEstimate(runDetail));
    }, 1000);
    return () => clearTimeout(timer);
  }, [runDetail, costEstimate]);

  const { isFetching: isTerminateLoading, refetch: terminateRun } = useQuery(
    ['terminateRun'],
    async () => {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/app/${orgId}/runs/${runTypeRoute}/${runSummary.id}/terminate`,
        {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${accessToken}`,
          },
        },
      );
      if (!response.ok) {
        const body = await response.json();
        throw new Error(body.error || 'Error connecting to server');
      }
      queryClient.invalidateQueries({ queryKey: [`runDetail${runSummary.id}`] });
      return response.json();
    },
    {
      enabled: false,
    },
  );

  const onTerminateSubmit = async () => {
    const response = await terminateRun();
    if (response.isSuccess) {
      toast({
        title: 'Run terminating',
        description: 'Termination request sent.',
        status: 'success',
        duration: 7000,
        isClosable: true,
      });
      refetchDetail(); // this is a promise, but we don't care about waiting for the promise to complete
      onTerminateClose();
    } else {
      toast({
        title: 'Failed to terminate run',
        description: `${response.failureReason}`,
        status: 'error',
        duration: 5_000,
        isClosable: true,
      });
    }
  };

  const { isFetching: isRetryLoading, refetch: retryRun } = useQuery(
    ['retryRun'],
    async () => callRetryRun(orgId, runTypeRoute, runSummary, accessToken, queryClient),
    {
      enabled: false,
    },
  );

  // We should add a default return value for these queries soon, this type of variable is getting out of hand
  const isTESChildTask = runSummary.run_type === runTypes.TES && (runDetail && !!runDetail.parent_id);
  const { data: logFiles } = useQuery(
    [`logFiles${runSummary.id}`, orgId],
    async () => {
      const response = await
      fetch(`${process.env.REACT_APP_API_URL}/app/${orgId}/runs/tes-tasks/${runSummary.id}/log-files`, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${accessToken}`,
        },
      });
      if (!response.ok) {
        const body = await response.json();
        throw new Error(body.error || 'Error connecting to server');
      }
      return response.json();
    },
    {
      enabled: isRunDetailSuccess && isTESChildTask,
      refetchOnMount: true,
      refetchOnWindowFocus: false,
      refetchInterval: 60 * 60 * 1000,
    },
  );

  const onRetrySubmit = async () => {
    const response = await retryRun();
    if (response.isSuccess) {
      toast({
        title: 'Run resumed',
        status: 'success',
        duration: 7000,
        isClosable: true,
      });
      refetchDetail(); // this is a promise, but we don't care about waiting for the promise to complete
    } else {
      toast({
        title: 'Failed to resume run',
        description: `${response.failureReason}`,
        status: 'error',
        duration: 5_000,
        isClosable: true,
      });
    }
  };

  const scrollToDataSection = () => {
    dataSectionRef.current?.scrollIntoView({ behavior: 'smooth' });
  };

  return (
    <Box>
      {isLoading || !isRunDetailSuccess || !runSummary.id
        ? <Flex alignItems="center" justifyContent="center"><Spinner mt={150}/></Flex>
        : (
          <Box>
            <Flex direction="row" align="center">
              <Heading size='xl' mr={4} noOfLines={1} maxWidth="20ch">
                Run detail
              </Heading>
              {convertStatusToBadge(
                runDetail ? runDetail.status : runSummary.status,
              )}
              <Spacer />
            </Flex>
            {runDetail.tool_name.length > 20
              && (<Flex mt={4} mb={4}>
                <Text variant='secondary' noOfLines={1}>
                  {runDetail.tool_name}
                </Text>
              </Flex>)
            }
            <Flex width='100%' alignItems='flex-start' gap='1.25rem'>
              <TerminateRunConfirmationModal
                isOpen={isTerminateOpen}
                onClose={onTerminateClose}
                isLoading={isTerminateLoading}
                onSubmit={onTerminateSubmit}
                toolName={runDetail.tool_name}
                runId={runSummary.id}
                createdAt={new Date(runDetail.created_at).toLocaleString('en-US')}
              />
              <RunSummarySection
                runSummary={runSummary}
                runDetail={runDetail}
                costEstimate={costEstimate}
              />
              <RunExecutionSection
                runDetail={runDetail}
                costEstimate={costEstimate}
                onTerminateClick={onTerminateOpen}
                onRetryClick={onRetrySubmit}
                isSubtask={isTESChildTask}
                scrollToData={scrollToDataSection}
                isRetryLoading={isRetryLoading}
              />
            </Flex>
            <SectionBox header='Logs' bodyPaddingTop='1rem'>
              <RunInfoAccordion
                taskId={runSummary.id}
                activeRun={runDetail && isActiveRun(runDetail.status)}
                runDetail={runDetail}
                logFiles={logFiles || []}
                hide={!(runSummary.run_type === runTypes.TES)}
                isTESChildTask={isTESChildTask}
              />
              <Box mt={2} />
            </SectionBox>
            <SectionBox header='Tasks' bodyPaddingTop='0rem'>
              <RunsSummaryTable
                runs={runDetail.subtasks}
                refetch={refetchDetail}
                noMargin={false}
              />
            </SectionBox>
            <Box ref={dataSectionRef}>
              <RunDataSection
                key={runSummary.id}
                hidden={!useSingleInstanceLaunch || isTESChildTask}
                runId={runSummary.id}
              />
            </Box>
            {!useSingleInstanceLaunch && runSummary.run_type === runTypes.TES && runDetail.spawn_args
                && (
                  <SectionBox header='Advanced'>
                    <Button
                      onClick={onTemplateOpen}
                      colorScheme="blue"
                      size={'md'}
                      w={'max-content'}
                      m={5}
                    >
                      Create template from run
                    </Button>
                    <TemplateCreationModal
                      isOpen={isTemplateOpen}
                      onClose={onTemplateClose}
                      pipelineName={runDetail?.tool_name ?? ''}
                      wfManager={runDetail?.wf_manager}
                      spawnArgs={JSON.stringify(runDetail?.spawn_args, null, '\t')}
                    />
                  </SectionBox>
                )
            }
          </Box>
        )
      }
    </Box>
  );
};

export default withRequiredAuthInfo(RunDetailPage, {
  displayIfLoggedOut: <RedirectToLogin />,
});
