import {
  Box,
  Button,
  Flex,
  Heading,
  Spacer,
} from '@chakra-ui/react';
import * as React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { RedirectToLogin, withRequiredAuthInfo } from '@propelauth/react';

import { ReactComponent as LaunchIcon } from '../images/launch_glyph.svg';
import { themeConfig } from '../theme';
import SectionBox from '../SectionBox';

import PipelinesTable from './PipelinesTable';
import EmptyPipelinesTableMessage from './EmptyPipelinesTableMessage';

const PipelinesPage = () => (
  <Box flex='4'>
    <Flex>
      <Heading as='h2' size='xl' mb={2}>
          Pipelines
      </Heading>
      <Spacer />
      <Button
        as={RouterLink}
        to='launch'
        colorScheme='blue'
        leftIcon={<LaunchIcon width='1ch' fill={themeConfig.colors.blues['50']} />}
      >
          Launch a pipeline
      </Button>
    </Flex>
    <SectionBox header='Your pipelines' bodyPaddingTop='0rem'>
      <PipelinesTable
        key={'pipelinesTable'}
        noMargin
        emptyMessage={<EmptyPipelinesTableMessage />}
      />
    </SectionBox>
  </Box>
);

export default withRequiredAuthInfo(PipelinesPage, {
  displayIfLoggedOut: <RedirectToLogin />,
});
