import { useLogoutFunction, withAuthInfo } from '@propelauth/react';
import { Link as RouterLink } from 'react-router-dom';
import {
  Button, Menu, MenuButton, MenuDivider, MenuGroup, MenuItem, MenuList, Text,
} from '@chakra-ui/react';
import * as React from 'react';
import { ChevronDownIcon } from '@chakra-ui/icons';
import { GoGear, GoCreditCard, GoSignOut } from 'react-icons/go';

function UserProfile({ isLoggedIn, user }) {
  const logoutFn = useLogoutFunction();
  if (isLoggedIn) {
    const profilePicture = <img alt='profile' src={user.pictureUrl} height='30px' width='30px' />;
    return (
      <Menu>
        <MenuButton
          as={Button}
          variant='ghost'
          leftIcon={<ChevronDownIcon />}
          rightIcon={profilePicture}
          textColor='greys.700'
        >
          {user.email}
        </MenuButton>
        <MenuList>
          <MenuGroup title='Admin'>
            <MenuItem as={RouterLink} to='billing'>
              <GoCreditCard /><Text ml='0.7ch'>Billing</Text>
            </MenuItem>
            <MenuItem as={RouterLink} to='settings'>
              <GoGear /><Text ml='0.7ch'>Settings</Text>
            </MenuItem>
          </MenuGroup>
          <MenuDivider />
          <MenuItem
            onClick={() => logoutFn(true)}
          >
            <GoSignOut /><Text ml='0.7ch'>Sign out</Text>
          </MenuItem>
        </MenuList>
      </Menu>);
  }
  return null;
}

export default withAuthInfo(UserProfile);
