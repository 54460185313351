import { VscGithub } from 'react-icons/vsc';
import {
  Box, Button, HStack, Icon, Link, Text,
} from '@chakra-ui/react';
import { withRequiredAuthInfo } from '@propelauth/react';
import { useQuery } from '@tanstack/react-query';
import SectionBox from '../SectionBox';

const GitHubConnectionSection = ({ accessToken, orgHelper }: any) => {
  const orgId = orgHelper.getOrgIds()[0];

  const {
    data,
  } = useQuery(
    ['githubConnection', orgId],
    () => fetch(`${process.env.REACT_APP_API_URL}/app/${orgId}/gh/connection`, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`,
      },
    }).then(async (response) => {
      if (!response.ok) {
        const body = await response.json();
        throw new Error(body.error || 'Error connecting to server');
      }
      return response.json();
    }),
    {
      refetchOnMount: true,
      refetchOnWindowFocus: false,
      refetchInterval: () => 10 * 60 * 1000,
    },
  );
  return (
    <SectionBox header='GitHub' isFormatted>
      <Text as='b' fontSize='m'>
        Connected:
      </Text>
      { data?.name
        ? (<HStack ml={4} pb={5} mt={1}>
          <Box bg='greens.500' h='.5em' w='.5em' borderRadius='1em'></Box>
          <Text> {data?.name}</Text>
        </HStack>) : (
          <HStack ml={4} pb={5} mt={1}>
            <Box bg='orange.500' h='.5em' w='.5em' borderRadius='1em'></Box>
            <Text>No connections</Text>
          </HStack>
        )
      }
      <Link
        isExternal
        href={`https://github.com/apps/flowdeploy-pipeline-manager/installations/new?state=${orgId}`}
        width='17em'
      >
        <Button
          mt={1}
          leftIcon={<Icon as={VscGithub} />}
          colorScheme="blue"
          width='17em'
        >
            Manage GitHub connection
        </Button>
      </Link>
    </SectionBox>
  );
};

export default withRequiredAuthInfo(GitHubConnectionSection);
