import {
  Flex, Text, Tooltip,
} from '@chakra-ui/react';

import CopyableBadge from '../../CopyableBadge';
import SectionBox from '../../SectionBox';

const ListDetail = ({
  name, value, noBorderBottom, children,
}: any) => (
  <Flex
    id='text-container'
    pb='0.9375rem'
    align='center'
    gap='1.5625rem'
    borderBottom={!noBorderBottom && '1px solid'}
    borderColor='gray.300'
    width='100%'
  >
    <Text
      width='6.25rem'
      color='gray.900'
      variant='text-sm/lineHeight-5/font-semibold'
    >
      {name}
    </Text>
    {value
      && <Text
        width='17.8125rem'
        color='gray.900'
        variant='text-md/lineHeight-6/font-normal'
      >
        {value}
      </Text>}
    {children}
  </Flex>
);

const RunSummarySection = ({ runSummary, runDetail, costEstimate }: any) => (
  <SectionBox
    header={runDetail.tool_name || 'Run summary'}
    bodyPaddingTop='0rem'
    marginBottom='0rem'
  >
    <Flex
      id='run-summary-section'
      padding='1.25rem 1.5625rem 0rem 1.25rem'
      direction='column'
      justify='center'
      align='flex-start'
      gap='1.25rem'
    >
      <Flex
        id='summary-content'
        direction='column'
        justify='center'
        align='flex-start'
        gap='0.9375rem'
        width='100%'
      >
        <ListDetail
          name='Start time'
          value={new Date(runDetail.created_at).toLocaleString('en-US')}
        />
        <ListDetail
          name='Last update'
          value={new Date(runDetail.last_updated || runDetail.created_at).toLocaleString('en-US')}
        />
        <ListDetail
          name='ID'
        >
          <Flex position='relative' pb="9">
            <CopyableBadge
              expanding={true}
              label={`${runSummary.id.substring(0, 8)}...`}
              value={runSummary.id}
            />
          </Flex>
        </ListDetail>
        <ListDetail
          name='Version'
          value={runDetail.tool_version}
        />
        <ListDetail
          name='Compute type'
          value={runDetail.instance_type}
        />
        <Tooltip
          label='This is an estimate of the cost of this run so far. Your final cost may be different.'
          closeDelay={200}
          shouldWrapChildren
          hasArrow
        >
          <ListDetail
            name='Live cost'
            value={costEstimate ? `$${costEstimate.toFixed(2)}` : 'N/A'}
            noBorderBottom
          />
        </Tooltip>
      </Flex>
    </Flex>
  </SectionBox>
);

export default RunSummarySection;
