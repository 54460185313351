import {
  Checkbox,
  Flex,
  Icon,
  IconButton,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  Spacer,
  useDisclosure,
  useToast,
} from '@chakra-ui/react';

import * as React from 'react';
import { useEffect, useState } from 'react';
import { BsBoxArrowInDown, BsXCircle } from 'react-icons/bs';
import {
  compact, map,
} from 'lodash';
import { IoSearchSharp } from 'react-icons/io5';
import { HiXCircle } from 'react-icons/hi';
import { useQueries, useQueryClient } from '@tanstack/react-query';
import { withRequiredAuthInfo } from '@propelauth/react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import UndoToastTitle from '../../inputOutputSelection/UndoToastTitle';
import fetchPresignedUrl from '../../fetchPresignedUrl';
import FilesBreadCrumb from './FilesBreadCrumb';
import DirectoryCreationModal from '../../DirectoryCreationModal';
import DataRepoConnectModal from '../../DataRepoConnectModal';
import AddDataMenu from './AddDataMenu';
import DeleteSelectedIconButton from './DeleteSelectedIconButton';

export type Breadcrumb = {
  displayName: string,
  to: string,
  isRegisteredDirectory?: boolean,
  isAboveRegisteredDirectory?: boolean,
  subdirectory: string,
}

export type BreadcrumbConfig = {
  path: string,
  setPath: any,
  breadcrumbs: Array<Breadcrumb>,
  setBreadcrumbs: any,
}

type NewFilesTableHeaderProps = {
  onRemove?: any,
  onAdd?: any,
  enableDownload?: boolean,
  enableAddData?: boolean,
  setGlobalSearchQuery?: any,
  table: any,
  headerButtons?: any,
  accessToken: any,
  orgHelper: any,
  refetch?: any,
  originalPath?: any,
  enableEditHeader?: any,
  breadcrumbConfig?: BreadcrumbConfig,
}

const NewFilesTableHeader = (props: NewFilesTableHeaderProps) => {
  const {
    onRemove, onAdd, enableDownload, enableAddData, setGlobalSearchQuery, table, headerButtons, accessToken, orgHelper,
    refetch, originalPath, enableEditHeader, breadcrumbConfig,
  } = props;
  const orgId = orgHelper.getOrgIds()[0];
  const isAnySelectOptionEnabled = !!onRemove || !!onAdd || !!enableDownload || !!enableAddData;
  const { getIsAllRowsSelected, toggleAllRowsSelected, getSelectedRowModel } = table;
  const toast = useToast();
  const [searchQuery, setSearchQuery] = useState('');
  const [selectedUrls, setSelectedUrls] = useState([]);
  const { isOpen: isCreateDirOpen, onOpen: onCreateDirOpen, onClose: onCreateDirClose } = useDisclosure();
  const { isOpen: isBucketConnectOpen, onOpen: onBucketConnectOpen, onClose: onBucketConnectClose } = useDisclosure();
  const navigate = useNavigate();

  // Ideally, the download function would be defined in RunDataSection – just like onAdd and onRemove are passed from
  // InputDataSection and OutputDataSection. This is much cleaner for now, though – although it tightly couples.
  const queryClient = useQueryClient();
  useQueries({
    queries: selectedUrls.map((selectedUrl: any) => ({
      queryKey: ['downloadFiles', selectedUrl],
      queryFn: async () => fetchPresignedUrl({
        registeredUrl: selectedUrl,
        accessToken,
        orgId,
      }),
      enabled: true,
      staleTime: 60 * 1000,
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      onSuccess: (presignedUrl) => {
        window.open(presignedUrl, '_blank');
      },
    })),
  });

  // Should be able to use getIsSomeRowsSelected(), but it doesn't consistently work :(
  function getIsAnyRowSelected() {
    const { rows: selectedRows } = getSelectedRowModel();
    return selectedRows?.length > 0;
  }

  const isAllRowsSelected = getIsAllRowsSelected();
  const isAnyRowSelected = getIsAnyRowSelected();
  const isPartialRowsSelected = isAnyRowSelected && !isAllRowsSelected;

  useEffect(() => {
    setGlobalSearchQuery(searchQuery);
  }, [setGlobalSearchQuery, searchQuery]);

  function toggleRows() {
    if (isAnyRowSelected) {
      // Deselect all rows
      toggleAllRowsSelected(false);
    } else {
      // Select all rows
      toggleAllRowsSelected(true);
    }
  }

  function removeSelectedFromTable() {
    function undoRemoveSelected(removedRows) {
      toast.closeAll();
      map(
        removedRows,
        (row) => onAdd(row),
      );
      toast({
        title: 'Action undone',
        position: 'bottom',
        status: 'success',
        isClosable: true,
      });
    }
    const { rows: selectedRows } = getSelectedRowModel();
    const originalRows = map(selectedRows, (row) => (row.original));
    const selectedRowIndexes = map(selectedRows, (row) => (row.index));
    selectedRowIndexes.sort().reverse();

    // To preserve the validity of the index, the rows have to be removed in reverse order
    map(selectedRowIndexes, (index) => onRemove(index));
    toast({
      title: <UndoToastTitle title='Removed selected files from table' undo={() => undoRemoveSelected(originalRows)} />,
      position: 'bottom',
      status: 'success',
      isClosable: true,
    });
  }

  function downloadSelected() {
    const { rows: selectedRows } = getSelectedRowModel();
    const urls = map(selectedRows, (row) => (row.original.url));
    setSelectedUrls(urls);
    queryClient.invalidateQueries(['downloadFiles']).then();
  }

  // todo: remove this next block somehow
  const [searchParams, setSearchParams] = useSearchParams();
  let pathSegments = breadcrumbConfig?.path && !breadcrumbConfig?.breadcrumbs // remove breadcrumbs here
    ? compact(['fd:', 'run', ...breadcrumbConfig.path.split('/')])
    : compact(searchParams.get('path')?.split('/')) ?? [];
  // Hacky override, change this!
  if (breadcrumbConfig?.breadcrumbs) {
    pathSegments = ['fd', ...(breadcrumbConfig.breadcrumbs.map((crumb) => crumb.displayName))];
  }

  function onBreadCrumbClick({ index }: { index: number }) {
    // Last index is 2 less than length due trailing slash and zero indexing
    if (index + 2 < pathSegments.length) {
      if (breadcrumbConfig?.setBreadcrumbs) {
        breadcrumbConfig.setPath(breadcrumbConfig.breadcrumbs[index]);
        breadcrumbConfig.setBreadcrumbs(breadcrumbConfig.breadcrumbs.slice(0, index + 1));
      } else if (breadcrumbConfig?.setPath) {
        // first two elements are dummy elements for run drive, fd URI looks like fd://run/blah.txt
        const newPath = `${pathSegments.slice(2, index + 2).join('/')}`;
        breadcrumbConfig.setPath(newPath);
      } else {
        const protocol = `${pathSegments[0]}//`;
        setSearchParams({ path: `${protocol}${pathSegments.slice(1, index + 2).join('/')}/` });
      }
      refetch();
    }
  }

  const { rows: selectedRows } = getSelectedRowModel();
  const selectedFiles = selectedRows.map((row) => row.original);

  return (
    <>
      <Flex
        id='icons-and-search'
        height='3.4375rem'
        p='0.625rem'
        pl='1.22rem'
        justify='space-between'
        align='center'
        bg='gray.50'
        borderBottom='1px solid'
        borderColor='gray.200'
        borderRadius='0.625rem 0.625rem 0rem 0rem'
        hidden={!enableEditHeader}
      >
        <DirectoryCreationModal
          isOpen={isCreateDirOpen}
          onClose={onCreateDirClose}
          originalPath={originalPath}
          setSearchParams={setSearchParams}
          refetchFiles={refetch}
        />
        <DataRepoConnectModal
          isOpen={isBucketConnectOpen}
          onClose={onBucketConnectClose}
        />
        <Flex
          id='table-breadcrumb'
          padding='0rem 0.25rem'
          justify='center'
          align='center'
        >
          <FilesBreadCrumb
            pathSegments={pathSegments.slice(1)}
            onBreadCrumbClick={onBreadCrumbClick}
          />
        </Flex>
        <Spacer/>
        {enableAddData && <AddDataMenu
          onCreateNewFolder={onCreateDirOpen}
          onConnectBucket={onBucketConnectOpen}
          onImportViaUrl={() => navigate('./transfer')}
        />}
      </Flex>
      <Flex
        id='icons-and-search'
        height='3.4375rem'
        p='0.625rem'
        pl='1.22rem'
        justify='space-between'
        align='center'
        bg='gray.50'
        borderRadius='0.625rem 0.625rem 0rem 0rem'
      >
        <Flex
          id='table-header-icons'
          padding='0rem 0.25rem'
          justify='center'
          align='center'
        >
          {isAnySelectOptionEnabled && <Checkbox
            size='lg'
            isChecked={isAllRowsSelected}
            isIndeterminate={isPartialRowsSelected}
            onChange={toggleRows}
          />}
          {isAnyRowSelected && onRemove && <IconButton
            aria-label='remove selected files from table'
            variant='ghost'
            colorScheme='gray'
            icon={<Icon as={BsXCircle} width='1.25rem' height='1.25rem'/>}
            ml='0.75rem '
            onClick={removeSelectedFromTable}
          />}
          {isAnyRowSelected && enableEditHeader && (
            <DeleteSelectedIconButton
              selectedFiles={selectedFiles}
              uriPath={originalPath}
              refetch={refetch}
              toggleRows={toggleRows}
            />
          )}
          {isAnyRowSelected && enableDownload && <IconButton
            aria-label='download selected files'
            variant='ghost'
            colorScheme='gray'
            icon={<Icon as={BsBoxArrowInDown} width='1.25rem' height='1.25rem'/>}
            ml='0.75rem '
            onClick={downloadSelected}
          />}
        </Flex>
        <Spacer/>
        <InputGroup
          id='search-table-group'
          width='16.625rem'
          alignItems='center'
          flexShrink={0}
          borderRadius='0.4375rem'
          size='sm'
        >
          <InputLeftElement justifyContent='center' alignItems='center' width='2rem' height='2rem'>
            <Icon as={IoSearchSharp} color='gray.800' />
          </InputLeftElement>
          <Input
            id='search-input'
            height='2rem'
            padding='0rem 0.75rem 0rem 2rem'
            flexDirection='column'
            alignItems='center'
            justifyContent='center'
            borderRadius='1.25rem'
            border='1px solid'
            borderColor='gray.200'
            bg='white'
            size='sm'
            variant='outline'
            placeholder='Search'
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
          />
          <InputRightElement
            hidden={!searchQuery}
            justifyContent='center'
            alignItems='center'
            width='2rem'
            height='2rem'
            ml='1rem'
          >
            <IconButton
              aria-label='clear search'
              variant='ghost'
              size='sm'
              icon={<Icon as={HiXCircle} color='gray.400'/>}
              onClick={() => setSearchQuery('')}
              _hover={{
                bg: 'transparent',
              }}
            />
          </InputRightElement>
        </InputGroup>
        {headerButtons}
      </Flex>
    </>
  );
};

export default withRequiredAuthInfo(NewFilesTableHeader);
