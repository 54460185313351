import { useEffect, useState } from 'react';
import { BsSlack } from 'react-icons/bs';
import {
  Button,
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  Icon,
  Input,
  Link,
  Text,
  useToast,
} from '@chakra-ui/react';
import { withRequiredAuthInfo } from '@propelauth/react';
import { useQuery } from '@tanstack/react-query';

import SectionBox from '../SectionBox';

const GitHubConnectionSection = ({ accessToken, orgHelper }: any) => {
  const orgId = orgHelper.getOrgIds()[0];
  const [webhook, setWebhook] = useState('');
  const [webhookErrors, setWebhookErrors] = useState();
  const toast = useToast();

  const {
    data: webhookRes, refetch: getWebhook, isFetching,
  } = useQuery(
    ['slackWebhook', orgId],
    async () => fetch(`${process.env.REACT_APP_API_URL}/app/${orgId}/slack`, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`,
      },
    }).then(async (response) => {
      if (!response.ok) {
        const body = await response.json();
        throw new Error(body.error || 'Error connecting to server');
      }
      const json = await response.json();
      const webhookUrl = json.webhook_url ?? '';
      if (!webhook) {
        setWebhook(webhookUrl);
      }
      return webhookUrl;
    }),
    {
      refetchOnMount: true,
      refetchOnWindowFocus: false,
      staleTime: 5 * 60 * 1000,
    },
  );

  useEffect(() => {
    if (!webhook) {
      setWebhook(webhookRes);
    }
  }, [webhookRes]);

  const {
    refetch: saveWebhook,
  } = useQuery(
    ['slackConnection', orgId],
    async () => {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/app/${orgId}/slack`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${accessToken}`,
        },
        body: JSON.stringify({
          webhook_url: webhook.trim(),
        }),
      });
      if (response.ok) {
        toast({
          title: 'Updated Slack webhook',
          status: 'success',
          duration: 5000,
          isClosable: true,
        });
        await getWebhook();
        return webhook;
      }
      const body = await response.json();
      setWebhookErrors(body.error || 'Error connecting to server');
      return webhook;
    },
    {
      enabled: false,
      refetchOnMount: false,
      refetchOnWindowFocus: false,
    },
  );

  return (
    <SectionBox header='Notifications' isFormatted>
      <FormLabel size='sm' color='greys.900'>
        <Text>Slack</Text>
      </FormLabel>
      <FormControl isInvalid={!!webhookErrors}>
        <Input mb={1}
          value={webhook}
          onChange={(event: any) => { setWebhookErrors(undefined); setWebhook(event.target.value); }}
          isDisabled={isFetching}
          placeholder='https://hooks.slack.com/services/T00000000/B00000000/XXXXXXXXXXXXXXXXXXXXXXXX'
        />
        <FormErrorMessage>{webhookErrors}</FormErrorMessage>
        <FormHelperText>
          Add an incoming webhook to receive pipeline start and end notifications.
          See <Link isExternal mr='.5ch' href='https://api.slack.com/messaging/webhooks'>here</Link>
          for instructions on how to create a Slack webhook.
        </FormHelperText>
      </FormControl>
      <Button
        mt={3}
        onClick={saveWebhook}
        width='17em'
        leftIcon={<Icon as={BsSlack}/>}
        colorScheme="blue"
      >
        Save webhook
      </Button>
    </SectionBox>
  );
};

export default withRequiredAuthInfo(GitHubConnectionSection);
